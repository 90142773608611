import React, { useState } from "react";
import {
  Table,
  Button,
  Pagination,
  Form,
  Col,
  InputGroup,
  Row,
} from "react-bootstrap";
import Loader from "../Loader";
import PaginatedResponse from "../../models/PaginatedResponse";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import Resources from "../../models/Resources";
import { Typography } from "@material-ui/core";
import PaginationComponent from "../lib/PaginationComponent";

import {
  StatusSalesOption,
  StatusResourcesOption,
} from "../../models/Constant";
import DeleteButtonClickListModal from "../lib/DeleteButtonClickListModal";

export interface SearchParams {
  page?: number;
  search?: string;
}

interface Props {
  loading: boolean;
  list: PaginatedResponse<Resources>;
  searchFunction: (params: SearchParams) => void;
  searchResources: (params: SearchParams) => void;
  openResources: (id: string) => void;
  newResources: () => void;
  deleteResources: (id: string) => void;
}

export default function ResourcesList(props: Props) {
  if (props.loading) {
    return <Loader rows={10} />;
  }

  const newResourcesButton = (
    <div className="text-right">
      <Button
        size="sm"
        className="btn-custom-secondary"
        variant=""
        onClick={props.newResources}
      >
        Nuova risorsa
      </Button>
    </div>
  );

  const list: PaginatedResponse<Resources> = props.list;
  console.log("table component");
  console.log(props);
  console.log("table component");
  const tableEntries = props.list.entries.map((b) => {
    const open = (event: any) => {
      event.preventDefault();
      props.openResources(b.id);
    };
    let statusLabel: string | undefined = "";
    if (
      b.type != null &&
      StatusResourcesOption.find((f) => f.value == b.type) != undefined
    ) {
      console.log("Selezionato");
      console.log(StatusResourcesOption.find((f) => f.value == b.type));
      statusLabel = StatusResourcesOption.find((f) => f.value == b.type)?.label;
    }
    return (
      <tr key={`resources-entry-${b.id}`}>
        <td className="pl-0 py-4 td-image">
          <div>
            <img src={b.image_cover} className="image-icon" alt="" />
          </div>
        </td>

        <td className="td-title-resource">
          <div className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {b.title}
          </div>
          <div className="text-dark-50">{b.subtitle}</div>
        </td>
        <td className="td-id-resource" style={{ color: "#B5B5C3" }}>
          <div>{b.id}</div>
        </td>
        <td className="td-type-resource" style={{ color: "#B5B5C3" }}>
          <div>{statusLabel}</div>
        </td>
        <td className="td-isbn-resource" style={{ color: "#B5B5C3" }}>
          {" "}
          <div>{b.isbn}</div>
        </td>
        <td className="td-date-publish-resource" style={{ color: "#B5B5C3" }}>
          <div>
            {b.date_publish != null
              ? new Date(b.date_publish).toLocaleDateString("it-IT")
              : b.date_publish}
          </div>
        </td>
        <td className="button-td" style={{ textAlign: "right" }}>
          <a
            onClick={open}
            href="#"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </a>
          <DeleteButtonClickListModal
            deleteAction={props.deleteResources}
            idDelete={b.id}
          />
        </td>
      </tr>
    );
  });

  return (
    <>
      <Row className="title-row">
        <Col xs={6}>
          <Typography variant="h5" component="h3">
            <b>Gestione risorse</b>
          </Typography>
        </Col>
        <Col xs={3}>
          <SearchForm {...props} />
        </Col>
        <Col xs={3}>{newResourcesButton}</Col>
      </Row>
      {0 === list.entries.length ? (
        <p className="font-italic">No resources matches your search criteria</p>
      ) : (
        <Table hover responsive size="sm" className="people-list-table">
          <thead className="MuiPaper-rounded">
            <tr>
              <th
                style={{
                  border: "none",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                }}
              ></th>
              <th>NOME RISORSA</th>
              <th style={{ color: "#B5B5C3" }}>ID</th>
              <th style={{ color: "#B5B5C3" }}>TIPO RISORSA</th>
              <th style={{ color: "#B5B5C3" }}>ISBN</th>
              <th style={{ color: "#B5B5C3" }}>DATA PUBBLICAZIONE</th>
              <th></th>
              <th
                style={{
                  border: "none",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                }}
              ></th>
            </tr>
          </thead>
          <br />
          <tbody>{tableEntries}</tbody>
        </Table>
      )}
      <PaginationComponent {...props} />
    </>
  );
}

function SearchForm(props: Props) {
  const [search, setSearch] = useState(props.list.search || "");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    props.searchResources({ search });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col xs={12}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <span className="oi oi-magnifying-glass"></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={({ target: { value } }) => setSearch(value)}
              value={search}
            />
          </InputGroup>
        </Col>
      </Form.Row>
    </Form>
  );
}
