import {
  CONTAINER_OPEN,
  ContainerOpen,
  CONTAINER_EDIT,
  ContainerEdit,
  CONTAINER_SAVE_RELATION_START,
  CONTAINER_SAVE_RELATION_DONE,
  ContainerSaveRelationAction,
  CONTAINER_SAVE_START,
  CONTAINER_SAVE_DONE,
  CONTAINER_SAVE_FAILED,
  ContainerSaveAction,
  CONTAINER_DELETE_START,
  CONTAINER_DELETE_DONE,
  ContainerDeleteAction,
  ASSET_SAVE_START,
  ASSET_SAVE_DONE,
  ASSET_SAVE_FAILED,
  AssetSaveAction,
} from "../actionTypes";
import { ContainerEditState } from "../state";

const initialState: ContainerEditState = {
  editing: false,
  sending: false,
  errors: {},
  saveSucceeded: false,
};

export default function reducer(
  state: ContainerEditState = initialState,
  action:
    | ContainerOpen
    | ContainerEdit
    | ContainerSaveAction
    | ContainerDeleteAction
    | AssetSaveAction
    | ContainerSaveRelationAction
) {
  switch (action.type) {
    case CONTAINER_OPEN: {
      const container = action.payload;
      return { ...initialState, container, editing: action.editing };
    }

    case CONTAINER_EDIT: {
      return { ...state, ...initialState, editing: action.payload };
    }

    case CONTAINER_SAVE_START: {
      return { ...state, sending: true, errors: {}, saveSucceeded: false };
    }

    case CONTAINER_SAVE_DONE: {
      return {
        ...state,
        editing: false,
        sending: false,
        errors: {},
        container: action.payload,
        saveSucceeded: true,
      };
    }

    case CONTAINER_SAVE_RELATION_START: {
      return { ...state, sending: true, errors: {} };
    }

    case CONTAINER_SAVE_RELATION_DONE: {
      return {
        ...state,
        editing: true,
        sending: false,
        errors: {},
        container: action.payload,
      };
    }

    case CONTAINER_SAVE_FAILED: {
      return {
        ...state,
        editing: true,
        sending: false,
        errors: action.payload,
        saveSucceeded: false,
      };
    }

    case CONTAINER_DELETE_START: {
      return { ...state, sending: true };
    }

    case CONTAINER_DELETE_DONE: {
      return { ...state, sending: false, container: undefined };
    }

    case ASSET_SAVE_START: {
      return { ...state, sending: true };
    }

    case ASSET_SAVE_DONE:
    case ASSET_SAVE_FAILED: {
      return { ...state, sending: false };
    }

    default:
      return state;
  }
}
