import React from "react";
import PaginatedResponse from "../../models/PaginatedResponse";
import { Pagination, Row, Col } from "react-bootstrap";

export interface SearchParams {
  page?: number;
  search?: string;
  parent_id?: string;
}
interface Props {
  searchFunction: (params: SearchParams) => void;
  list: PaginatedResponse<Record<string, any>>;
}

export default function Pages(props: Props) {
  console.log("Pages");
  console.log(props);

  const {
    list: {
      totalPages,
      currentPage,
      search,
      currentFromCount,
      currentToCount,
      totalCount,
      parent_id,
    },
    searchFunction,
  } = props;
  if (1 === totalPages)
    return (
      <div style={{ height: 50 }}>
        <Row>
          <Col
            xs
            md
            lg="6"
            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-md pt-4"
          >
            {totalCount} {totalCount == 1 ? "elemento" : "elementi"}
          </Col>
          <Col xs md lg="6">
            <Pagination></Pagination>
          </Col>
        </Row>
      </div>
    );

  const links = [];
  const fromRecord = currentFromCount;
  const toRecord = currentToCount;
  const totalRecord = totalCount;
  const nextPage = () =>
    searchFunction({ search, parent_id, page: currentPage + 1 });
  const prevPage = () =>
    searchFunction({ search, parent_id, page: currentPage - 1 });
  const specificPage = (i: number) => {
    searchFunction({ search, page: i });
  };

  if (currentPage != 1) {
    links.push(<Pagination.Prev key="pagination.prev" onClick={prevPage} />);
  }

  let i: number = 0;
  switch (currentPage) {
    case 1:
    case 2:
    case 3:
    case 4:
      for (let i = 0; i < 5; i++) {
        let page = i + 1;
        let classActive = "";
        if (currentPage == i + 1) {
          classActive = "active";
        }
        if (totalPages > i) {
          links.push(
            <Pagination.Item
              onClick={() => specificPage(page)}
              className={classActive}
              key={page}
            >
              {page}
            </Pagination.Item>
          );
        }
      }
      break;
    default:
      for (let i = currentPage - 3; i <= currentPage + 1; i++) {
        let page = i + 1;
        let classActive = "";
        if (currentPage == i + 1) {
          classActive = "active";
        }
        if (totalPages > i) {
          links.push(
            <Pagination.Item
              onClick={() => specificPage(page)}
              className={classActive}
              key={page}
            >
              {page}
            </Pagination.Item>
          );
        }
      }
      break;
  }

  if (currentPage != totalPages) {
    links.push(<Pagination.Next key="pagination.next" onClick={nextPage} />);
  }

  return (
    <div style={{ height: 50 }}>
      <Row>
        <Col
          xs
          md
          lg="6"
          className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-md pt-4"
        >
          da {fromRecord} a {toRecord} di {totalRecord}{" "}
        </Col>
        <Col xs md lg="6">
          <Pagination>{links}</Pagination>
        </Col>
      </Row>
    </div>
  );
}
