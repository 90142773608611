import Container from "./Container";
import People from "./People";

export default interface Content extends Record<string, any> {
  id: string;
  title: string;
  audio_book_id: string;
  created_at: string;
  file_track: string;
  narrator_id: string;
  position: number;
  streaming_completed: string;
  track_duration: string;
  track_filename: string;
  track_id: number;
  track_name: string;
  track_number: string;
  track_weight: string;
  updated_at: string;
  audio_book?: Container;
  person?: People;
}

export function build() {
  return {
    id: "",
    title: "",
    audio_book_id: "",
    created_at: "",
    file_track: "",
    narrator_id: "",
    position: 0,
    streaming_completed: "",
    track_duration: "",
    track_filename: "",
    track_id: 0,
    track_name: "",
    track_number: "",
    track_weight: "",
    updated_at: "",
  };
}
