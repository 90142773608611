import {
  CATEGORY_LIST_OPTION_LOAD_START,
  CATEGORY_LIST_OPTION_LOAD_DONE,

  CATEGORY_LIST_LOAD_START,
  CATEGORY_LIST_LOAD_DONE,
  CategoryListAction,

  CATEGORY_OPEN,
  CategoryOpen,

  CATEGORY_EDIT,
  CategoryEdit,

  CATEGORY_SAVE_START,
  CATEGORY_SAVE_DONE,
  CATEGORY_SAVE_FAILED,
  CategorySaveAction,

  CATEGORY_DELETE_START,
  CATEGORY_DELETE_DONE,
  CategoryDeleteAction,
  CategoryListOptionAction
} from '../actionTypes'
import { service as categoryService, SearchParams } from '../../services/CategoryService'
import PaginatedResponse from '../../models/PaginatedResponse'
import Category, { build as buildCategory } from '../../models/Category'
import OptionResponse from '../../models/OptionResponse'
import { Console } from 'console'

export function loadCategoryList(params?: SearchParams): (dispatch: (action: CategoryListAction) => void) => void {
  console.log("action:loadCategoryList")
  return async dispatch => {
    dispatch({ type: CATEGORY_LIST_LOAD_START })

    const list: PaginatedResponse<Category> = await categoryService.search(params || {})
    console.log(list)
    dispatch({ type: CATEGORY_LIST_LOAD_DONE, payload: list })
  }
}

export function loadCategoryOption(params?: SearchParams): (dispatch: (action: CategoryListOptionAction) => void) => void {
  console.log("action:loadCategoryOption")
  return async dispatch => {
    dispatch({ type: CATEGORY_LIST_OPTION_LOAD_START })

    const list: OptionResponse[] = await categoryService.getOptionList(params || {})
    console.log(list)
    dispatch({ type: CATEGORY_LIST_OPTION_LOAD_DONE, payload: list })
  }
}


export function openCategory(payload: Category): (dispatch: (action: CategoryOpen) => void) => void {
  return async dispatch => {
    dispatch({ type: CATEGORY_OPEN, payload, editing:true})
  }
}

export function newCategory(): (dispatch: (action: CategoryOpen | CategoryEdit) => void) => void {
  return async dispatch => {
    const newCategory: Category = buildCategory()
    dispatch({ type: CATEGORY_OPEN, payload: newCategory, editing:true })
    dispatch({ type: CATEGORY_EDIT, payload: true })
  }
}

export function edit(): (dispatch: (action: CategoryEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: CATEGORY_EDIT, payload: true})
  }
}

export function cancelEdit(): (dispatch: (action: CategoryEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: CATEGORY_EDIT, payload: false })
  }
}

export function save(category: Category): (dispatch: (action: CategorySaveAction) => void) => void {
  return async dispatch => {
    dispatch({ type: CATEGORY_SAVE_START })
    try {
      const updatedModel: Category = await categoryService.save(category)
      dispatch({ type: CATEGORY_SAVE_DONE, payload: updatedModel })
    } catch (err) {
      //dispatch({ type: CATEGORY_SAVE_FAILED, payload: err.data })
    }
  }
}

export function deleteCategory(id: string): (dispatch: (action: CategoryDeleteAction) => void) => void {
  return async dispatch => {
    dispatch({ type: CATEGORY_DELETE_START, payload: { id } })
    try {
      
      await categoryService.deleteModel(id)
      dispatch({ type: CATEGORY_DELETE_DONE, payload: { id } })
    } catch (err) {}
  }
}


