import { connect } from "react-redux";

import Asset from "../../models/Asset";

import AssetsListComponent from "../../components/chapters/AssetsList";
import {
  upload,
  moveAsset,
  deleteAsset,
} from "../../redux/actions/assetActions";
import { StoreState } from "../../redux/state";

interface OwnProps {
  chapterId: string;
  assets: Asset[];
}

const mapStateToProps = ({
  chapterEdit: { sending, uploading },
}: StoreState) => ({
  sending,
  uploading,
});

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => {
  return {
    upload: (file: File) => upload(ownProps.chapterId, file)(dispatch),
    moveAsset: (asset: Asset, offset: number) =>
      moveAsset(asset, offset)(dispatch),
    deleteAsset: (id: string) => deleteAsset(id, ownProps.chapterId)(dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetsListComponent);
