import React, { Component } from "react";
import { connect } from "react-redux";

import PaginatedResponse from "../../models/PaginatedResponse";
import Container from "../../models/Container";

import ContainerListComponent, {
  SearchParams,
} from "../../components/container/ContainerList";
import {
  loadContainerList,
  openContainer,
  newContainer,
  deleteContainer,
} from "../../redux/actions/containerActions";
import { StoreState } from "../../redux/state";
import { bool } from "prop-types";

interface Props {
  loading: boolean;
  modal: boolean;
  list: PaginatedResponse<Container>;
  listSelected?: string[];
  newContainerShortcut?: boolean;
  closeModal: () => void;

  loadContainerList: (params: SearchParams) => void;
  openContainer: (container: Container) => void;
  newContainer: () => void;
  checkboxChange?: (event: any, container: Container) => void;
  deleteContainer: (id: string) => void;
}

class ContainerList extends Component<Props> {
  componentDidMount() {
    console.log("componentDidMount");
    console.log("before load");

    if (this.props.newContainerShortcut == true) {
      this.props.newContainer();
    } else {
      this.props.loadContainerList({});
    }

    console.log("after load");
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.list.currentPage == prevProps.list.currentPage) {
      if (this.props.list.entries.length != prevProps.list.entries.length) {
        this.props.loadContainerList({ search: this.props.list.search });
      }
    }
  }
  render() {
    console.log("render");
    const {
      modal,
      loading,
      list,
      listSelected,
      openContainer,
      newContainer,
      loadContainerList,
      checkboxChange,
      deleteContainer,
    } = this.props;
    const openById = (id: string) => {
      const container: Container | undefined = list.entries.find(
        (b) => b.id === id
      );
      if (container) {
        openContainer(container);
      }
    };

    return (
      <ContainerListComponent
        modal={modal}
        loading={loading}
        list={list}
        listSelected={listSelected}
        openContainer={openById}
        newContainer={newContainer}
        searchContainer={loadContainerList}
        searchFunction={loadContainerList}
        checkboxChange={checkboxChange}
        deleteContainer={deleteContainer}
        closeModal={this.props.closeModal}
      />
    );
  }
}

const mapStateToProps = (store: StoreState) => {
  return store.containerList;
};

export default connect(mapStateToProps, {
  loadContainerList,
  openContainer,
  newContainer,
  deleteContainer,
})(ContainerList);
