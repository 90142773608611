import React, { useState } from "react";
import {
  Table,
  Button,
  Form,
  Col,
  InputGroup,
  Row,
} from "react-bootstrap";
import Loader from "../Loader";
import PaginatedResponse from "../../models/PaginatedResponse";
import People from "../../models/People";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Checkbox } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import PaginationComponent from "../lib/PaginationComponent";
import DeleteButtonClickListModal from "../lib/DeleteButtonClickListModal";

export interface SearchParams {
  page?: number;
  search?: string;
}

interface Props {
  loading: boolean;
  list: PaginatedResponse<People>;

  searchPeople: (params: SearchParams) => void;
  searchFunction: (params: SearchParams) => void;
  openPeople: (id: string) => void;
  newPeople: () => void;
  deletePeople: (id: string) => void;
}

export default function PeopleList(props: Props) {
  if (props.loading) {
    return <Loader rows={10} />;
  }

  const newPeopleButton = (
    <div className="text-right">
      <Button
        size="sm"
        className="btn-custom-secondary"
        variant=""
        onClick={props.newPeople}
      >
        Nuovo contributore
      </Button>
    </div>
  );

  const list: PaginatedResponse<People> = props.list;
  console.log("table component people");
  console.log(props);
  console.log("table component");
  const tableEntries = props.list.entries.map((b) => {
    const open = (event: any) => {
      event.preventDefault();
      props.openPeople(b.id);
    };

    return (
      <tr key={`people-entry-${b.id}`}>
        <td className="pl-0 td-image">
          <img src={b.image_square} className="image-icon" alt="" />
        </td>
        <td className="td-name-people">
          <label className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {b.first_name + " " + b.last_name}
          </label>
          <div>
            <a href="#" className="text-dark-50 ">
              {b.type}
            </a>
          </div>
        </td>
        <td className="td-description" style={{ color: "#B5B5C3" }}>
          <div>{b.description}</div>
        </td>
        <td className="button-td" style={{ textAlign: "right" }}>
          <a
            onClick={open}
            href="#"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </a>
          <DeleteButtonClickListModal
            deleteAction={props.deletePeople}
            idDelete={b.id}
          />
        </td>
      </tr>
    );
  });

  return (
    <>
      <Row className="title-row">
        <Col xs={7}>
          <Typography variant="h5" component="h3">
            <b>Anagrafiche Contributori</b>
          </Typography>
        </Col>
        <Col xs={3}>
          <SearchForm {...props} />
        </Col>
        <Col xs={2}>{newPeopleButton}</Col>
      </Row>

      {0 === list.entries.length ? (
        <p className="font-italic">No people matches your search criteria</p>
      ) : (
        <Table hover responsive size="sm" className="people-list-table">
          <thead className="MuiPaper-rounded">
            <tr>
              <th
                style={{
                  border: "none",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                }}
              ></th>
              <th style={{ border: "none" }}>NOME CONTRIBUTORE</th>
              <th style={{ border: "none", color: "#B5B5C3" }}>DESCRIZIONE</th>
              <th
                style={{
                  border: "none",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                }}
              ></th>
            </tr>
          </thead>

          <tbody>{tableEntries}</tbody>
        </Table>
      )}
      <PaginationComponent {...props} />
    </>
  );
}

function SearchForm(props: Props) {
  const [search, setSearch] = useState(props.list.search || "");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    props.searchPeople({ search });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col xs={12}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <span className="oi oi-magnifying-glass"></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className="custom-form-box"
              type="text"
              placeholder="Cerca contributore"
              onChange={({ target: { value } }) => setSearch(value)}
              value={search}
            />
          </InputGroup>
        </Col>
      </Form.Row>
    </Form>
  );
}
