import clone from "lodash/clone";
import findIndex from "lodash/findIndex";

import {
  CONTAINER_LIST_OPTION_LOAD_START,
  CONTAINER_LIST_OPTION_LOAD_DONE,
  ContainerListOptionAction,
  CONTAINER_LIST_LOAD_START,
  CONTAINER_LIST_LOAD_DONE,
  ContainerListAction,
  CONTAINER_SAVE_DONE,
  ContainerSaveAction,
  CONTAINER_DELETE_DONE,
  ContainerDeleteAction,
} from "../actionTypes";
import { ContainerListState } from "../state";
import PaginatedResponse, { empty } from "../../models/PaginatedResponse";
import Container from "../../models/Container";

const initialState: ContainerListState = {
  loading: false,
  list: empty,
  listOption: [],
};

export default function reducer(
  state: ContainerListState = initialState,
  action:
    | ContainerListAction
    | ContainerSaveAction
    | ContainerDeleteAction
    | ContainerListOptionAction
) {
  switch (action.type) {
    case CONTAINER_LIST_LOAD_START: {
      return { ...state, loading: true };
    }

    case CONTAINER_LIST_LOAD_DONE: {
      console.log("reducer:CONTAINER_LIST_LOAD_DONE");
      console.log(action.payload);
      console.log("reducer:CONTAINER_LIST_LOAD_DONE - END");
      return { ...state, loading: false, list: action.payload };
    }

    case CONTAINER_LIST_OPTION_LOAD_START: {
      return { ...state, loading: true };
    }

    case CONTAINER_LIST_OPTION_LOAD_DONE: {
      return { ...state, loading: false, listOption: action.payload };
    }

    case CONTAINER_SAVE_DONE: {
      const entries: Container[] = clone(state.list.entries);
      const updated: Container = action.payload;

      const index = findIndex(entries, (entry) => entry.id === updated.id);
      if (index >= 0) {
        entries[index] = updated;
      } else {
        entries.push(updated);
      }
      const list: PaginatedResponse<Container> = { ...state.list, entries };
      return { ...state, list };
    }

    case CONTAINER_DELETE_DONE: {
      const { id } = action.payload;
      const entries: Container[] = clone(state.list.entries);

      const index = findIndex(entries, (entry) => entry.id === id);
      if (index >= 0) {
        entries.splice(index, 1);
      }
      const list: PaginatedResponse<Container> = { ...state.list, entries };
      return { ...state, list };
    }

    default:
      return state;
  }
}
