import clone from "lodash/clone";
import findIndex from "lodash/findIndex";

import {
  PEOPLE_LIST_OPTION_LOAD_START,
  PEOPLE_LIST_OPTION_LOAD_DONE,
  PeopleListOptionAction,
  PEOPLE_LIST_LOAD_START,
  PEOPLE_LIST_LOAD_DONE,
  PeopleListAction,
  PEOPLE_SAVE_DONE,
  PeopleSaveAction,
  PEOPLE_DELETE_DONE,
  PeopleDeleteAction,
} from "../actionTypes";
import { PeopleListState } from "../state";
import PaginatedResponse, { empty } from "../../models/PaginatedResponse";
import People from "../../models/People";

const initialState: PeopleListState = {
  loading: false,
  list: empty,
  listOption: [],
};

export default function reducer(
  state: PeopleListState = initialState,
  action:
    | PeopleListAction
    | PeopleSaveAction
    | PeopleDeleteAction
    | PeopleListOptionAction
) {
  switch (action.type) {
    case PEOPLE_LIST_LOAD_START: {
      return { ...state, loading: true };
    }

    case PEOPLE_LIST_LOAD_DONE: {
      console.log("reducer:PEOPLE_LIST_LOAD_DONE");
      console.log(action.payload);
      console.log("reducer:PEOPLE_LIST_LOAD_DONE - END");
      return { ...state, loading: false, list: action.payload };
    }

    case PEOPLE_SAVE_DONE: {
      const entries: People[] = clone(state.list.entries);
      const updated: People = action.payload;

      const index = findIndex(entries, (entry) => entry.id === updated.id);
      if (index >= 0) {
        entries[index] = updated;
      } else {
        entries.push(updated);
      }
      const list: PaginatedResponse<People> = { ...state.list, entries };
      return { ...state, list };
    }

    case PEOPLE_DELETE_DONE: {
      const { id } = action.payload;
      const entries: People[] = clone(state.list.entries);

      const index = findIndex(entries, (entry) => entry.id === id);
      if (index >= 0) {
        entries.splice(index, 1);
      }
      const list: PaginatedResponse<People> = { ...state.list, entries };
      return { ...state, list };
    }

    case PEOPLE_LIST_OPTION_LOAD_START: {
      return { ...state, loading: true };
    }

    case PEOPLE_LIST_OPTION_LOAD_DONE: {
      return { ...state, loading: false, listOption: action.payload };
    }

    default:
      return state;
  }
}
