import { connect } from "react-redux";

import CategoryDetailsComponent from "../../components/category/CategoryDetails";

import { CategoryEditState } from "../../redux/state";
import { edit } from "../../redux/actions/categoryActions";

const mapStateToProps = ({
  categoryEdit,
}: {
  categoryEdit: CategoryEditState;
}) => categoryEdit || {};

export default connect(mapStateToProps, { edit })(CategoryDetailsComponent);
