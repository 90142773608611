import React, { useState } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import PublisherList from "../containers/publisher/PublisherList";
import PublisherDetails from "../containers/publisher/PublisherDetails";

import CurrentUser from "../models/CurrentUser";
import { cancelEdit } from "../redux/actions/publisherActions";
import { PublisherEdit, PEOPLE_EDIT, PeopleEdit } from "../redux/actionTypes";

interface Props {
  currentUser: CurrentUser;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(3, 2),
    borderRadius: 12,
  },
}));

export default function PublisherBackoffice(props: Props) {
  const classes = useStyles();
  console.log("PROPS");
  const dispatch = useDispatch();
  const editing = useSelector(
    (state: RootStateOrAny) => state.publisherEdit.editing
  );

  return (
    <div>
      {!editing ? (
        <Paper className={classes.root}>
          <Typography component="div">
            <PublisherList />
          </Typography>
        </Paper>
      ) : (
        <Paper className={classes.root}>
          <Typography variant="h5" component="h3">
            <b>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(cancelEdit())}
              >
                {"<   "}
              </span>
              Editore
            </b>
          </Typography>
          <div className="separator separator-solid my-7"></div>
          <Typography component="div">
            <PublisherDetails />
          </Typography>
        </Paper>
      )}
    </div>
  );
}
