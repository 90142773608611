import React from "react";
import { Button, Table } from "react-bootstrap";
import join from "lodash/join";

import ContainerForm from "../../containers/container/ContainerForm";
import Container from "../../models/Container";

interface Props {
  container?: Container;
  editing: boolean;

  edit: () => void;
}

export default function ContainerDetails(props: Props) {
  if (!props.container) {
    return <p className="font-italic">Select a container from the list</p>;
  }
  console.log(props);
  const { container } = props;

  return (
    <>
      <div className="mt-4 mb-4">{<ContainerForm />}</div>
    </>
  );
}

function DetailsTable({ container }: { container: Container }) {
  return (
    <Table size="sm">
      <tbody>
        <tr>
          <th>ID</th>
          <td>{container.id}</td>
        </tr>
        <tr>
          <th>Name</th>
          <td>{container.name}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{container.description}</td>
        </tr>
      </tbody>
    </Table>
  );
}
