import React, { Component } from "react";
import { FormControl, Dropdown } from "react-bootstrap";

import differenceBy from "lodash/differenceBy";

import Author from "../../models/Author";
import PaginatedResponse from "../../models/PaginatedResponse";
import { service } from "../../services/PersonService";

interface Props {
  selectedAuthors: Author[];
  addAuthor: (author: Author) => void;
}

interface State {
  availableAuthors: Author[];
  search: string;
  loading: boolean;
}

async function searchAuthors(search: string) {
  const result: PaginatedResponse<Author> = await service.searchAuthors(search);
  return result.entries;
}

export default class AuthorsDropdownSelect extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);

    this.state = {
      search: "",
      loading: true,
      availableAuthors: [],
    };

    this.setSearch = this.setSearch.bind(this);
    this.doSearch = this.doSearch.bind(this);
  }

  async componentDidMount() {
    const availableAuthors = await searchAuthors(this.state.search);
    this.setState({ ...this.state, loading: false, availableAuthors });
  }

  setSearch({ target: { value } }: { target: { value: string } }) {
    this.setState({ search: value });
  }

  async doSearch({ charCode }: { charCode: number }) {
    if (13 === charCode) {
      this.setState({ ...this.state, loading: true });
      const availableAuthors = await searchAuthors(this.state.search);
      this.setState({ ...this.state, loading: false, availableAuthors });
    }
  }

  render() {
    const authors = differenceBy(
      this.state.availableAuthors,
      this.props.selectedAuthors,
      (author) => author.id
    );

    return (
      <>
        {this.state.loading ? (
          <Dropdown.Menu>
            <Dropdown.Item>Loading...</Dropdown.Item>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu>
            <FormControl
              autoFocus
              className="mx-3 my-2 w-auto"
              placeholder="Search authors..."
              onKeyPress={this.doSearch}
              onChange={this.setSearch}
              value={this.state.search}
            />
            {authors.length > 0 ? (
              authors.map((author) => {
                return (
                  <Dropdown.Item
                    key={`author-${author.id}`}
                    onClick={() => this.props.addAuthor(author)}
                  >
                    {author.firstName} {author.lastName}
                  </Dropdown.Item>
                );
              })
            ) : (
              <Dropdown.Item className="font-italic">No matches</Dropdown.Item>
            )}
            {this.props.children}
          </Dropdown.Menu>
        )}
      </>
    );
  }
}
