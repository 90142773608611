export default interface PaginatedResponse<T> {
  totalPages: number;
  totalCount: number;
  currentPage: number;
  currentFromCount: number;
  currentToCount: number;
  search: string;
  entries: T[];
  parent_id?: string;
}

export const empty = {
  totalPages: 0,
  totalCount: 0,
  currentPage: 1,
  currentFromCount: 0,
  currentToCount: 0,
  search: "",
  entries: [],
};
