import {
  AUDIO_BOOK_OPEN,
  AudioBookOpen,
  AUDIO_BOOK_EDIT,
  AudioBookEdit,
  AUDIO_BOOK_SAVE_START,
  AUDIO_BOOK_SAVE_DONE,
  AUDIO_BOOK_SAVE_FAILED,
  AudioBookSaveAction,
  AUDIO_BOOK_DELETE_START,
  AUDIO_BOOK_DELETE_DONE,
  AudioBookDeleteAction,
  ASSET_SAVE_START,
  ASSET_SAVE_DONE,
  ASSET_SAVE_FAILED,
  AssetSaveAction,
} from "../actionTypes";
import { AudioBookEditState } from "../state";

const initialState: AudioBookEditState = {
  editing: false,
  sending: false,
  errors: {},
  saveSucceeded: false,
};

export default function reducer(
  state: AudioBookEditState = initialState,
  action:
    | AudioBookOpen
    | AudioBookEdit
    | AudioBookSaveAction
    | AudioBookDeleteAction
    | AssetSaveAction
) {
  switch (action.type) {
    case AUDIO_BOOK_OPEN: {
      const audioBook = action.payload;
      return { ...initialState, audioBook };
    }

    case AUDIO_BOOK_EDIT: {
      return { ...state, ...initialState, editing: action.payload };
    }

    case AUDIO_BOOK_SAVE_START: {
      return { ...state, sending: true, errors: {}, saveSucceeded: false };
    }

    case AUDIO_BOOK_SAVE_DONE: {
      return {
        ...state,
        editing: false,
        sending: false,
        errors: {},
        audioBook: action.payload,
        saveSucceeded: true,
      };
    }

    case AUDIO_BOOK_SAVE_FAILED: {
      return {
        ...state,
        editing: true,
        sending: false,
        errors: action.payload,
        saveSucceeded: false,
      };
    }

    case AUDIO_BOOK_DELETE_START: {
      return { ...state, sending: true };
    }

    case AUDIO_BOOK_DELETE_DONE: {
      return { ...state, sending: false, audioBook: undefined };
    }

    case ASSET_SAVE_START: {
      return { ...state, sending: true };
    }

    case ASSET_SAVE_DONE:
    case ASSET_SAVE_FAILED: {
      return { ...state, sending: false };
    }

    default:
      return state;
  }
}
