import { connect } from "react-redux";

import ContainerDetailsComponent from "../../components/container/ContainerDetails";

import { ContainerEditState } from "../../redux/state";
import { edit } from "../../redux/actions/containerActions";

const mapStateToProps = ({
  containerEdit,
}: {
  containerEdit: ContainerEditState;
}) => containerEdit || {};

export default connect(mapStateToProps, { edit })(ContainerDetailsComponent);
