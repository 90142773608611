import HttpClientService, {
  HttpClientResponse,
} from "./HttpClientServiceLaravel";
import PaginatedResponse from "../models/PaginatedResponse";
import TrackPreview from "../models/TrackPreview";
import { AxiosRequestConfig } from "axios";

export interface SearchParams {
  page?: number;
  search?: string;
}

export default class TrackPreviewService extends HttpClientService {
  // Get all trackPreview?
  async search(params: SearchParams): Promise<PaginatedResponse<TrackPreview>> {
    console.log("TRACK PEOPLE LIST API CALLING");
    const request: AxiosRequestConfig = {
      method: "GET",
      url: `/api/v1/trackPreview/${params.search}`,
    };

    const response: HttpClientResponse<PaginatedResponse<
      TrackPreview
    >> = await this.doRequest(request);
    console.log(response);
    return response.data;
  }

  // Save uploaded file preiew
  async save(model: TrackPreview): Promise<TrackPreview> {
    let request: AxiosRequestConfig;
    const data: any = { ...model, id: undefined };
    console.log("TRACK_PREVIW_SAVE_MODEL");
    console.log(model);

    let headers: AxiosRequestConfig["headers"] = {};

    headers["Content-Type"] = "multipart/form-data";

    let formData: FormData = new FormData();
    formData!.append("name", model.name);
    formData!.append("file_preview", model.uploadFile);

    console.log("FORMDATA");
    console.log(formData.get("file_preview"));

    // Make request
    request = {
      method: "POST",
      url: `/api/v1/trackPreview/${model.audio_bookfk}`,
      data: formData,
      headers: headers,
    };

    const response: HttpClientResponse<TrackPreview> = await this.doRequest(
      request
    );

    return response.data;
  }

  async deleteModel(model: TrackPreview): Promise<boolean> {
    console.log("DELETE " + model.id);
    const request: AxiosRequestConfig = {
      method: "DELETE",
      url: `/api/v1/trackPreview/${model.id}`,
    };

    await this.doRequest(request);

    return true;
  }
}

export const service = new TrackPreviewService();
