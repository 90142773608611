import React from "react";

import Dashboard from "../containers/dashboard/Dashboard";

import CurrentUser from "../models/CurrentUser";

interface Props {
  currentUser: CurrentUser;
}

export default function HomeBackoffice(props: Props) {
  return (
    <div>
      <Dashboard />
    </div>
  );
}
