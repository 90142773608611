import clone from "lodash/clone";
import findIndex from "lodash/findIndex";

import {
  CATEGORY_LIST_LOAD_START,
  CATEGORY_LIST_LOAD_DONE,
  CategoryListAction,
  CATEGORY_LIST_OPTION_LOAD_START,
  CATEGORY_LIST_OPTION_LOAD_DONE,
  CategoryListOptionAction,
  CATEGORY_SAVE_DONE,
  CategorySaveAction,
  CATEGORY_DELETE_DONE,
  CategoryDeleteAction,
} from "../actionTypes";
import { CategoryListState } from "../state";
import PaginatedResponse, { empty } from "../../models/PaginatedResponse";
import Category from "../../models/Category";

const initialState: CategoryListState = {
  loading: false,
  list: empty,
  listOption: [],
};

export default function reducer(
  state: CategoryListState = initialState,
  action:
    | CategoryListAction
    | CategorySaveAction
    | CategoryDeleteAction
    | CategoryListOptionAction
) {
  switch (action.type) {
    case CATEGORY_LIST_LOAD_START: {
      return { ...state, loading: true };
    }

    case CATEGORY_LIST_LOAD_DONE: {
      console.log("reducer:CATEGORY_LIST_LOAD_DONE");
      console.log(action.payload);
      console.log("reducer:CATEGORY_LIST_LOAD_DONE - END");
      return { ...state, loading: false, list: action.payload };
    }

    case CATEGORY_LIST_OPTION_LOAD_START: {
      return { ...state, loading: true };
    }

    case CATEGORY_LIST_OPTION_LOAD_DONE: {
      return { ...state, loading: false, listOption: action.payload };
    }

    case CATEGORY_SAVE_DONE: {
      const entries: Category[] = clone(state.list.entries);
      const updated: Category = action.payload;

      const index = findIndex(entries, (entry) => entry.id === updated.id);
      if (index >= 0) {
        entries[index] = updated;
      } else {
        entries.push(updated);
      }
      const list: PaginatedResponse<Category> = { ...state.list, entries };
      return { ...state, list };
    }

    case CATEGORY_DELETE_DONE: {
      const { id } = action.payload;
      const entries: Category[] = clone(state.list.entries);

      const index = findIndex(entries, (entry) => entry.id === id);
      if (index >= 0) {
        entries.splice(index, 1);
      }
      const list: PaginatedResponse<Category> = { ...state.list, entries };
      return { ...state, list };
    }

    default:
      return state;
  }
}
