import { connect } from "react-redux";

import PublisherDetailsComponent from "../../components/publisher/PublisherDetails";

import { PublisherEditState } from "../../redux/state";
import { edit } from "../../redux/actions/publisherActions";

const mapStateToProps = ({
  publisherEdit,
}: {
  publisherEdit: PublisherEditState;
}) => publisherEdit || {};

export default connect(mapStateToProps, { edit })(PublisherDetailsComponent);
