import {
  AUDIO_BOOK_OPEN,
  AudioBookOpen,
  AUDIO_BOOK_DELETE_DONE,
  AudioBookDeleteAction,
  CHAPTER_OPEN,
  ChapterOpen,
  CHAPTER_EDIT,
  ChapterEdit,
  CHAPTER_SAVE_START,
  CHAPTER_SAVE_DONE,
  CHAPTER_SAVE_FAILED,
  ChapterSaveAction,
  CHAPTER_DELETE_START,
  CHAPTER_DELETE_DONE,
  ChapterDeleteAction,
  ASSET_UPLOAD_START,
  ASSET_UPLOAD_DONE,
  ASSET_SAVE_START,
  ASSET_SAVE_DONE,
  ASSET_SAVE_FAILED,
  AssetSaveAction,
  ASSET_DELETE_START,
  ASSET_DELETE_DONE,
  AssetDeleteAction,
  CHAPTER_RELOAD,
  ChapterReload,
} from "../actionTypes";
import { ChapterEditState } from "../state";
import Chapter from "../../models/Chapter";

const initialState: ChapterEditState = {
  editing: false,
  sending: false,
  uploading: false,
  errors: {},
  saveSucceeded: false,
};

export default function reducer(
  state: ChapterEditState = initialState,
  action:
    | AudioBookOpen
    | AudioBookDeleteAction
    | ChapterOpen
    | ChapterEdit
    | ChapterReload
    | ChapterSaveAction
    | ChapterDeleteAction
    | AssetSaveAction
    | AssetDeleteAction
) {
  switch (action.type) {
    case AUDIO_BOOK_OPEN:
    case AUDIO_BOOK_DELETE_DONE: {
      return initialState;
    }

    case CHAPTER_OPEN: {
      return { ...initialState, chapter: action.payload };
    }

    case CHAPTER_EDIT: {
      return { ...state, ...initialState, editing: action.payload };
    }

    case CHAPTER_SAVE_START: {
      return { ...state, sending: true, errors: {}, saveSucceeded: false };
    }

    case CHAPTER_SAVE_DONE: {
      return {
        ...state,
        editing: false,
        sending: false,
        errors: {},
        chapter: action.payload,
        saveSucceeded: true,
      };
    }

    case CHAPTER_SAVE_FAILED: {
      return {
        ...state,
        editing: true,
        sending: false,
        errors: action.payload,
        saveSucceeded: false,
      };
    }

    case CHAPTER_DELETE_START: {
      return { ...state, sending: true };
    }

    case CHAPTER_DELETE_DONE: {
      return { ...state, sending: false, chapter: undefined };
    }

    case ASSET_UPLOAD_START: {
      return { ...state, uploading: true };
    }

    case ASSET_UPLOAD_DONE: {
      return { ...state, uploading: false };
    }

    case ASSET_DELETE_START:
    case ASSET_SAVE_START: {
      return { ...state, sending: true };
    }

    case ASSET_DELETE_DONE:
    case ASSET_SAVE_DONE:
    case ASSET_SAVE_FAILED: {
      return { ...state, sending: false };
    }

    case CHAPTER_RELOAD: {
      const reloaded: Chapter = action.payload;
      const chapter: Chapter | undefined = state.chapter;
      if (chapter && chapter.id === reloaded.id) {
        return { ...state, chapter: reloaded };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}
