import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import PeopleList from "../containers/people/PeopleList";
import PeopleDetails from "../containers/people/PeopleDetails";
import PeopleDetailsLight from "../containers/people/PeopleDetailsLight";

import CurrentUser from "../models/CurrentUser";

interface Props {
  currentUser: CurrentUser;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(3, 2),
    borderRadius: 12,
  },
}));

export default function PeopleBackoffice(props: Props) {
  const classes = useStyles();
  console.log("PROPS");
  const editing = useSelector(
    (state: RootStateOrAny) => state.peopleEdit.editing
  );

  return (
    <div>
      {!editing ? (
        <Paper className={classes.root}>
          <Typography component="div">
            <PeopleList />
          </Typography>
        </Paper>
      ) : (
        <div>
          <PeopleDetails />
        </div>
      )}
    </div>
  );
}
