import React, { Component } from "react";
import { connect } from "react-redux";

import PaginatedResponse from "../../models/PaginatedResponse";
import Publisher from "../../models/Publisher";

import PublisherListComponent, {
  SearchParams,
} from "../../components/publisher/PublisherList";
import {
  loadPublisherList,
  openPublisher,
  newPublisher,
  deletePublisher,
} from "../../redux/actions/publisherActions";
import { StoreState } from "../../redux/state";

interface Props {
  loading: boolean;
  list: PaginatedResponse<Publisher>;

  loadPublisherList: (params: SearchParams) => void;
  openPublisher: (publisher: Publisher) => void;
  deletePublisher: (id: string) => void;
  newPublisher: () => void;
}

class PublisherList extends Component<Props> {
  componentDidMount() {
    this.props.loadPublisherList({});
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.list.currentPage == prevProps.list.currentPage) {
      if (this.props.list.entries.length != prevProps.list.entries.length) {
        this.props.loadPublisherList({ search: this.props.list.search });
      }
    }
  }

  render() {
    console.log("render");
    const {
      loading,
      list,
      openPublisher,
      newPublisher,
      loadPublisherList,
      deletePublisher,
    } = this.props;
    const openById = (id: string) => {
      const publisher: Publisher | undefined = list.entries.find(
        (b) => b.id === id
      );
      if (publisher) {
        openPublisher(publisher);
      }
    };
    const deletePublisherById = (id: string) => {
      const publisher: Publisher | undefined = list.entries.find(
        (b) => b.id === id
      );
      if (publisher) {
        deletePublisher(id);
      }
    };

    return (
      <PublisherListComponent
        loading={loading}
        list={list}
        openPublisher={openById}
        newPublisher={newPublisher}
        searchPublisher={loadPublisherList}
        searchFunction={loadPublisherList}
        deletePublisher={deletePublisherById}
      />
    );
  }
}

const mapStateToProps = (store: StoreState) => {
  return store.publisherList;
};

export default connect(mapStateToProps, {
  loadPublisherList,
  openPublisher,
  newPublisher,
  deletePublisher,
})(PublisherList);
