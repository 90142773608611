import React, { Component } from "react";
import { connect } from "react-redux";

import PaginatedResponse from "../../models/PaginatedResponse";
import People from "../../models/People";

import PeopleListComponent, {
  SearchParams,
} from "../../components/people/PeopleList";
import {
  loadPeopleList,
  openPeople,
  newPeople,
  deletePeople,
} from "../../redux/actions/peopleActions";
import { StoreState } from "../../redux/state";

interface Props {
  loading: boolean;
  list: PaginatedResponse<People>;

  loadPeopleList: (params: SearchParams) => void;
  openPeople: (people: People) => void;
  newPeople: () => void;
  deletePeople: (id: string) => void;
}

class PeopleList extends Component<Props> {
  componentDidMount() {
    console.log("componentDidMount");
    console.log("before load");
    this.props.loadPeopleList({});
    console.log(this.props.list);
    console.log("after load");
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.list.currentPage == prevProps.list.currentPage) {
      if (this.props.list.entries.length != prevProps.list.entries.length) {
        this.props.loadPeopleList({ search: this.props.list.search });
      }
    }
  }
  render() {
    console.log("render");
    const {
      loading,
      list,
      openPeople,
      newPeople,
      loadPeopleList,
      deletePeople,
    } = this.props;
    const openById = (id: string) => {
      const people: People | undefined = list.entries.find((b) => b.id === id);
      if (people) {
        openPeople(people);
      }
    };

    return (
      <PeopleListComponent
        loading={loading}
        list={list}
        openPeople={openById}
        newPeople={newPeople}
        searchPeople={loadPeopleList}
        searchFunction={loadPeopleList}
        deletePeople={deletePeople}
      />
    );
  }
}

const mapStateToProps = (store: StoreState) => {
  return store.peopleList;
};

export default connect(mapStateToProps, {
  loadPeopleList,
  openPeople,
  newPeople,
  deletePeople,
})(PeopleList);
