import { connect } from "react-redux";

import PeopleDetailsComponent from "../../components/people/PeopleDetails";

import { PeopleEditState } from "../../redux/state";
import { edit } from "../../redux/actions/peopleActions";

const mapStateToProps = ({ peopleEdit }: { peopleEdit: PeopleEditState }) =>
  peopleEdit || {};

export default connect(mapStateToProps, { edit })(PeopleDetailsComponent);
