import { connect } from "react-redux";

import CategoryFormComponent, {
  DataProps as CategoryFormProps,
} from "../../components/category/CategoryForm";

import { CategoryEditState, CategoryListState } from "../../redux/state";
import {
  save,
  cancelEdit,
  deleteCategory,
  loadCategoryOption,
} from "../../redux/actions/categoryActions";
import { build as buildCategory } from "../../models/Category";

const mapStateToProps = ({
  categoryEdit,
  categoryList,
}: {
  categoryEdit: CategoryEditState;
  categoryList: CategoryListState;
}): CategoryFormProps => {
  const category = categoryEdit.category || buildCategory();
  const categoryOption = categoryList.listOption || [];
  return { ...categoryEdit, category, categoryOption };
};

export default connect(mapStateToProps, {
  save,
  cancelEdit,
  deleteCategory,
  loadCategoryOption,
})(CategoryFormComponent as any);
