import clone from "lodash/clone";
import findIndex from "lodash/findIndex";

import {
  PUBLISHER_LIST_LOAD_START,
  PUBLISHER_LIST_LOAD_DONE,
  PublisherListAction,
  PUBLISHER_SAVE_DONE,
  PublisherSaveAction,
  PUBLISHER_DELETE_DONE,
  PublisherDeleteAction,
  PUBLISHER_LIST_OPTION_LOAD_START,
  PUBLISHER_LIST_OPTION_LOAD_DONE,
  PublisherListOptionAction,
} from "../actionTypes";
import { PublisherListState } from "../state";
import PaginatedResponse, { empty } from "../../models/PaginatedResponse";
import Publisher from "../../models/Publisher";

const initialState: PublisherListState = {
  loading: false,
  list: empty,
  listOption: [],
};

export default function reducer(
  state: PublisherListState = initialState,
  action:
    | PublisherListAction
    | PublisherSaveAction
    | PublisherDeleteAction
    | PublisherListOptionAction
) {
  switch (action.type) {
    case PUBLISHER_LIST_LOAD_START: {
      return { ...state, loading: true };
    }

    case PUBLISHER_LIST_LOAD_DONE: {
      console.log("reducer:PUBLISHER_LIST_LOAD_DONE");
      console.log(action.payload);
      console.log("reducer:PUBLISHER_LIST_LOAD_DONE - END");
      return { ...state, loading: false, list: action.payload };
    }

    case PUBLISHER_SAVE_DONE: {
      const entries: Publisher[] = clone(state.list.entries);
      const updated: Publisher = action.payload;

      const index = findIndex(entries, (entry) => entry.id === updated.id);
      if (index >= 0) {
        entries[index] = updated;
      } else {
        entries.push(updated);
      }
      const list: PaginatedResponse<Publisher> = { ...state.list, entries };
      return { ...state, list };
    }

    case PUBLISHER_DELETE_DONE: {
      const { id } = action.payload;
      const entries: Publisher[] = clone(state.list.entries);

      const index = findIndex(entries, (entry) => entry.id === id);
      if (index >= 0) {
        entries.splice(index, 1);
      }
      const list: PaginatedResponse<Publisher> = { ...state.list, entries };
      return { ...state, list };
    }

    case PUBLISHER_LIST_OPTION_LOAD_START: {
      return { ...state, loading: true };
    }

    case PUBLISHER_LIST_OPTION_LOAD_DONE: {
      return { ...state, loading: false, listOption: action.payload };
    }

    default:
      return state;
  }
}
