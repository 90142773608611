import { connect } from "react-redux";

import ContentFormComponent, {
  DataProps as ContentFormProps,
} from "../../components/content/ContentForm";

import { ContentEditState, PeopleListState } from "../../redux/state";
import {
  save,
  cancelEdit,
  deleteContent,
} from "../../redux/actions/contentActions";
import { build as buildContent } from "../../models/Content";
import { loadPeopleOption } from "../../redux/actions/peopleActions";

const mapStateToProps = ({
  contentEdit,
  peopleList,
}: {
  contentEdit: ContentEditState;
  peopleList: PeopleListState;
}): ContentFormProps => {
  const content = contentEdit.content || buildContent();
  const peopleOption = peopleList.listOption || [];
  return { ...contentEdit, content, peopleOption };
};

export default connect(mapStateToProps, {
  save,
  cancelEdit,
  deleteContent,
  loadPeopleOption,
})(ContentFormComponent as any);
