import {

  RESOURCES_SAVE_RELATION_START,
  RESOURCES_SAVE_RELATION_DONE,
  ResourcesSaveRelationAction,

  RESOURCES_LIST_LOAD_START,
  RESOURCES_LIST_LOAD_DONE,
  ResourcesListAction,

  RESOURCES_LOAD_START,
  RESOURCES_LOAD_DONE,
  ResourcesAction,

  RESOURCES_OPEN,
  ResourcesOpen,

  RESOURCES_EDIT,
  ResourcesEdit,

  RESOURCES_SAVE_START,
  RESOURCES_SAVE_DONE,
  RESOURCES_SAVE_FAILED,
  ResourcesSaveAction,

  RESOURCES_DELETE_START,
  RESOURCES_DELETE_DONE,
  ResourcesDeleteAction
} from '../actionTypes'
import { service as resourcesService, SearchParams } from '../../services/ResourcesService'
import PaginatedResponse from '../../models/PaginatedResponse'
import Resources, { build as buildResources } from '../../models/Resources'
import ResourcesHasContainer from '../../models/ResourcesHasContainer'

export function loadResourcesList(params?: SearchParams): (dispatch: (action: ResourcesListAction) => void) => void {
  console.log("action:loadResourcesList")
  return async dispatch => {
    dispatch({ type: RESOURCES_LIST_LOAD_START })

    const list: PaginatedResponse<Resources> = await resourcesService.search(params || {})
    console.log(list)
    dispatch({ type: RESOURCES_LIST_LOAD_DONE, payload: list })
  }
}

export function getResources(id: string): (dispatch: (action: ResourcesAction | ResourcesOpen) => void) => void {
  console.log("action:loadResourcesList")
  return async dispatch => {
    dispatch({ type: RESOURCES_LOAD_START })

    const list: Resources = await resourcesService.getResources(id)
    console.log(list)
    dispatch({ type: RESOURCES_LOAD_DONE, payload: list, editing:false })
    //dispatch({ type: RESOURCES_OPEN, payload: list, editing:true })
  }
}


export function openResources(payload: Resources): (dispatch: (action: ResourcesOpen) => void) => void {
  return async dispatch => {
    dispatch({ type: RESOURCES_OPEN, payload, editing:true})
  }
}

export function newResources(): (dispatch: (action: ResourcesOpen | ResourcesEdit) => void) => void {
  return async dispatch => {
    const newResources: Resources = buildResources()
    dispatch({ type: RESOURCES_OPEN, payload: newResources, editing:true })
    dispatch({ type: RESOURCES_EDIT, payload: true })
  }
}

export function edit(): (dispatch: (action: ResourcesEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: RESOURCES_EDIT, payload: true})
  }
}

export function cancelEdit(): (dispatch: (action: ResourcesEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: RESOURCES_EDIT, payload: false })
  }
}

export function save(resources: Resources): (dispatch: (action: ResourcesSaveAction) => void) => void {
  return async dispatch => {
    dispatch({ type: RESOURCES_SAVE_START })
    try {
      const updatedModel: Resources = await resourcesService.save(resources)
      dispatch({ type: RESOURCES_SAVE_DONE, payload: updatedModel })
    } catch (err) {
      //dispatch({ type: RESOURCES_SAVE_FAILED, payload: err.data })
    }
  }
}

export function saveContainerRelated(resources_id:string,resourcesHasContainer: ResourcesHasContainer[]): (dispatch: (action: ResourcesSaveRelationAction) => void) => void {
  return async dispatch => {
    dispatch({ type: RESOURCES_SAVE_RELATION_START })
    try {
      const updatedModel: Resources = await resourcesService.saveContainerRelated(resources_id,resourcesHasContainer)
      dispatch({ type: RESOURCES_SAVE_RELATION_DONE, payload: updatedModel })
    } catch (err) {
      
    }
  }
}

export function deleteResources(id: string): (dispatch: (action: ResourcesDeleteAction) => void) => void {
  return async dispatch => {
    dispatch({ type: RESOURCES_DELETE_START, payload: { id } })
    try {
      await resourcesService.deleteModel(id)
      dispatch({ type: RESOURCES_DELETE_DONE, payload: { id } })
    } catch (err) {}
  }
}
