import {
  CONTAINER_SAVE_RELATION_START,
  CONTAINER_SAVE_RELATION_DONE,
  ContainerSaveRelationAction,

  CONTAINER_LIST_OPTION_LOAD_START,
  CONTAINER_LIST_OPTION_LOAD_DONE,  
  ContainerListOptionAction,

  CONTAINER_LIST_LOAD_START,
  CONTAINER_LIST_LOAD_DONE,
  ContainerListAction,

  CONTAINER_OPEN,
  ContainerOpen,

  CONTAINER_EDIT,
  ContainerEdit,

  CONTAINER_SAVE_START,
  CONTAINER_SAVE_DONE,
  CONTAINER_SAVE_FAILED,
  ContainerSaveAction,

  CONTAINER_DELETE_START,
  CONTAINER_DELETE_DONE,
  ContainerDeleteAction
} from '../actionTypes'
import { service as containerService, SearchParams } from '../../services/ContainerService'
import PaginatedResponse from '../../models/PaginatedResponse'
import Container, { build as buildContainer } from '../../models/Container'
import OptionResponse from '../../models/OptionResponse'
import ContainerHasContent from '../../models/ContainerHasContent'

export function loadContainerList(params?: SearchParams): (dispatch: (action: ContainerListAction) => void) => void {
  console.log("action:loadContainerList")
  return async dispatch => {
    dispatch({ type: CONTAINER_LIST_LOAD_START })

    const list: PaginatedResponse<Container> = await containerService.search(params || {})
    console.log(list)
    dispatch({ type: CONTAINER_LIST_LOAD_DONE, payload: list })
  }
}

export function loadContainerOption(params?: SearchParams): (dispatch: (action: ContainerListOptionAction) => void) => void {
  console.log("action:loadContainerOption")
  return async dispatch => {
    dispatch({ type: CONTAINER_LIST_OPTION_LOAD_START })

    const list: OptionResponse[] = await containerService.getOptionList(params || {})
    console.log(list)
    dispatch({ type: CONTAINER_LIST_OPTION_LOAD_DONE, payload: list })
  }
}


export function openContainer(payload: Container): (dispatch: (action: ContainerOpen) => void) => void {
  return async dispatch => {
    dispatch({ type: CONTAINER_OPEN, payload, editing:true})
  }
}

export function newContainer(): (dispatch: (action: ContainerOpen | ContainerEdit) => void) => void {
  return async dispatch => {
    const newContainer: Container = buildContainer()
    dispatch({ type: CONTAINER_OPEN, payload: newContainer, editing:true })
    dispatch({ type: CONTAINER_EDIT, payload: true })
  }
}

export function edit(): (dispatch: (action: ContainerEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: CONTAINER_EDIT, payload: true})
  }
}

export function cancelEdit(): (dispatch: (action: ContainerEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: CONTAINER_EDIT, payload: false })
  }
}

export function save(container: Container): (dispatch: (action: ContainerSaveAction) => void) => void {
  return async dispatch => {
    dispatch({ type: CONTAINER_SAVE_START })
    try {
      const updatedModel: Container = await containerService.save(container)
      dispatch({ type: CONTAINER_SAVE_DONE, payload: updatedModel })
    } catch (err) {
      //dispatch({ type: CONTAINER_SAVE_FAILED, payload: err.data })
    }
  }
}

export function deleteContainer(id: string): (dispatch: (action: ContainerDeleteAction) => void) => void {
  return async dispatch => {
    dispatch({ type: CONTAINER_DELETE_START, payload: { id } })
    try {
      await containerService.deleteModel(id)
      dispatch({ type: CONTAINER_DELETE_DONE, payload: { id } })
    } catch (err) {}
  }
}

export function saveContentRelated(resources_id:string,resourcesHasContainer: ContainerHasContent[]): (dispatch: (action: ContainerSaveRelationAction) => void) => void {
  return async dispatch => {
    dispatch({ type: CONTAINER_SAVE_RELATION_START })
    try {
      const updatedModel: Container = await containerService.saveContentRelated(resources_id,resourcesHasContainer)
      dispatch({ type: CONTAINER_SAVE_RELATION_DONE, payload: updatedModel })
    } catch (err) {
      
    }
  }
}
