import {
  DASHBOARD_LOAD_START,
  DASHBOARD_LOAD_DONE,
  DASHBOARD_REDIRECT_CONTAINER_TYPE,
  DashboardAction,
} from "../actionTypes";
import { DashboardState } from "../state";
import DashboardModel, { build } from "../../models/Dashboard";
import { Console } from "console";

const initialState: DashboardState = {
  dashboard: build(),
  isRedirectContainer: false,
};
export default function reducer(
  state: DashboardState = initialState,
  action: DashboardAction
) {
  switch (action.type) {
    case DASHBOARD_LOAD_START: {
      return { ...state, loading: true };
    }

    case DASHBOARD_LOAD_DONE: {
      console.log("reducer:DASHBOARD_LOAD_DONE");
      console.log(action.payload);
      return { ...state, dashboard: action.payload };
    }

    case DASHBOARD_REDIRECT_CONTAINER_TYPE: {
      console.log("IS REDIRECT");
      console.log(action.isRedirectContainer);

      return { ...state, isRedirectContainer: action.isRedirectContainer };
    }

    default:
      return state;
  }
}
