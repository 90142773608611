import { connect } from "react-redux";

import ResourcesDetailsComponent from "../../components/resources/ResourcesDetails";

import { ResourcesEditState } from "../../redux/state";
import { edit } from "../../redux/actions/resourcesActions";

const mapStateToProps = ({
  resourcesEdit,
}: {
  resourcesEdit: ResourcesEditState;
}) => resourcesEdit || {};

export default connect(mapStateToProps, { edit })(ResourcesDetailsComponent);
