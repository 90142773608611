export default interface Publisher extends Record<string, any> {
  id: string;
  description: string;
  link_external?: string;
  name: string;
  logo?: string;
  created_at: string;
  updated_at: string;
}

export function build() {
  return {
    id: "",
    description: "",
    link_external: "",
    name: "",
    logo: "",
    created_at: "",
    updated_at: "",
  };
}
