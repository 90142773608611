import React, { Component } from "react";
import { connect } from "react-redux";
import PaginatedResponse from "../../models/PaginatedResponse";
import Category from "../../models/Category";
import CategoryListComponent, {
  SearchParams,
} from "../../components/category/CategoryList";
import {
  loadCategoryList,
  openCategory,
  newCategory,
  deleteCategory,
} from "../../redux/actions/categoryActions";
import { StoreState } from "../../redux/state";

interface Props {
  loading: boolean;
  list: PaginatedResponse<Category>;

  loadCategoryList: (params: SearchParams) => void;
  openCategory: (category: Category) => void;
  newCategory: () => void;
  deleteCategory: (id: string) => void;
}

class CategoryList extends Component<Props> {
  componentDidMount() {
    console.log("componentDidMount");
    console.log("before load");
    this.props.loadCategoryList({});
    console.log("after load");
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.list.currentPage == prevProps.list.currentPage) {
      if (this.props.list.entries.length != prevProps.list.entries.length) {
        this.props.loadCategoryList({ search: this.props.list.search });
      }
    }
  }

  render() {
    console.log("render");
    const {
      loading,
      list,
      openCategory,
      newCategory,
      loadCategoryList,
      deleteCategory,
    } = this.props;
    const openById = (id: string) => {
      const category: Category | undefined = list.entries.find(
        (b) => b.id === id
      );
      if (category) {
        openCategory(category);
      }
    };

    return (
      <CategoryListComponent
        loading={loading}
        list={list}
        openCategory={openById}
        newCategory={newCategory}
        searchCategory={loadCategoryList}
        searchFunction={loadCategoryList}
        deleteCategory={deleteCategory}
      />
    );
  }
}

const mapStateToProps = (store: StoreState) => {
  return store.categoryList;
};

export default connect(mapStateToProps, {
  loadCategoryList,
  openCategory,
  newCategory,
  deleteCategory,
})(CategoryList);
