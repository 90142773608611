import React from "react";
import ReactPlaceholder from "react-placeholder";
import { Container, Row, Col } from "react-bootstrap";

import "react-placeholder/lib/reactPlaceholder.css";

interface Props {
  rows?: number;
}

export default function Loader(props: Props) {
  const rows = props.rows || 3;

  return (
    <div className="main-content vertically-centered">
      <Container>
        <Row>
          <Col xs={12} style={{ textAlign: "center" }}>
            <span>Loading...</span>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {/* <ReactPlaceholder
              type="text"
              rows={rows}
              ready={false}
              showLoadingAnimation={true}
            ></ReactPlaceholder> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
