import React, { Component } from "react";
import Select from "react-select";
import { Form, ButtonGroup, Button, Card, Col } from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";

import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import FormInputErrors, { hasErrors } from "../lib/FormInputErrors";

import People from "../../models/People";
import OptionResponse from "../../models/OptionResponse";
import imgplaceholder from "../layout/img/upload_image_placeholder.png";

export interface DataProps {
  people: People;
  sending: boolean;
  errors: Errors;
}

interface Props extends DataProps {
  save: (people: People) => void;
  cancelEdit: () => void;
  deletePeople: (id: string) => void;
}

interface Errors {
  id?: string[];
  first_name?: string[];
  last_name?: string[];
  description?: string[];
  image_square?: string[];
  type?: string[];
  copyright?: string[];
  link_external?: string[];
  link_youtube?: string[];
}

interface State {
  peopleEditing: People;
}

export default class PeopleForm extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = { peopleEditing: cloneDeep(this.props.people) };

    this.save = this.save.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setValueImage = this.setValueImage.bind(this);
  }

  componentWillUnmount() {
    this.props.cancelEdit();
  }

  save() {
    this.props.save(this.state.peopleEditing);
  }

  setStringValue(attribute: string, options: string | undefined) {
    const peopleEditing = cloneDeep(this.state.peopleEditing);

    peopleEditing[attribute] = options;
    this.setState({ peopleEditing });
  }

  setValue(
    attribute: string,
    { target: { value } }: { target: { value: string } }
  ) {
    const peopleEditing = cloneDeep(this.state.peopleEditing);

    peopleEditing[attribute] = value;
    this.setState({ peopleEditing });
  }
  async setValueImage(attribute: string, value?: FileList | null) {
    console.log(value);
    const peopleEditing = cloneDeep(this.state.peopleEditing);
    var reader = new FileReader();
    if (value == null) {
      peopleEditing[attribute] = "";
      this.setState({ peopleEditing });
    } else {
      reader.readAsDataURL(value?.item(0) || new File([], ""));
      var parentThis = this;
      reader.onload = function() {
        peopleEditing[attribute] = reader.result?.toString() || "";
        parentThis.setState({ peopleEditing });
      };
      reader.onerror = function(error) {
        alert("Error: " + error);
      };
      //this.props.save(this.state.resourcesEditing)
    }
  }

  render() {
    const {
      sending,
      errors,
      people: { id },
      deletePeople,
    } = this.props;
    const { peopleEditing } = this.state;
    const isNewRecord = !id;

    const tipologieOption: OptionResponse[] = [
      { label: "Autore", value: "Author" },
    ];
    let tipologieOptionSelected = null;
    if (
      tipologieOption.find((f) => f.value == peopleEditing.type) != undefined
    ) {
      console.log("Selezionato");
      console.log(tipologieOption.find((f) => f.value == peopleEditing.type));
      tipologieOptionSelected = tipologieOption.find(
        (f) => f.value == peopleEditing.type
      );
    }
    console.log(peopleEditing);
    if (peopleEditing.image_portrait != null) {
      var url = "url(".concat(peopleEditing.image_portrait || "").concat(")");
    } else {
      var url = "url(".concat(imgplaceholder).concat(")");
    }
    if (peopleEditing.image_square != null) {
      var urlSquare = "url("
        .concat(peopleEditing.image_square || "")
        .concat(")");
    } else {
      var urlSquare = "";
    }

    return (
      <>
        <Paper className="custom-paper">
          <Typography component="div">
            <Form>
              <Form.Group>
                <Form.Control
                  isInvalid={hasErrors(errors, "id")}
                  disabled={true}
                  hidden={true}
                  type="text"
                  value={peopleEditing.id || ""}
                  onChange={(event) => this.setValue("id", event)}
                />
                <FormInputErrors
                  elementId={`people-${id}-id-field`}
                  errors={errors.id}
                />
              </Form.Group>

              <Form.Row className="mt-4 ">
                <Col xs md lg="8">
                  <Form.Row>
                    <Col xs md lg="3">
                      <Form.Group>
                        <div
                          className="image-input image-input-outline"
                          id="kt_image_1"
                        >
                          <div
                            className="image-input-wrapper"
                            style={{ background: urlSquare }}
                          >
                            <img
                              className="placeholder-image"
                              hidden={
                                peopleEditing.image_square != "" &&
                                peopleEditing.image_square != null
                              }
                              src={imgplaceholder}
                            />
                            <label
                              style={{
                                width: "100%",
                                height: "100%",
                                opacity: 0,
                              }}
                              className="custom-image-edit-icon-btn btn btn-xs btn-icon  btn-white btn-hover-text-primary btn-shadow"
                              data-action="change"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="Change avatar"
                            >
                              <i className="fa fa-pen icon-sm text-muted"></i>
                              <Form.Control
                                isInvalid={hasErrors(errors, "image_square")}
                                disabled={sending}
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  this.setValueImage(
                                    "image_square",
                                    event.currentTarget.files
                                  )
                                }
                              />
                              <input
                                type="hidden"
                                name="profile_avatar_remove"
                              />
                            </label>
                          </div>

                          <label
                            hidden={
                              peopleEditing.image_square == null ||
                              peopleEditing.image_square == ""
                            }
                            className="custom-image-edit-icon-btn btn btn-xs btn-icon  btn-white btn-hover-text-primary btn-shadow"
                            data-action="change"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Change avatar"
                          >
                            <i className="fa fa-pen icon-sm text-muted"></i>
                            <Form.Control
                              isInvalid={hasErrors(errors, "image_square")}
                              disabled={sending}
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                this.setValueImage(
                                  "image_square",
                                  event.currentTarget.files
                                )
                              }
                            />
                            <input type="hidden" name="profile_avatar_remove" />
                          </label>

                          <Button
                            className="custom-image-edit-icon-btn btn btn-xs btn-icon btn-white btn-hover-text-primary btn-shadow"
                            variant=""
                            data-action="remove"
                            disabled={sending}
                            hidden={
                              peopleEditing.image_square == null ||
                              peopleEditing.image_square == ""
                            }
                            data-toggle="tooltip"
                            title="Cancel avatar"
                            onClick={() =>
                              this.setValueImage("image_square", null)
                            }
                          >
                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs md lg="8" className="ml-4">
                      <Form.Group>
                        <Form.Row>
                          <Form.Label className="text-dark-75 font-weight-bolder">
                            {peopleEditing.first_name.concat(
                              " ".concat(peopleEditing.last_name || "")
                            ) || ""}
                          </Form.Label>
                        </Form.Row>
                        <Form.Row>
                          <Form.Label className="">
                            <i className="duotone-text-icon text-muted icon-1x r-5"></i>
                            Contributore
                          </Form.Label>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Col>
                <Col xs md lg="4" hidden>
                  <Form.Row>
                    <Col xs md lg="4">
                      <Form.Group>
                        <Form.Row>
                          <Form.Label className="text-dark-75 font-weight-bolder">
                            Visibilità stato
                          </Form.Label>
                        </Form.Row>
                      </Form.Group>
                    </Col>

                    <Col xs md lg="8">
                      <Form.Group>
                        <Form.Row>
                          <Select
                            isDisabled
                            isClearable
                            placeholder="Seleziona visibilità"
                            onChange={(selectedOption) => {
                              console.log(selectedOption);
                              //this.setStringValue("status",selectedOption?.value)
                            }}
                          />
                        </Form.Row>
                        <Form.Row className="mt-4 box-ref">
                          <Col>
                            <Form.Row>
                              <Form.Label className="text-dark-100 font-weight-bolder custom-font-size-24">
                                25
                              </Form.Label>
                            </Form.Row>
                            <Form.Row>
                              <Form.Label className="text-dark-100 font-weight-bolder custom-font-light">
                                Risorse collegate
                              </Form.Label>
                            </Form.Row>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Col>
              </Form.Row>
            </Form>
          </Typography>
        </Paper>
        <Paper className="custom-paper">
          <Typography variant="h5" component="h3">
            <b>Anagrafica contributori</b>
          </Typography>
          <div className="separator separator-solid my-7"></div>
          <Typography component="div">
            <Form>
              <Form.Group>
                <Form.Control
                  isInvalid={hasErrors(errors, "id")}
                  disabled={true}
                  hidden={true}
                  type="text"
                  value={peopleEditing.id || ""}
                  onChange={(event) => this.setValue("id", event)}
                />
                <FormInputErrors
                  elementId={`people-${id}-id-field`}
                  errors={errors.id}
                />
              </Form.Group>

              <Form.Row className="mt-4">
                <Col xs md lg={{ span: 4, offset: 4 }}>
                  <Form.Group>
                    <Select
                      isClearable
                      placeholder="Seleziona tipologia"
                      options={tipologieOption}
                      value={tipologieOption}
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        this.setStringValue("type", selectedOption?.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Nome
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      isInvalid={hasErrors(errors, "first_name")}
                      disabled={sending}
                      className="custom-input"
                      placeholder="Inserisci il nome del contributore"
                      type="text"
                      value={peopleEditing.first_name || ""}
                      onChange={(event) => this.setValue("first_name", event)}
                    />
                    <FormInputErrors
                      elementId={`people-${id}-first_name-field`}
                      errors={errors.first_name}
                    />
                  </Form.Group>
                </Col>
                <Col xs md lg="4">
                  <Form.Group>
                    {/*
                    <Form.Control
                      isInvalid={hasErrors(errors, 'last_name')}
                      disabled={sending}
                      className='custom-input'
                      placeholder='Cognome del contributore'
                      type="text" value={peopleEditing.last_name || ''}
                      onChange={event => this.setValue('last_name', event)} />
                    <FormInputErrors elementId={`people-${id}-last_name-field`} errors={errors.last_name} />
                    */}
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Cognome
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      isInvalid={hasErrors(errors, "first_name")}
                      disabled={sending}
                      className="custom-input"
                      placeholder="Inserisci il nome del contributore"
                      type="text"
                      value={peopleEditing.last_name || ""}
                      onChange={(event) => this.setValue("last_name", event)}
                    />
                    <FormInputErrors
                      elementId={`people-${id}-first_name-field`}
                      errors={errors.last_name}
                    />
                  </Form.Group>
                </Col>
                <Col xs md lg="4">
                  <Form.Group>
                    {/*
                    <Form.Control
                      isInvalid={hasErrors(errors, 'last_name')}
                      disabled={sending}
                      className='custom-input'
                      placeholder='Cognome del contributore'
                      type="text" value={peopleEditing.last_name || ''}
                      onChange={event => this.setValue('last_name', event)} />
                    <FormInputErrors elementId={`people-${id}-last_name-field`} errors={errors.last_name} />
                    */}
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Descrizione
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      isInvalid={hasErrors(errors, "description")}
                      disabled={sending}
                      className="custom-input"
                      placeholder="Inserisci una descrizione"
                      type="text"
                      value={peopleEditing.description || ""}
                      onChange={(event) => this.setValue("description", event)}
                    />
                    <FormInputErrors
                      elementId={`people-${id}-description-field`}
                      errors={errors.description}
                    />
                  </Form.Group>
                </Col>
                <Col xs md lg="4"></Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Carica foto landscape
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <div
                      className="image-input image-input-outline"
                      id="kt_image_1"
                    >
                      <div
                        className="image-input-wrapper image-input-wrapper-landscape"
                        style={{ background: url }}
                      >
                        <img
                          className="placeholder-image"
                          hidden={
                            peopleEditing.image_portrait != null &&
                            peopleEditing.image_portrait != ""
                          }
                          src={imgplaceholder}
                        />
                        <label
                          style={{ width: "100%", height: "100%", opacity: 0 }}
                          className="custom-image-edit-icon-btn btn btn-xs btn-icon btn-white btn-hover-text-primary btn-shadow"
                          data-action="change"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Change avatar"
                        >
                          <i className="fa fa-pen icon-sm text-muted"></i>
                          <Form.Control
                            isInvalid={hasErrors(errors, "image_portrait")}
                            disabled={sending}
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              this.setValueImage(
                                "image_portrait",
                                event.currentTarget.files
                              )
                            }
                          />
                          <input type="hidden" name="profile_avatar_remove" />
                        </label>
                      </div>

                      <label
                        hidden={
                          peopleEditing.image_portrait == null ||
                          peopleEditing.image_portrait == ""
                        }
                        className="custom-image-edit-icon-btn btn btn-xs btn-icon btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="Change avatar"
                      >
                        <i className="fa fa-pen icon-sm text-muted"></i>
                        <Form.Control
                          isInvalid={hasErrors(errors, "image_portrait")}
                          disabled={sending}
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            this.setValueImage(
                              "image_portrait",
                              event.currentTarget.files
                            )
                          }
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>
                      <Button
                        className="custom-image-edit-icon-btn btn btn-xs btn-icon btn-white btn-hover-text-primary btn-shadow"
                        variant=""
                        data-action="remove"
                        disabled={sending}
                        hidden={
                          peopleEditing.image_portrait == null ||
                          peopleEditing.image_portrait == ""
                        }
                        data-toggle="tooltip"
                        title="Cancel avatar"
                        onClick={() =>
                          this.setValueImage("image_portrait", null)
                        }
                      >
                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                      </Button>
                    </div>
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Copyright
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      isInvalid={hasErrors(errors, "copyright")}
                      disabled={sending}
                      className="custom-input"
                      placeholder="Inserisci i dati del copyright"
                      type="text"
                      value={peopleEditing.copyright || ""}
                      onChange={(event) => this.setValue("copyright", event)}
                    />
                    <FormInputErrors
                      elementId={`people-${id}-copyright-field`}
                      errors={errors.copyright}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Link esterni
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      isInvalid={hasErrors(errors, "link_external")}
                      disabled={sending}
                      className="custom-input"
                      placeholder="Inserisci un collegamento esterno"
                      type="text"
                      value={peopleEditing.link_external || ""}
                      onChange={(event) =>
                        this.setValue("link_external", event)
                      }
                    />
                    <FormInputErrors
                      elementId={`people-${id}-link_external-field`}
                      errors={errors.link_external}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Link Youtube
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      isInvalid={hasErrors(errors, "link_youtube")}
                      disabled={sending}
                      className="custom-input"
                      placeholder="Inserisci un collegamento al canale youtube"
                      type="text"
                      value={peopleEditing.link_youtube || ""}
                      onChange={(event) => this.setValue("link_youtube", event)}
                    />
                    <FormInputErrors
                      elementId={`people-${id}-link_youtube-field`}
                      errors={errors.link_youtube}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <div className="separator separator-solid my-7"></div>

              <Form.Row className="mt-4 justify-content-between">
                {/*
                  !isNewRecord ?
                    <DeleteButtonClickConfirm
                      label="Delete people" labelConfirm="Click to confirm" size="sm"
                      action={() => deletePeople(id)}
                      disabled={sending} />
                    :
                    <div />
                */}
                <div />
                <div>
                  <Button
                    onClick={this.props.cancelEdit}
                    disabled={sending}
                    size="sm"
                    variant="link"
                    className="marginButton"
                  >
                    Annulla
                  </Button>

                  <Button
                    onClick={this.save}
                    disabled={sending}
                    size="sm"
                    variant="primary"
                  >
                    Salva modifiche
                  </Button>
                </div>
              </Form.Row>
            </Form>
          </Typography>
        </Paper>
      </>
    );
  }
}
