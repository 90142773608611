import { AxiosRequestConfig } from "axios";

import HttpClientService, {
  HttpClientResponse,
} from "./HttpClientServiceLaravel";
import PaginatedResponse from "../models/PaginatedResponse";
import Resources from "../models/Resources";
import ResourcesHasContainer from "../models/ResourcesHasContainer";

export interface SearchParams {
  page?: number;
  search?: string;
}

export default class ResourcesService extends HttpClientService {
  async search(params: SearchParams): Promise<PaginatedResponse<Resources>> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/v1/resources",
      params,
    };

    const response: HttpClientResponse<PaginatedResponse<
      Resources
    >> = await this.doRequest(request);
    console.log(response);
    return response.data;
  }

  async getResources(id: string): Promise<Resources> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/v1/resources/" + id,
    };

    const response: HttpClientResponse<Resources> = await this.doRequest(
      request
    );
    console.log(response);
    return response.data;
  }

  async save(model: Resources): Promise<Resources> {
    let request: AxiosRequestConfig;
    const data: any = { ...model, id: undefined };
    if (data.authors) {
      data.overrideAuthors = data.authors.map((a: any) => a.id);
      delete data.authors;
    }

    if (model.id) {
      request = {
        method: "PATCH",
        url: `/api/v1/resources/${model.id}`,
        data: { resources: data },
      };
    } else {
      request = {
        method: "POST",
        url: "/api/v1/resources",
        data: { resources: data },
      };
    }

    const response: HttpClientResponse<Resources> = await this.doRequest(
      request
    );
    return response.data;
  }

  async saveContainerRelated(
    resources_id: string,
    model: ResourcesHasContainer[]
  ): Promise<Resources> {
    let request: AxiosRequestConfig;
    const data: any = { ...model };

    request = {
      method: "PATCH",
      url: `/api/v1/resources/relatedcontainer/${resources_id}`,
      data: { data },
    };

    const response: HttpClientResponse<Resources> = await this.doRequest(
      request
    );
    return response.data;
  }

  async deleteModel(id: string): Promise<boolean> {
    const request: AxiosRequestConfig = {
      method: "DELETE",
      url: `/api/v1/resources/${id}`,
    };

    await this.doRequest(request);
    return true;
  }
}

export const service = new ResourcesService();
