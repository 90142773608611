import { connect } from "react-redux";

import DashboardComponent, {
  DataProps as DashboardFormProps,
} from "../../components/dashboard/Dashboard";

import { DashboardState } from "../../redux/state";
import {
  loadDashboard,
  enableContainerRedirect,
  disableContainerRedirect,
} from "../../redux/actions/dashboardActions";
import { newContainer } from "../../redux/actions/containerActions";
import { newResources } from "../../redux/actions/resourcesActions";
import { build as buildDashboard } from "../../models/Dashboard";

const mapStateToProps = ({
  editDashboard,
}: {
  editDashboard: DashboardState;
}): DashboardFormProps => {
  console.log("DASHBOARD STATE");
  console.log(editDashboard);
  const dashboard = editDashboard.dashboard || buildDashboard();
  console.log("DASHBOARD STATE 1");
  console.log(dashboard);
  //const dashboard = buildDashboard()
  //const dashboard : DashboardModel = {}
  return { ...editDashboard, dashboard };
};

export default connect(mapStateToProps, {
  loadDashboard,
  newContainer,
  enableContainerRedirect,
  disableContainerRedirect,
  newResources,
})(DashboardComponent as any);
