import React from "react";
import { Button, Table } from "react-bootstrap";
import join from "lodash/join";

import CategoryForm from "../../containers/category/CategoryForm";
import Category from "../../models/Category";

interface Props {
  category?: Category;
  editing: boolean;

  edit: () => void;
}

export default function CategoryDetails(props: Props) {
  if (!props.category) {
    return <p className="font-italic">Select a category from the list</p>;
  }
  console.log(props);
  const { category } = props;

  return (
    <>
      <div className="mt-4 mb-4">{<CategoryForm />}</div>
    </>
  );
}

function DetailsTable({ category }: { category: Category }) {
  return (
    <Table size="sm">
      <tbody>
        <tr>
          <th>ID</th>
          <td>{category.id}</td>
        </tr>
        <tr>
          <th>Name</th>
          <td>{category.name}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{category.description}</td>
        </tr>
      </tbody>
    </Table>
  );
}
