import {
  EXCEL_UPLOAD_START,
  EXCEL_UPLOAD_DONE,

  EXCEL_DOWNLOAD_START,
  EXCEL_DOWNLOAD_DONE,
  ExcelAction,

} from '../actionTypes'

import { service as excelService } from '../../services/ExcelService'

export function resetUpload(): (dispatch: (action: ExcelAction) => void) => void {
  return async dispatch => {
    console.log("reset_upload")
    dispatch({ type: EXCEL_UPLOAD_DONE, importResponse:undefined })
  }
}

export function upload(file: File): (dispatch: (action: ExcelAction) => void) => void {
  return async dispatch => {
    dispatch({ type: EXCEL_UPLOAD_START })

      excelService.upload(file).then(res => {
        console.log("ENDED")
        console.log(res)
        dispatch({ type: EXCEL_UPLOAD_DONE, importResponse:res })
      })

  }
}

export function download(): (dispatch: (action: ExcelAction) => void) => void {
  return async dispatch => {
    dispatch({ type: EXCEL_DOWNLOAD_START })

    await excelService.donwload()

    dispatch({ type: EXCEL_DOWNLOAD_DONE })
  }
}

