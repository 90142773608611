import {
  RESOURCES_LOAD_START,
  RESOURCES_LOAD_DONE,
  ResourcesAction,
  RESOURCES_OPEN,
  ResourcesOpen,
  RESOURCES_EDIT,
  ResourcesEdit,
  RESOURCES_SAVE_RELATION_START,
  RESOURCES_SAVE_RELATION_DONE,
  RESOURCES_SAVE_RELATION_FAILED,
  ResourcesSaveRelationAction,
  RESOURCES_SAVE_START,
  RESOURCES_SAVE_DONE,
  RESOURCES_SAVE_FAILED,
  ResourcesSaveAction,
  RESOURCES_DELETE_START,
  RESOURCES_DELETE_DONE,
  ResourcesDeleteAction,
  ASSET_SAVE_START,
  ASSET_SAVE_DONE,
  ASSET_SAVE_FAILED,
  AssetSaveAction,
} from "../actionTypes";
import { ResourcesEditState } from "../state";

const initialState: ResourcesEditState = {
  editing: false,
  sending: false,
  errors: {},
  saveSucceeded: false,
};

export default function reducer(
  state: ResourcesEditState = initialState,
  action:
    | ResourcesOpen
    | ResourcesEdit
    | ResourcesSaveAction
    | ResourcesDeleteAction
    | AssetSaveAction
    | ResourcesAction
    | ResourcesSaveRelationAction
) {
  switch (action.type) {
    case RESOURCES_LOAD_START: {
      return { ...state, loading: true };
    }

    case RESOURCES_LOAD_DONE: {
      console.log("reducer:RESOURCES_LIST_LOAD_DONE");
      console.log(action.payload);
      console.log("reducer:RESOURCES_LIST_LOAD_DONE - END");
      return { ...state, loading: false, resources: action.payload };
    }

    case RESOURCES_OPEN: {
      const resources = action.payload;
      return { ...initialState, resources, editing: action.editing };
    }

    case RESOURCES_EDIT: {
      return { ...state, ...initialState, editing: action.payload };
    }

    case RESOURCES_SAVE_START: {
      return { ...state, sending: true, errors: {}, saveSucceeded: false };
    }

    case RESOURCES_SAVE_DONE: {
      return {
        ...state,
        editing: false,
        sending: false,
        errors: {},
        resources: action.payload,
        saveSucceeded: true,
      };
    }

    case RESOURCES_SAVE_RELATION_START: {
      return { ...state, sending: true, errors: {}, saveSucceeded: false };
    }

    case RESOURCES_SAVE_RELATION_DONE: {
      return {
        ...state,
        editing: true,
        sending: false,
        errors: {},
        resources: action.payload,
      };
    }

    case RESOURCES_SAVE_FAILED: {
      return {
        ...state,
        editing: true,
        sending: false,
        errors: action.payload,
        saveSucceeded: false,
      };
    }

    case RESOURCES_DELETE_START: {
      return { ...state, sending: true };
    }

    case RESOURCES_DELETE_DONE: {
      return { ...state, sending: false, resources: undefined };
    }

    case ASSET_SAVE_START: {
      return { ...state, sending: true };
    }

    case ASSET_SAVE_DONE:
    case ASSET_SAVE_FAILED: {
      return { ...state, sending: false };
    }

    default:
      return state;
  }
}
