import { AxiosRequestConfig } from "axios";

import HttpClientService, {
  HttpClientResponse,
} from "./HttpClientServiceLaravel";
import PaginatedResponse from "../models/PaginatedResponse";
import Category from "../models/Category";
import OptionResponse from "../models/OptionResponse";
import DashboardModel from "../models/Dashboard";

export default class DashboardService extends HttpClientService {
  async loadDashboard(): Promise<DashboardModel> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/dashboard",
    };

    const response: HttpClientResponse<DashboardModel> = await this.doRequest(
      request
    );
    console.log("DASHBOARD SERVICE");
    console.log(response);
    return response.data;
  }
}

export const service = new DashboardService();
