import React from "react";
import { Button, Table } from "react-bootstrap";
import join from "lodash/join";

import BookForm from "../../containers/books/BookForm";
import ChaptersList from "../../containers/chapters/ChaptersList";
import AudioBook from "../../models/AudioBook";

interface Props {
  audioBook?: AudioBook;
  editing: boolean;

  edit: () => void;
}

export default function BookDetails(props: Props) {
  if (!props.audioBook) {
    return <p className="font-italic">Select a book from the list</p>;
  }

  const { audioBook } = props;

  return (
    <>
      <div className="mt-4 mb-4">
        {!props.editing ? (
          <>
            <DetailsTable audioBook={audioBook} />
            <div className="text-right">
              <Button onClick={props.edit} size="sm" variant="secondary">
                Edit
              </Button>
            </div>
          </>
        ) : (
          <BookForm />
        )}
      </div>
      {audioBook.id && (
        <>
          <h4>Chapters</h4>
          <ChaptersList audioBookId={audioBook.id} />
        </>
      )}
    </>
  );
}

function DetailsTable({ audioBook }: { audioBook: AudioBook }) {
  const authors = join(
    audioBook.authors.map((a) => `${a.firstName} ${a.lastName}`),
    ", "
  );

  return (
    <Table size="sm">
      <tbody>
        <tr>
          <th>Product ID</th>
          <td>{audioBook.productId}</td>
        </tr>
        <tr>
          <th>Type</th>
          <td>{audioBook.kind}</td>
        </tr>
        <tr>
          <th>Title</th>
          <td>{audioBook.title}</td>
        </tr>
        <tr>
          <th>ISBN</th>
          <td>{audioBook.isbn}</td>
        </tr>
        <tr>
          <th>RSS feed link</th>
          <td>{audioBook.rssFeedLink}</td>
        </tr>
        <tr>
          <th>Authors</th>
          <td>{authors}</td>
        </tr>
        <tr>
          <th>Summary</th>
          <td>{audioBook.summary}</td>
        </tr>
        <tr>
          <th>Category</th>
          <td>{audioBook.category}</td>
        </tr>
        <tr>
          <th>Director</th>
          <td>{audioBook.director}</td>
        </tr>
        <tr>
          <th>Duration</th>
          <td>{audioBook.duration}</td>
        </tr>
        <tr>
          <th>Release</th>
          <td>{audioBook.release}</td>
        </tr>
        <tr>
          <th>Translator</th>
          <td>{audioBook.translator}</td>
        </tr>
        <tr>
          <th>Edition</th>
          <td>{audioBook.bookEdition}</td>
        </tr>
        <tr>
          <th>Publishing date</th>
          <td>{audioBook.publishingDate}</td>
        </tr>
      </tbody>
    </Table>
  );
}
