import React, { Component } from "react";
import Select from "react-select";
import { ButtonGroup, Button, Card, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";

import FormInputErrors, { hasErrors } from "../lib/FormInputErrors";
import DeleteButtonClickConfirm from "../lib/DeleteButtonClickConfirm";

import Category from "../../models/Category";
import { CategoryEditState } from "../../redux/state";
import OptionResponse from "../../models/OptionResponse";
import MultiselectOption from "../../models/MultiselectOption";

export interface DataProps {
  category: Category;
  categoryOption: OptionResponse[];
  sending: boolean;
  errors: Errors;
}

interface Props extends DataProps {
  save: (category: Category) => void;
  cancelEdit: () => void;
  deleteCategory: (id: string) => void;
  loadCategoryOption: () => void;
}

interface Errors {
  id?: string[];
  code?: string[];
  description?: string[];
  parent_category?: string[];
}

interface State {
  categoryEditing: Category;
}

export default class CategoryForm extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = { categoryEditing: cloneDeep(this.props.category) };
    this.save = this.save.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  componentDidMount() {
    console.log("componentDidMountForm");
    console.log("before load");
    this.props.loadCategoryOption();
    console.log("after load");
  }

  componentWillUnmount() {
    this.props.cancelEdit();
  }

  save() {
    if (this.state.categoryEditing.code == "") {
      alert("Devi inserire una codice");
      return;
    }
    if (this.state.categoryEditing.description == "") {
      alert("Devi inserire una descrizione");
      return;
    }
    this.props.save(this.state.categoryEditing);
  }

  setValue(
    attribute: string,
    { target: { value } }: { target: { value: string } }
  ) {
    const categoryEditing = cloneDeep(this.state.categoryEditing);

    categoryEditing[attribute] = value;
    this.setState({ categoryEditing });
  }

  setStringValue(attribute: string, options: string | undefined) {
    const categoryEditing = cloneDeep(this.state.categoryEditing);

    categoryEditing[attribute] = options;
    this.setState({ categoryEditing });
  }

  render() {
    let categoryOptions: MultiselectOption[] = [];
    let statusOptionSelected = null;
    {
      this.props.categoryOption.map((el, i) => {
        if (this.state.categoryEditing.parent_category == el.value) {
          statusOptionSelected = el;
        }
      });
    }

    const {
      sending,
      errors,
      category: { id },
      deleteCategory,
    } = this.props;
    const { categoryEditing } = this.state;

    const isNewRecord = !id;
    console.log(categoryEditing);
    return (
      <Form>
        <Form.Row className="mt-4 ">
          <Col xs md lg="3">
            <Form.Group>
              <label className="text-dark-75 font-weight-bolder">Codice</label>
            </Form.Group>
          </Col>
          <Col xs md lg="9">
            <Form.Group>
              <Form.Control
                className="custom-input"
                placeholder="Inserisci un codice"
                isInvalid={hasErrors(errors, "code")}
                disabled={sending}
                type="text"
                value={categoryEditing.code || ""}
                onChange={(event) => this.setValue("code", event)}
              />
              <FormInputErrors
                elementId={`category-${id}-code-field`}
                errors={errors.code}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row className="mt-4 ">
          <Col xs md lg="3">
            <Form.Group>
              <label className="text-dark-75 font-weight-bolder">
                Descrizione
              </label>
            </Form.Group>
          </Col>
          <Col xs md lg="9">
            <Form.Control
              required
              as="textarea"
              rows={5}
              placeholder="Inserisci una descrizione"
              className="custom-input"
              isInvalid={hasErrors(errors, "description")}
              disabled={sending}
              type="text"
              value={categoryEditing.description || ""}
              onChange={(event) => this.setValue("description", event)}
            />
            <FormInputErrors
              elementId={`category-${id}-description-field`}
              errors={errors.description}
            />
          </Col>
        </Form.Row>
        <Form.Row className="mt-4 ">
          <Col xs md lg="3">
            <Form.Group>
              <label className="text-dark-75 font-weight-bolder">
                Categoria
              </label>
            </Form.Group>
          </Col>
          <Col lg="9">
            <Select
              isClearable
              className="custom-input"
              options={this.props.categoryOption}
              placeholder="Seleziona un codice padre"
              value={statusOptionSelected}
              onChange={(selectedOption) => {
                console.log(selectedOption);
                this.setStringValue("parent_category", selectedOption?.value);
              }}
            />
            {/*<Form.Control as="select"
              isInvalid={hasErrors(errors, 'parent_category')}
              disabled={sending}
              className="custom-input"
              onChange={event => this.setValue('parent_category', event)}>
              <option key='blankChoice' hidden>Seleziona un codice padre</option>

              {this.props.categoryOption.map((el, i) => {
                console.log(el);
                // Return the element. Also pass key   
                if(categoryEditing.parent_category == el.key){
                  return (<option selected value = {el.key}>{el.value}</option>)
                }  
                return (<option value = {el.key}>{el.value}</option>)
              })}

            </Form.Control> */}
          </Col>
        </Form.Row>
        <div className="separator separator-solid my-7"></div>
        <Form.Row className="mt-4 justify-content-between">
          {/*!isNewRecord ?
              <DeleteButtonClickConfirm
                label="Delete category" labelConfirm="Click to confirm" size="sm"
                action={() => deleteCategory(id)}
                disabled={sending} />
              :
              <div />*/}
          <div />

          <div>
            <Button
              onClick={this.props.cancelEdit}
              disabled={sending}
              size="sm"
              variant="link"
              className="marginButton"
            >
              Annulla
            </Button>
            <Button
              onClick={this.save}
              disabled={sending}
              size="sm"
              variant="primary"
            >
              Salva modifiche
            </Button>
          </div>
        </Form.Row>
      </Form>
    );
  }
}
