import React, { useState } from "react";
import {
  Table,
  Button,
  Pagination,
  Form,
  Col,
  InputGroup,
  Row,
  CloseButton,
} from "react-bootstrap";
import Loader from "../Loader";
import PaginatedResponse from "../../models/PaginatedResponse";
import Container from "../../models/Container";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Typography } from "@material-ui/core";
import PaginationComponent from "../lib/PaginationComponent";
import DeleteButtonClickListModal from "../lib/DeleteButtonClickListModal";

export interface SearchParams {
  page?: number;
  search?: string;
}

interface Props {
  loading: boolean;
  modal: boolean;
  list: PaginatedResponse<Container>;
  listSelected?: string[];
  searchFunction: (params: SearchParams) => void;
  searchContainer: (params: SearchParams) => void;
  openContainer: (id: string) => void;
  newContainer: () => void;
  checkboxChange?: (event: any, container: Container) => void;
  deleteContainer: (id: string) => void;
  closeModal?: () => void;
}

export default function ContainerList(props: Props) {
  if (props.loading) {
    return <Loader rows={10} />;
  }

  const newContainerButton = (
    <div className="text-right">
      <CloseButton hidden={!props.modal} onClick={props.closeModal} />
      <Button
        size="sm"
        hidden={props.modal}
        className="btn-custom-secondary"
        variant=""
        onClick={props.newContainer}
      >
        Nuovo container
      </Button>
    </div>
  );

  const list: PaginatedResponse<Container> = props.list;
  console.log("table component");
  console.log(props);
  console.log("table component");
  const tableEntries = props.list.entries.map((b) => {
    const open = (event: any) => {
      event.preventDefault();
      props.openContainer(b.id);
    };
    let selected: boolean = false;
    if (props.listSelected?.find((f) => f == b.id) != undefined) {
      selected = true;
    }
    return (
      <tr key={`container-entry-${b.id}`}>
        <td className="pl-6 py-6 td-checkbox" hidden={!props.modal}>
          <label className="checkbox checkbox-lg checkbox-inline">
            <input
              type="checkbox"
              defaultChecked={selected}
              onChange={(event) => props.checkboxChange?.(event, b)}
            />
            <span></span>
          </label>
        </td>
        <td className="pl-0 py-4 td-image-resource">
          <div>
            <img src={b.img_cover} className="image-icon" alt="" />
          </div>
        </td>

        <td className="td-title-resource">
          <div className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {b.title}
          </div>
          <div className="text-dark-50 ">{b.subtitle}</div>
        </td>
        {/*<td hidden={props.modal} className='td-id-resource' style={{ color: "#B5B5C3" }}><div>{b.id}</div></td>*/}
        <td className="td-type-resource" style={{ color: "#B5B5C3" }}>
          <div>{b.type}</div>
        </td>
        <td className="td-isbn-resource" style={{ color: "#B5B5C3" }}>
          {" "}
          <div>{b.isbn}</div>
        </td>
        <td className="td-date-publish-resource" style={{ color: "#B5B5C3" }}>
          <div>
            {b.date_publish != null
              ? new Date(b.date_publish).toLocaleDateString("it-IT")
              : b.date_publish}
          </div>
        </td>
        <td
          className="td-date-publish-resource"
          style={{ color: "#B5B5C3", textAlign: "center" }}
        >
          <div>NO</div>
        </td>
        <td
          hidden={props.modal}
          className="button-td"
          style={{ textAlign: "right" }}
        >
          <a
            onClick={open}
            href="#"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </a>
          <DeleteButtonClickListModal
            deleteAction={props.deleteContainer}
            idDelete={b.id}
          />
        </td>
      </tr>
    );
  });

  return (
    <>
      <Row className="title-row">
        <Col xs={7}>
          <Typography variant="h5" component="h3">
            <b>Gestione container</b>
          </Typography>
        </Col>
        <Col xs={3}>
          <SearchForm {...props} />
        </Col>
        <Col xs={2}>{newContainerButton}</Col>
      </Row>

      {0 === list.entries.length ? (
        <p className="font-italic">No container matches your search criteria</p>
      ) : (
        <Table hover responsive size="sm" className="people-list-table">
          <thead style={{ textAlign: "center" }} className="MuiPaper-rounded">
            <tr>
              <th hidden={!props.modal}></th>
              <th
                style={{
                  border: "none",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                }}
              ></th>

              <th style={{ textAlign: "left" }}>NOME CONTAINER</th>

              <th style={{ color: "#B5B5C3" }}>TIPO CONTAINER</th>
              <th style={{ color: "#B5B5C3" }}>ISBN</th>
              <th style={{ color: "#B5B5C3" }}>DATA PUBBLICAZIONE</th>
              <th style={{ color: "#B5B5C3" }}>COMPLETATO</th>
              <th></th>
              <th
                style={{
                  border: "none",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                }}
              ></th>
            </tr>
          </thead>
          <br />
          <tbody>{tableEntries}</tbody>
        </Table>
      )}
      <PaginationComponent {...props} />
    </>
  );
}

function SearchForm(props: Props) {
  const [search, setSearch] = useState(props.list.search || "");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    props.searchContainer({ search });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col xs={12}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <span className="oi oi-magnifying-glass"></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              placeholder="Cerca container"
              onChange={({ target: { value } }) => setSearch(value)}
              value={search}
            />
          </InputGroup>
        </Col>
      </Form.Row>
    </Form>
  );
}
