import React, { useState } from "react";
import {
  Table,
  Button,
  Pagination,
  Form,
  Col,
  InputGroup,
} from "react-bootstrap";

import Loader from "../Loader";

import PaginatedResponse from "../../models/PaginatedResponse";
import AudioBook from "../../models/AudioBook";

export interface SearchParams {
  page?: number;
  search?: string;
}

interface Props {
  loading: boolean;
  list: PaginatedResponse<AudioBook>;

  searchBooks: (params: SearchParams) => void;
  openAudioBook: (id: string) => void;
  newAudioBook: () => void;
}

export default function BooksList(props: Props) {
  if (props.loading) {
    return <Loader rows={10} />;
  }

  const newBookButton = (
    <div className="text-right">
      <Button size="sm" onClick={props.newAudioBook}>
        Add new book
      </Button>
    </div>
  );

  const list: PaginatedResponse<AudioBook> = props.list;

  const tableEntries = props.list.entries.map((b) => {
    const open = (event: any) => {
      event.preventDefault();
      props.openAudioBook(b.id);
    };

    return (
      <tr key={`audio-book-entry-${b.id}`}>
        <td>
          <a href="/" onClick={open}>
            {b.productId}
          </a>
        </td>
        <td>{b.title}</td>
        <td>{b.kind}</td>
        <td>{b.publishingDate}</td>
      </tr>
    );
  });

  return (
    <>
      <div className="mt-4 mb-4">
        <SearchForm {...props} />
      </div>

      {0 === list.entries.length ? (
        <p className="font-italic">
          No audio book matches your search criteria
        </p>
      ) : (
        <Table
          striped
          bordered
          hover
          responsive
          size="sm"
          className="books-list-table"
        >
          <thead>
            <tr>
              <th>Product ID</th>
              <th>Title</th>
              <th>Type</th>
              <th>Publishing date</th>
            </tr>
          </thead>
          <tbody>{tableEntries}</tbody>
        </Table>
      )}
      <Pages {...props} />
      {newBookButton}
    </>
  );
}

function Pages(props: Props) {
  const {
    list: { totalPages, currentPage, search },
    searchBooks,
  } = props;
  if (1 === totalPages) {
    return null;
  }

  const links = [];

  const nextPage = () => searchBooks({ search, page: currentPage + 1 });
  const prevPage = () => searchBooks({ search, page: currentPage - 1 });

  if (1 === currentPage) {
    links.push(
      <Pagination.Item key="pagination.item" disabled>
        1
      </Pagination.Item>
    );
    links.push(<Pagination.Next key="pagination.next" onClick={nextPage} />);
  } else if (totalPages === currentPage) {
    links.push(<Pagination.Prev key="pagination.prev" onClick={prevPage} />);
    links.push(
      <Pagination.Item key="pagination.item" disabled>
        {currentPage}
      </Pagination.Item>
    );
  } else {
    links.push(<Pagination.Prev key="pagination.prev" onClick={prevPage} />);
    links.push(
      <Pagination.Item key="pagination.item" disabled>
        {currentPage}
      </Pagination.Item>
    );
    links.push(<Pagination.Next key="pagination.next" onClick={nextPage} />);
  }

  return <Pagination size="lg">{links}</Pagination>;
}

function SearchForm(props: Props) {
  const [search, setSearch] = useState(props.list.search || "");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    props.searchBooks({ search });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col xs={4}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <span className="oi oi-magnifying-glass"></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={({ target: { value } }) => setSearch(value)}
              value={search}
            />
          </InputGroup>
        </Col>
      </Form.Row>
    </Form>
  );
}
