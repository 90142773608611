import React from "react";
import { Form } from "react-bootstrap";

interface Props {
  elementId: string;
  errors?: string[];
}

export default function FormInputErrors(props: Props) {
  if ("undefined" === typeof props.errors || 0 === props.errors.length) {
    return null;
  }

  return (
    <>
      {props.errors.map((errMsg, index) => {
        return (
          <Form.Control.Feedback
            key={`${props.elementId}-errFeedback-${index}`}
            type="invalid"
          >
            {errMsg}
          </Form.Control.Feedback>
        );
      })}
    </>
  );
}

export function hasErrors(errors: any, property: string): boolean {
  return errors.hasOwnProperty(property) && errors[property].length > 0;
}
