import React from "react";
import { Button } from "react-bootstrap";

import ChapterForm from "../../containers/chapters/ChapterForm";
import Chapter from "../../models/Chapter";
import AssetsList from "../../containers/chapters/AssetsList";

interface Props {
  chapter?: Chapter;
  editing: boolean;

  edit: () => void;
}

export default function ChapterDetails(props: Props) {
  if (!props.chapter) {
    return <p className="font-italic">Select a chapter from the list</p>;
  }

  const { chapter } = props;

  return (
    <>
      <div className="mb-4">
        {!props.editing ? (
          <div>
            <p>
              <strong>Title:</strong> {chapter.title}
            </p>
            <p>
              <strong>ID:</strong> {chapter.id}
            </p>
            <div className="text-right">
              <Button onClick={props.edit} size="sm" variant="secondary">
                Edit
              </Button>
            </div>
          </div>
        ) : (
          <ChapterForm />
        )}
      </div>
      {chapter.id && (
        <>
          <h4>Audio tracks</h4>
          <AssetsList chapterId={chapter.id} assets={chapter.assets} />
        </>
      )}
    </>
  );
}
