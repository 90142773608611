import {
  PEOPLE_LIST_OPTION_LOAD_START,
  PEOPLE_LIST_OPTION_LOAD_DONE,
  PeopleListOptionAction,

  PEOPLE_LIST_LOAD_START,
  PEOPLE_LIST_LOAD_DONE,
  PeopleListAction,

  PEOPLE_OPEN,
  PeopleOpen,

  PEOPLE_EDIT,
  PeopleEdit,

  PEOPLE_SAVE_START,
  PEOPLE_SAVE_DONE,
  PEOPLE_SAVE_FAILED,
  PeopleSaveAction,

  PEOPLE_DELETE_START,
  PEOPLE_DELETE_DONE,
  PeopleDeleteAction
} from '../actionTypes'
import { service as peopleService, SearchParams } from '../../services/PeopleService'
import PaginatedResponse from '../../models/PaginatedResponse'
import People, { build as buildPeople } from '../../models/People'
import OptionResponse from '../../models/OptionResponse'

export function loadPeopleList(params?: SearchParams): (dispatch: (action: PeopleListAction) => void) => void {
  console.log("action:loadPeopleList")
  return async dispatch => {
    dispatch({ type: PEOPLE_LIST_LOAD_START })

    const list: PaginatedResponse<People> = await peopleService.search(params || {})
    console.log(list)
    dispatch({ type: PEOPLE_LIST_LOAD_DONE, payload: list })
  }
}

export function loadPeopleOption(params?: SearchParams): (dispatch: (action: PeopleListOptionAction) => void) => void {
  console.log("action:loadCategoryOption")
  return async dispatch => {
    dispatch({ type: PEOPLE_LIST_OPTION_LOAD_START })

    const list: OptionResponse[] = await peopleService.getOptionList(params || {})
    console.log(list)
    dispatch({ type: PEOPLE_LIST_OPTION_LOAD_DONE, payload: list })
  }
}

export function openPeople(payload: People): (dispatch: (action: PeopleOpen) => void) => void {
  return async dispatch => {
    dispatch({ type: PEOPLE_OPEN, payload, editing:true})
  }
}

export function newPeople(): (dispatch: (action: PeopleOpen | PeopleEdit) => void) => void {
  return async dispatch => {
    const newPeople: People = buildPeople()
    dispatch({ type: PEOPLE_OPEN, payload: newPeople, editing:true })
    dispatch({ type: PEOPLE_EDIT, payload: true })
  }
}

export function edit(): (dispatch: (action: PeopleEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: PEOPLE_EDIT, payload: true})
  }
}

export function cancelEdit(): (dispatch: (action: PeopleEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: PEOPLE_EDIT, payload: false })
  }
}

export function save(people: People): (dispatch: (action: PeopleSaveAction) => void) => void {
  return async dispatch => {
    dispatch({ type: PEOPLE_SAVE_START })
    try {
      const updatedModel: People = await peopleService.save(people)
      dispatch({ type: PEOPLE_SAVE_DONE, payload: updatedModel })
    } catch (err) {
      //dispatch({ type: PEOPLE_SAVE_FAILED, payload: err.data })
    }
  }
}

export function deletePeople(id: string): (dispatch: (action: PeopleDeleteAction) => void) => void {
  return async dispatch => {
    dispatch({ type: PEOPLE_DELETE_START, payload: { id } })
    try {
      await peopleService.deleteModel(id)
      dispatch({ type: PEOPLE_DELETE_DONE, payload: { id } })
    } catch (err) {}
  }
}
