import {
  CHAPTERS_LOAD_START,
  CHAPTERS_LOAD_DONE,
  ChaptersAction,

  CHAPTER_OPEN,
  ChapterOpen,

  CHAPTER_EDIT,
  ChapterEdit,

  CHAPTER_SAVE_START,
  CHAPTER_SAVE_DONE,
  CHAPTER_SAVE_FAILED,
  ChapterSaveAction,

  CHAPTER_DELETE_START,
  CHAPTER_DELETE_DONE,
  ChapterDeleteAction
} from '../actionTypes'

import { service as chapterService } from '../../services/ChapterService'
import Chapter, { build as buildChapter } from '../../models/Chapter'

export function loadChaptersList(audioBookId: string): (dispatch: (action: ChaptersAction) => void) => void {
  return async dispatch => {
    dispatch({ type: CHAPTERS_LOAD_START })

    const list: Chapter[] = await chapterService.list(audioBookId)
    dispatch({ type: CHAPTERS_LOAD_DONE, payload: list })
  }
}

export function openChapter(payload: Chapter): (dispatch: (action: ChapterOpen) => void) => void {
  return async dispatch => {
    dispatch({ type: CHAPTER_OPEN, payload })
  }
}

export function newChapter(audioBookId: string): (dispatch: (action: ChapterOpen | ChapterEdit) => void) => void {
  return async dispatch => {
    const model: Chapter = buildChapter(audioBookId)
    dispatch({ type: CHAPTER_OPEN, payload: model })
    dispatch({ type: CHAPTER_EDIT, payload: true })
  }
}

export function edit(): (dispatch: (action: ChapterEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: CHAPTER_EDIT, payload: true })
  }
}

export function cancelEdit(): (dispatch: (action: ChapterEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: CHAPTER_EDIT, payload: false })
  }
}

export function save(chapter: Chapter): (dispatch: (action: ChapterSaveAction) => void) => void {
  return async dispatch => {
    dispatch({ type: CHAPTER_SAVE_START })
    try {
      const updatedModel: Chapter = await chapterService.save(chapter)
      dispatch({ type: CHAPTER_SAVE_DONE, payload: updatedModel })
    } catch (err) {
      //dispatch({ type: CHAPTER_SAVE_FAILED, payload: err.data })
    }
  }
}

export function deleteChapter(model: Chapter): (dispatch: (action: ChapterDeleteAction | ChaptersAction) => void) => void {
  return async dispatch => {
    dispatch({ type: CHAPTER_DELETE_START, payload: { id: model.id } })
    try {
      await chapterService.deleteModel(model.id)

      const list: Chapter[] = await chapterService.list(model.audioBookId)
      dispatch({ type: CHAPTERS_LOAD_DONE, payload: list })

      dispatch({ type: CHAPTER_DELETE_DONE, payload: { id: model.id } })
    } catch (err) {}
  }
}

export function moveChapter(chapter: Chapter, offset: number): (dispatch: (action: ChaptersAction | ChapterSaveAction) => void) => void {
  return async dispatch => {
    dispatch({ type: CHAPTER_SAVE_START })

    const updateData: Chapter = { ...chapter, position: chapter.position + offset }
    const updatedModel: Chapter = await chapterService.save(updateData)

    const list: Chapter[] = await chapterService.list(chapter.audioBookId)
    dispatch({ type: CHAPTERS_LOAD_DONE, payload: list })

    dispatch({ type: CHAPTER_SAVE_DONE, payload: updatedModel })
  }
}
