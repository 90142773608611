import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import ResourcesList from "../containers/resources/ResourcesList";
import ResourcesDetails from "../containers/resources/ResourcesDetails";
import ResourcesDetailsLight from "../containers/resources/ResourcesDetailsLight";

import CurrentUser from "../models/CurrentUser";

interface Props {
  currentUser: CurrentUser;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(3, 2),
    borderRadius: 12,
  },
}));

export default function ResourcesBackoffice(props: Props) {
  const classes = useStyles();
  let editing = useSelector(
    (state: RootStateOrAny) => state.resourcesEdit.editing
  );

  return (
    <div>
      {!editing ? (
        <Paper className={classes.root}>
          <Typography component="div">
            <ResourcesList />
          </Typography>
        </Paper>
      ) : (
        <div>
          <ResourcesDetails />
        </div>
      )}
    </div>
  );
}
