import { TrackPreviewState } from "../state";
import PaginatedResponse, { empty } from "../../models/PaginatedResponse";
import {
  TRACKPREVIEW_LIST_LOAD_START,
  TRACKPREVIEW_LIST_LOAD_DONE,
  TrackPreviewListAction,
  TRACK_PREVIEW_SAVE_DONE,
  TrackPreviewSaveAction,
} from "../actionTypes";

const initialState: TrackPreviewState = { loading: false, list: empty };

export default function reducer(
  state: TrackPreviewState = initialState,
  action: TrackPreviewListAction | TrackPreviewSaveAction
) {
  switch (action.type) {
    case TRACKPREVIEW_LIST_LOAD_START: {
      return { ...state, loading: true };
    }

    case TRACKPREVIEW_LIST_LOAD_DONE: {
      console.log("reducer:TRACKPREVIEW_LIST_LOAD_DON");
      console.log(action.payload);
      console.log("reducer:TRACKPREVIEW_LIST_LOAD_DON - END");
      console.error(action.payload);
      return { ...state, loading: false, list: action.payload };
    }

    case TRACK_PREVIEW_SAVE_DONE: {
      return { ...state };
    }

    default:
      return state;
  }
}
