import { AxiosRequestConfig } from "axios";

import HttpClientService, {
  HttpClientResponse,
} from "./HttpClientServiceLaravel";
import PaginatedResponse from "../models/PaginatedResponse";
import Container from "../models/Container";
import OptionResponse from "../models/OptionResponse";
import ContainerHasContent from "../models/ContainerHasContent";

export interface SearchParams {
  page?: number;
  search?: string;
}

export default class ContainerService extends HttpClientService {
  async search(params: SearchParams): Promise<PaginatedResponse<Container>> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/v1/container",
      params,
    };

    const response: HttpClientResponse<PaginatedResponse<
      Container
    >> = await this.doRequest(request);
    console.log(response);
    return response.data;
  }

  async saveContentRelated(
    container_id: string,
    model: ContainerHasContent[]
  ): Promise<Container> {
    let request: AxiosRequestConfig;
    const data: any = { ...model };

    request = {
      method: "PATCH",
      url: `/api/v1/container/relatedcontent/${container_id}`,
      data: { data },
    };

    const response: HttpClientResponse<Container> = await this.doRequest(
      request
    );
    return response.data;
  }

  async getOptionList(params: SearchParams): Promise<OptionResponse[]> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/v1/containeroption",
      params,
    };

    const response: HttpClientResponse<OptionResponse[]> = await this.doRequest(
      request
    );
    console.log(response);
    return response.data;
  }

  async save(model: Container): Promise<Container> {
    let request: AxiosRequestConfig;
    const data: any = { ...model, id: undefined };
    if (data.authors) {
      data.overrideAuthors = data.authors.map((a: any) => a.id);
      delete data.authors;
    }

    if (model.id) {
      request = {
        method: "PATCH",
        url: `/api/v1/container/${model.id}`,
        data: { container: data },
      };
    } else {
      request = {
        method: "POST",
        url: "/api/v1/container",
        data: { container: data },
      };
    }

    const response: HttpClientResponse<Container> = await this.doRequest(
      request
    );
    return response.data;
  }

  async deleteModel(id: string): Promise<boolean> {
    const request: AxiosRequestConfig = {
      method: "DELETE",
      url: `/api/v1/container/${id}`,
    };

    await this.doRequest(request);
    return true;
  }
}

export const service = new ContainerService();
