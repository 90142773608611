import React, { Component, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

interface Props {
  deleteAction: (id: string) => void;
  idDelete: string;
}

interface State {
  activated: boolean;
}

function DeleteModal(props: {
  deleteAction: (id: string) => void;
  idDelete: string;
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <a
        href="#"
        onClick={handleShow}
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="modal-delete-body">
          <div className="modal-delete-body-div">
            <label className="modal-delete-title">
              Vuoi Davvero eliminare questo elemento?
            </label>
            <br />
            <label className="modal-delete-subtitle">
              L'azione è irreversibile
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-delete-footer">
          <Button variant="secondary" onClick={handleClose}>
            Indietro
          </Button>
          <Button
            variant="primary"
            onClick={() => props.deleteAction(props.idDelete)}
          >
            Elimina
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default class DeleteButtonClickListModal extends Component<
  Props,
  State
> {
  timeoutId?: number;

  constructor(props: Props, state: State) {
    super(props, state);

    this.state = { activated: false };

    this.setModal = this.setModal.bind(this);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
  }

  setModal() {
    this.setState({ activated: true });
  }

  render() {
    const { deleteAction } = this.props;
    const { activated } = this.state;
    return (
      <DeleteModal
        deleteAction={this.props.deleteAction}
        idDelete={this.props.idDelete}
      />
    );
  }
}
