import React, { useState } from "react";
import Select from "react-select";
import {
  Table,
  Button,
  Pagination,
  Form,
  Col,
  InputGroup,
  Row,
  FormLabel,
  Modal,
} from "react-bootstrap";
import Loader from "../Loader";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import PaginatedResponse from "../../models/PaginatedResponse";
import Content from "../../models/Content";
import { Typography } from "@material-ui/core";
import PaginationComponent from "../lib/PaginationComponent";
import OptionResponse from "../../models/OptionResponse";
import Asset from "../../models/Asset";
import FileUploader, { FileUploaderExcel } from "../lib/FileUploader";
import DeleteButtonClickListModal from "../lib/DeleteButtonClickListModal";
import Upload from "rc-upload";
import { CONTENT_LIST_OPTION_LOAD_DONE } from "../../redux/actionTypes";
import ImportResponse from "../../models/ImportResponse";
import ModalInfo from "../lib/ModalInfo";

export interface SearchParams {
  page?: number;
  search?: string;
  parent_id?: string;
}

interface Props {
  loading: boolean;
  importResponse?: ImportResponse;
  list: PaginatedResponse<Content>;
  listOption: OptionResponse[];
  modal: boolean;
  listSelected?: string[];
  upload: (file: File) => void;
  download: () => void;
  uploading: boolean;
  sending: boolean;
  //searchByContainer: (id:string) => void,
  searchFunction: (params: SearchParams) => void;
  searchContent: (params: SearchParams) => void;
  openContent: (id: string) => void;
  newContent: (audio_book_id: string, label: string) => void;
  loadContainerOption: () => void;
  checkboxChange?: (event: any, content: Content) => void;
  deleteContent: (id: string) => void;
  resetUpload: () => void;
}

export default function ContentList(props: Props) {
  if (props.loading) {
    return <Loader rows={10} />;
  }

  console.log("IMPORT RESPONSE");
  console.log(props.importResponse);

  function newContentCheck() {
    if (props.list.parent_id == undefined) {
      alert("Scegliere un container");
    } else {
      let parentSelected = null;
      if (
        props.listOption.find((f) => f.value == props.list.parent_id) !=
        undefined
      ) {
        parentSelected = props.listOption.find(
          (f) => f.value == props.list.parent_id
        );
      }
      props.newContent(parentSelected?.value!, parentSelected?.label!);
    }
  }

  function exportExcel() {
    props.download();
    console.log("EXPORT");
  }

  const newContentButton = (
    <Button
      size="sm"
      className="btn-custom-secondary col-12"
      variant=""
      onClick={newContentCheck}
      hidden={props.modal}
    >
      Nuovo capitolo
    </Button>
  );
  const importExcel = (
    <FileUploaderExcel upload={props.upload}>
      <Button
        disabled={props.sending}
        className="btn-custom-action col-12"
        variant=""
        size="sm"
      >
        Importa in .xlsx <span className="oi oi-share-boxed mx-2"></span>
      </Button>
    </FileUploaderExcel>
  );
  const ExportExcel = (
    <Button
      size="sm"
      className="btn-custom-action col-12"
      variant=""
      onClick={exportExcel}
      hidden={props.modal}
    >
      Esporta in .xlsx<span className="oi oi-share-boxed mx-2"></span>
    </Button>
  );

  const list: PaginatedResponse<Content> = props.list;

  console.log("table component");
  console.log(props);
  console.log("table component");

  const tableEntries = props.list.entries.map((b) => {
    const open = (event: any) => {
      event.preventDefault();
      props.openContent(b.id);
    };
    let selected: boolean = false;
    console.log(props.listSelected);
    if (props.listSelected?.find((f) => f == b.id) != undefined) {
      selected = true;
    }
    console.log("CONTENT LIST LIST");
    console.log(b);
    return (
      <>
        <tr key={`content-entry-${b.id}`}>
          {/*
        <td className="pl-6 py-6 td-checkbox" hidden={!props.modal}>
          <label className="checkbox checkbox-lg checkbox-inline">
            <input type="checkbox" defaultChecked={selected} onChange={event => props.checkboxChange?.(event, b)} />
            <span></span>
          </label>
        </td>
    */}
          {/*<td className='pl-0 py-4 td-image-content'>
          <div>
            <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Media.svg")} />
          </div>
        </td>*/}
          <td className="pl-6 py-6 td-checkbox" hidden={!props.modal}>
            <label className="checkbox checkbox-lg checkbox-inline">
              <input
                type="checkbox"
                defaultChecked={selected}
                onChange={(event) => props.checkboxChange?.(event, b)}
              />
              <span></span>
            </label>
          </td>
          <td className="pl-0 py-4 td-image-resource">
            <div>
              <img src={b.track_cover} className="image-icon" alt="" />
            </div>
          </td>

          <td className="td-title-content">
            <div className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
              {b.title} - {b.audio_book != undefined ? b.audio_book?.title : ""}
            </div>
            <div className="text-dark-50 ">
              {b.person != undefined
                ? b.person?.first_name + " " + b.person?.last_name
                : ""}
            </div>
          </td>
          <td className="td-track-id-content" style={{ color: "#B5B5C3" }}>
            <div>{b.track_id}</div>
          </td>
          <td
            className="td-isbn-content"
            style={{ color: "#B5B5C3", width: "20%" }}
          >
            <div>{b.isbn}</div>
          </td>
          <td
            className="td-isbn-resource"
            style={{ color: "#B5B5C3", textAlign: "center" }}
          >
            {" "}
            <div>{b.streaming_completed ? "Sì" : "No"}</div>
          </td>
          <td
            className="button-td"
            hidden={props.modal}
            style={{ textAlign: "right" }}
          >
            <a
              onClick={open}
              href="#"
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>
            <DeleteButtonClickListModal
              deleteAction={props.deleteContent}
              idDelete={b.id}
            />
          </td>
        </tr>
      </>
    );
  });

  return (
    <>
      {props.importResponse != undefined ? (
        <ModalInfo
          callbackClose={props.resetUpload}
          message={[
            "CONTAINER INSERITI: " +
              props.importResponse.json_message.audiobook.inserted!,
            "CONTAINER AGGIORNATI: " +
              props.importResponse.json_message.audiobook.updated!,
            "CAPITOLI IMPORTATI: " +
              props.importResponse.json_message.chapters.inserted!,
            "CAPITOLI AGGIORNATI: " +
              props.importResponse.json_message.chapters.updated!,
          ]}
          show={true}
        />
      ) : null}
      <Row className="title-row">
        <Col xs={3}>
          <Typography variant="h5" component="h3">
            <b>Gestione capitoli</b>
          </Typography>
        </Col>
        <Col xs={3}>
          <SelectSearchForm {...props} />
          {/*<SearchForm {...props} />*/}
        </Col>
        <Col xs={2}>{importExcel}</Col>
        <Col xs={2}>{ExportExcel}</Col>
        <Col xs={2}>{newContentButton}</Col>
      </Row>
      {0 === list.entries.length ? (
        <p className="font-italic">No content matches your search criteria</p>
      ) : (
        <Table hover responsive size="sm" className="people-list-table">
          <thead className="MuiPaper-rounded" style={{ textAlign: "center" }}>
            <tr>
              <th hidden={!props.modal}></th>
              <th
                style={{
                  border: "none",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                }}
              ></th>
              <th style={{ textAlign: "left" }}>NOME CAPITOLO</th>
              <th style={{ color: "#B5B5C3" }}>TRACK ID</th>
              <th style={{ color: "#B5B5C3" }}>ISBN</th>
              <th style={{ color: "#B5B5C3" }}>COMPLETATO</th>
              <th hidden={props.modal}></th>
              <th
                style={{
                  border: "none",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                }}
              ></th>
            </tr>
          </thead>
          <br />
          <tbody>{tableEntries}</tbody>
        </Table>
      )}
      <PaginationComponent {...props} />
    </>
  );
}

function SelectSearchForm(props: Props) {
  const [parent_id, setParentId] = useState(props.list.parent_id || "");
  console.log("PARENT");
  console.log(props);
  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    props.searchContent({ parent_id });
  };

  let parentSelected = null;
  if (
    parent_id != null &&
    props.listOption.find((f) => f.value == parent_id) != undefined
  ) {
    parentSelected = props.listOption.find((f) => f.value == parent_id);
  }

  return (
    <Select
      className="custom-search-select"
      options={props.listOption}
      value={parentSelected}
      placeholder="Seleziona un container"
      onChange={(selectedOption) => {
        console.log(selectedOption);
        if (selectedOption != undefined) {
          setParentId(selectedOption.value);
          props.searchContent({ parent_id: selectedOption.value });
        }

        //selectedOption.map(o => {
        //    selArray.push(o.value);
        //  }
        //);
        //this.setValueMultiselect("categories",selArray)
      }}
    />
  );
}

function SearchForm(props: Props) {
  const [search, setSearch] = useState(props.list.search || "");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    props.searchContent({ search });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col xs={12}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <span className="oi oi-magnifying-glass"></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={({ target: { value } }) => setSearch(value)}
              value={search}
            />
          </InputGroup>
        </Col>
      </Form.Row>
    </Form>
  );
}
