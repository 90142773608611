import {
  PUBLISHER_OPEN,
  PublisherOpen,
  PUBLISHER_EDIT,
  PublisherEdit,
  PUBLISHER_SAVE_START,
  PUBLISHER_SAVE_DONE,
  PUBLISHER_SAVE_FAILED,
  PublisherSaveAction,
  PUBLISHER_DELETE_START,
  PUBLISHER_DELETE_DONE,
  PublisherDeleteAction,
  ASSET_SAVE_START,
  ASSET_SAVE_DONE,
  ASSET_SAVE_FAILED,
  AssetSaveAction,
} from "../actionTypes";
import { PublisherEditState } from "../state";

const initialState: PublisherEditState = {
  editing: false,
  sending: false,
  errors: {},
  saveSucceeded: false,
};

export default function reducer(
  state: PublisherEditState = initialState,
  action:
    | PublisherOpen
    | PublisherEdit
    | PublisherSaveAction
    | PublisherDeleteAction
    | AssetSaveAction
) {
  switch (action.type) {
    case PUBLISHER_OPEN: {
      const publisher = action.payload;
      return { ...initialState, publisher, editing: action.editing };
    }

    case PUBLISHER_EDIT: {
      return { ...state, ...initialState, editing: action.payload };
    }

    case PUBLISHER_SAVE_START: {
      return { ...state, sending: true, errors: {}, saveSucceeded: false };
    }

    case PUBLISHER_SAVE_DONE: {
      return {
        ...state,
        editing: false,
        sending: false,
        errors: {},
        publisher: action.payload,
        saveSucceeded: true,
      };
    }

    case PUBLISHER_SAVE_FAILED: {
      return {
        ...state,
        editing: true,
        sending: false,
        errors: action.payload,
        saveSucceeded: false,
      };
    }

    case PUBLISHER_DELETE_START: {
      return { ...state, sending: true };
    }

    case PUBLISHER_DELETE_DONE: {
      return { ...state, sending: false, publisher: undefined };
    }

    case ASSET_SAVE_START: {
      return { ...state, sending: true };
    }

    case ASSET_SAVE_DONE:
    case ASSET_SAVE_FAILED: {
      return { ...state, sending: false };
    }

    default:
      return state;
  }
}
