import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import ContentList from "../containers/content/ContentList";
import ContentDetails from "../containers/content/ContentDetails";

import CurrentUser from "../models/CurrentUser";

interface Props {
  currentUser: CurrentUser;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(3, 2),
    borderRadius: 12,
  },
}));

export default function ContentBackoffice(props: Props) {
  const classes = useStyles();
  console.log("PROPS");
  const editing = useSelector(
    (state: RootStateOrAny) => state.contentEdit.editing
  );

  return (
    <div>
      {!editing ? (
        <Paper className={classes.root}>
          <Typography component="div">
            <ContentList modal={false} sending={false} uploading={false} />
          </Typography>
        </Paper>
      ) : (
        <ContentDetails />
      )}
    </div>
  );
}
