import React, { Component, useState, useEffect } from "react";
import Select from "react-select";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Form, Button, Col, Modal, CloseButton } from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";

import FormInputErrors, { hasErrors } from "../lib/FormInputErrors";
import DeleteButtonClickConfirm from "../lib/DeleteButtonClickConfirm";
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import Resources from "../../models/Resources";
import OptionResponse from "../../models/OptionResponse";
import MultiselectOption from "../../models/MultiselectOption";
import {
  StatusSalesOption,
  StatusResourcesOption,
} from "../../models/Constant";
import ResourcesHasContainer from "../../models/ResourcesHasContainer";
import Container from "../../models/Container";
import ContainerList from "../../containers/container/ContainerList";
import imgplaceholder from "../layout/img/upload_image_placeholder.png";
import ModalInfo from "../lib/ModalInfo";

export interface DataProps {
  resources: Resources;
  categoryOption: OptionResponse[];
  sending: boolean;
  errors: Errors;
}

interface Props extends DataProps {
  save: (resources: Resources) => void;
  cancelEdit: () => void;
  deleteResources: (id: string) => void;
  loadCategoryOption: () => void;
  saveContainerRelated: (
    resources_id: string,
    resourcesHasContainer: ResourcesHasContainer[]
  ) => void;
  openResources: (resources: Resources) => void;
  getResources: (id: string) => void;
}

interface Errors {
  id?: string[];
  type?: string[];
  title?: string[];
  subtitle?: string[];
  isbn?: string[];
  description?: string[];
  image_cover?: string[];
  date_publish?: string[];
  date_selling?: string[];
  status_selling?: string[];
  size_total?: string[];
  duration_total?: string[];
  price?: string[];
  price_net?: string[];
}

interface State {
  resourcesEditing: Resources;
  containerSelected: string[];
}

function ContainerRelatedModal(props: {
  CheckboxChange: (event: any, container: Container) => void;
  listSelected?: string[];
  saveContainerRelated: (closeModal: () => void) => void;
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        onClick={handleShow}
        size="sm"
        variant="secondary"
        className="btn-custom-secondary"
      >
        Modifica
      </Button>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Body>
          <ContainerList
            closeModal={handleClose}
            modal={true}
            checkboxChange={props.CheckboxChange}
            listSelected={props.listSelected}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => props.saveContainerRelated(handleClose)}
          >
            Salva modifiche
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default class ResourcesForm extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    //let contSelected : string[]=[];
    let contSelected: string[] = [];
    if (this.props.resources.containers != undefined) {
      this.props.resources.containers?.map((el, i) => {
        if (el != null) {
          contSelected.push(el.id);
        }
      });
    }

    this.state = {
      resourcesEditing: cloneDeep(this.props.resources),
      containerSelected: contSelected,
    };

    this.save = this.save.bind(this);
    this.setValue = this.setValue.bind(this);
    this.saveContainerRelated = this.saveContainerRelated.bind(this);
  }

  handleContainerCheckboxChange = (event: any, container: Container) => {
    const containerSelected = cloneDeep(this.state.containerSelected);
    if (event.target.checked) {
      containerSelected.push(container.id);
    } else {
      const index = containerSelected.indexOf(container.id, 0);
      if (index > -1) {
        containerSelected.splice(index, 1);
      }
    }

    this.setState({ containerSelected: containerSelected });
  };

  componentDidMount() {
    this.props.loadCategoryOption();
  }

  componentDidUpdate() {
    console.log("UPDATE - RESOURCES");
    console.log(this.props.resources);
  }
  componentWillUnmount() {
    this.props.cancelEdit();
  }

  saveContainerRelated(closeDialog: () => void) {
    closeDialog();
    const containerSelected = cloneDeep(this.state.containerSelected);
    let resourceshasContainerSelected: ResourcesHasContainer[] = [];
    let resourcesEditing = cloneDeep(this.state.resourcesEditing);
    containerSelected.map((el, i) => {
      resourceshasContainerSelected.push({
        resources_id: resourcesEditing.id,
        container_id: el,
      });
    });
    this.props.saveContainerRelated(
      resourcesEditing.id,
      resourceshasContainerSelected
    );
    //this.props.getResources(resourcesEditing.id);
    //this.props.openResources(resourcesEditing);

    //this.setStringValue("containers", resourcesEditing.containers)
    //this.setState({ resourcesEditing })
  }

  save() {
    this.props.save(this.state.resourcesEditing);
  }

  setValueMultiselect(attribute: string, options: string[]) {
    const resourcesEditing = cloneDeep(this.state.resourcesEditing);

    resourcesEditing[attribute] = options;
    this.setState({ resourcesEditing });
  }
  setStringValue(attribute: string, options: string | undefined) {
    const resourcesEditing = cloneDeep(this.state.resourcesEditing);

    resourcesEditing[attribute] = options;
    this.setState({ resourcesEditing });
  }

  setValue(
    attribute: string,
    { target: { value } }: { target: { value: string } }
  ) {
    const resourcesEditing = cloneDeep(this.state.resourcesEditing);

    resourcesEditing[attribute] = value;
    this.setState({ resourcesEditing });
  }

  async setValueImage(attribute: string, value?: FileList | null) {
    const resourcesEditing = cloneDeep(this.state.resourcesEditing);
    var reader = new FileReader();
    if (value == null) {
      resourcesEditing[attribute] = "";
      this.setState({ resourcesEditing });
    } else {
      reader.readAsDataURL(value?.item(0) || new File([], ""));
      var parentThis = this;
      reader.onload = function() {
        resourcesEditing[attribute] = reader.result?.toString() || "";
        parentThis.setState({ resourcesEditing });
      };
      reader.onerror = function(error) {
        alert("Error: " + error);
      };
      //this.props.save(this.state.resourcesEditing)
    }
  }

  render() {
    const {
      sending,
      errors,
      resources: { id },
      deleteResources,
    } = this.props;
    const { resourcesEditing } = this.state;
    const isNewRecord = !id;
    let statusOptionSelected = null;
    if (
      resourcesEditing.status_selling != "" &&
      resourcesEditing.status_selling != null
    ) {
      statusOptionSelected = {
        value: resourcesEditing.status_selling,
        label: StatusSalesOption.filter(
          (i) => i.value == resourcesEditing.status_selling ?? ""
        )[0].label,
      };
    }
    let contSelected: string[] = [];
    if (this.props.resources.containers != undefined) {
      this.props.resources.containers?.map((el, i) => {
        if (el != null) {
          contSelected.push(el.id);
        }
      });
    }

    let categoryOptionSelected: MultiselectOption[] = [];

    {
      if (this.props.categoryOption != undefined) {
        this.props.categoryOption.map((el, i) => {
          if (el != null) {
            if (
              resourcesEditing.categories?.find((f) => f == el.value) !=
              undefined
            ) {
              categoryOptionSelected.push(el);
            }
          }
        });
      }
    }
    let resTypeOptionSelected = null;
    if (
      resourcesEditing.type != null &&
      StatusResourcesOption.find((f) => f.value == resourcesEditing.type) !=
        undefined
    ) {
      resTypeOptionSelected = StatusResourcesOption.find(
        (f) => f.value == resourcesEditing.type
      );
    }

    var url = "url(".concat(resourcesEditing.image_cover || "").concat(")");
    let elementVal = 0;
    switch (this.props.resources.containers.length) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
        elementVal = this.props.resources.containers.length;
        break;
      default:
        elementVal = 5;
        break;
    }

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: elementVal,
      slidesToScroll: elementVal,
    };
    return (
      <>
        <Paper className="custom-paper">
          <Typography component="div">
            <Form>
              <Form.Row className="mt-4 ">
                <Col xs md lg="8">
                  <Form.Row>
                    <Col xs md lg="3">
                      <Form.Group>
                        <div
                          className="image-input image-input-outline"
                          id="kt_image_1"
                        >
                          <div
                            className="image-input-wrapper"
                            style={{ background: url }}
                          >
                            <img
                              className="placeholder-image"
                              hidden={
                                resourcesEditing.image_cover != null &&
                                resourcesEditing.image_cover != ""
                              }
                              src={imgplaceholder}
                            />
                          </div>

                          <label
                            className="custom-image-edit-icon-btn btn btn-xs btn-icon btn-white btn-hover-text-primary btn-shadow"
                            data-action="change"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Change avatar"
                          >
                            <i className="fa fa-pen icon-sm text-muted"></i>
                            <Form.Control
                              isInvalid={hasErrors(errors, "image_cover")}
                              disabled={sending}
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                this.setValueImage(
                                  "image_cover",
                                  event.currentTarget.files
                                )
                              }
                            />
                            <input type="hidden" name="profile_avatar_remove" />
                          </label>
                          <Button
                            className="custom-image-edit-icon-btn btn btn-xs btn-icon btn-white btn-hover-text-primary btn-shadow"
                            variant=""
                            data-action="remove"
                            disabled={sending}
                            hidden={
                              resourcesEditing.image_cover == null ||
                              resourcesEditing.image_cover == ""
                            }
                            data-toggle="tooltip"
                            title="Cancel avatar"
                            onClick={() =>
                              this.setValueImage("image_cover", null)
                            }
                          >
                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col xs md lg="8" className="ml-4">
                      <Form.Group>
                        <Form.Row>
                          <Form.Label className="text-dark-75 font-weight-bolder">
                            {resourcesEditing.title || ""}
                          </Form.Label>
                        </Form.Row>
                        <Form.Row>
                          <Form.Label className="">
                            {resourcesEditing.subtitle}
                          </Form.Label>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Col>
                <Col xs md lg="4">
                  <Form.Row>
                    <Col xs md lg="4">
                      <Form.Group>
                        <Form.Row>
                          <Form.Label className="text-dark-75 font-weight-bolder"></Form.Label>
                        </Form.Row>
                      </Form.Group>
                    </Col>

                    <Col xs md lg="8">
                      <Form.Group>
                        <Form.Row className="mr-2">
                          <Select
                            placeholder="Tipo di risorsa"
                            options={StatusResourcesOption}
                            value={resTypeOptionSelected}
                            onChange={(selectedOption) => {
                              this.setStringValue(
                                "type",
                                selectedOption?.value
                              );
                            }}
                          />
                        </Form.Row>
                        <Form.Row className="mt-4 box-ref">
                          <Col>
                            <Form.Row>
                              <Form.Label className="text-dark-100 font-weight-bolder custom-font-size-24">
                                {this.props.resources.linked_res_count}
                              </Form.Label>
                            </Form.Row>
                            <Form.Row>
                              <Form.Label className="text-dark-100 font-weight-bolder custom-font-light">
                                Risorse collegate
                              </Form.Label>
                            </Form.Row>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Col>
              </Form.Row>
            </Form>
          </Typography>
        </Paper>

        <Paper className="custom-paper">
          <Typography variant="h5" component="h3">
            <b>Dettaglio risorse</b>
          </Typography>
          <div className="separator separator-solid my-7"></div>
          <Typography component="div">
            <Form>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <label className="text-dark-75 font-weight-bolder">
                      Categoria
                    </label>
                  </Form.Group>
                </Col>
                <Col lg="4">
                  <Select
                    isMulti
                    className="custom-input"
                    options={this.props.categoryOption}
                    value={categoryOptionSelected}
                    placeholder="Seleziona una categoria"
                    onChange={(selectedOption) => {
                      let selArray: string[] = [];
                      selectedOption.map((o) => {
                        selArray.push(o.value);
                      });
                      this.setValueMultiselect("categories", selArray);
                    }}
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <label className="text-dark-75 font-weight-bolder">
                      Stato vendita
                    </label>
                  </Form.Group>
                </Col>
                <Col lg="4">
                  <Select
                    isClearable
                    className="custom-input"
                    options={StatusSalesOption}
                    placeholder="Seleziona uno stato"
                    value={statusOptionSelected}
                    onChange={(selectedOption) => {
                      this.setStringValue(
                        "status_selling",
                        selectedOption?.value
                      );
                    }}
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Titolo
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      isInvalid={hasErrors(errors, "title")}
                      disabled={sending}
                      className="custom-input"
                      placeholder="Inserisci il titolo"
                      type="text"
                      value={resourcesEditing.title || ""}
                      onChange={(event) => this.setValue("title", event)}
                    />
                    <FormInputErrors
                      elementId={`resources-${id}-title-field`}
                      errors={errors.title}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Sottotitolo
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      isInvalid={hasErrors(errors, "subtitle")}
                      disabled={sending}
                      className="custom-input"
                      placeholder="Inserisci il sottotitolo"
                      type="text"
                      value={resourcesEditing.subtitle || ""}
                      onChange={(event) => this.setValue("subtitle", event)}
                    />
                    <FormInputErrors
                      elementId={`resources-${id}-subtitle-field`}
                      errors={errors.title}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      ISBN
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      isInvalid={hasErrors(errors, "isbn")}
                      className="custom-input"
                      disabled={sending}
                      type="text"
                      value={resourcesEditing.isbn || ""}
                      onChange={(event) => this.setValue("isbn", event)}
                    />
                    <FormInputErrors
                      elementId={`resources-${id}-isbn-field`}
                      errors={errors.isbn}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Descrizione
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      isInvalid={hasErrors(errors, "description")}
                      disabled={sending}
                      className="custom-input"
                      placeholder="Inserisci una descrizione"
                      type="text"
                      value={resourcesEditing.description || ""}
                      onChange={(event) => this.setValue("description", event)}
                    />
                    <FormInputErrors
                      elementId={`resources-${id}-description-field`}
                      errors={errors.description}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Date
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="8">
                  <Form.Group>
                    <Form.Row>
                      <Col xs md lg="5">
                        <label>Pubblicazione</label>
                        <Form.Control
                          type="date"
                          isInvalid={hasErrors(errors, "date_publish")}
                          className="custom-input"
                          disabled={sending}
                          value={resourcesEditing.date_publish || ""}
                          placeholder="Date di pubblicazione"
                          onChange={(event) =>
                            this.setValue("date_publish", event)
                          }
                        />
                        <FormInputErrors
                          elementId={`resources-${id}-date_publish-field`}
                          errors={errors.date_publish}
                        />
                      </Col>
                      <Col xs md lg="5">
                        <label>Vendita</label>
                        <Form.Control
                          type="date"
                          isInvalid={hasErrors(errors, "date_selling")}
                          className="custom-input"
                          disabled={sending}
                          value={resourcesEditing.date_selling || ""}
                          placeholder="Date di vendita"
                          onChange={(event) =>
                            this.setValue("date_selling", event)
                          }
                        />
                        <FormInputErrors
                          elementId={`resources-${id}-date_selling-field`}
                          errors={errors.date_selling}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Dimensioni
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="8">
                  <Form.Group>
                    <Form.Row>
                      <Col xs md lg="5">
                        <Form.Control
                          type="text"
                          isInvalid={hasErrors(errors, "duration_total")}
                          className="custom-input"
                          disabled={sending}
                          value={resourcesEditing.duration_total || ""}
                          placeholder="hh:mm"
                          onChange={(event) =>
                            this.setValue("duration_total", event)
                          }
                        />
                        <FormInputErrors
                          elementId={`resources-${id}-duration_total-field`}
                          errors={errors.duration_total}
                        />
                      </Col>
                      <Col xs md lg="5">
                        <Form.Control
                          type="text"
                          isInvalid={hasErrors(errors, "size_total")}
                          className="custom-input"
                          disabled={sending}
                          value={resourcesEditing.size_total || ""}
                          placeholder="Dim. tot (Mb)"
                          onChange={(event) =>
                            this.setValue("size_total", event)
                          }
                        />
                        <FormInputErrors
                          elementId={`resources-${id}-size_total-field`}
                          errors={errors.size_total}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </Col>
              </Form.Row>
              <div className="separator separator-solid my-7"></div>
              <Form.Row className="mt-4 justify-content-between">
                {/*
                  !isNewRecord ?
                    <DeleteButtonClickConfirm
                      label="Delete resources" labelConfirm="Click to confirm" size="sm"
                      action={() => deleteResources(id)}
                      disabled={sending} />
                    :
                    <div />
                */}

                <div />

                <div>
                  <Button
                    onClick={this.props.cancelEdit}
                    disabled={sending}
                    size="sm"
                    variant="link"
                    className="marginButton"
                  >
                    Annulla
                  </Button>
                  <Button
                    onClick={this.save}
                    disabled={sending}
                    size="sm"
                    variant="primary"
                  >
                    Salva modifiche
                  </Button>
                </div>
              </Form.Row>
            </Form>
          </Typography>
        </Paper>
        <Paper className="custom-paper">
          <Typography variant="h5" component="h3">
            <Form.Row className="mt-4 justify-content-between">
              Related containers
              <div>
                <ContainerRelatedModal
                  CheckboxChange={this.handleContainerCheckboxChange}
                  listSelected={this.state.containerSelected}
                  saveContainerRelated={this.saveContainerRelated}
                />
              </div>
            </Form.Row>
          </Typography>
          <div className="separator separator-solid my-7"></div>
          <Typography component="div">
            <div>
              {elementVal < 5 ? (
                <Form.Row>
                  {this.props.resources.containers.map((el, i) => {
                    return (
                      <Col xs="2" style={{ textAlign: "center" }}>
                        <div key={i} className="div-parent-img-related">
                          <img src={el.img_cover} className="img-related" />
                          <label className="label-img-related">
                            <b>{el.title}</b>
                          </label>
                        </div>
                      </Col>
                    );
                  })}
                </Form.Row>
              ) : (
                <Slider {...settings}>
                  {this.props.resources.containers != undefined
                    ? this.props.resources.containers.map((el, i) => {
                        if (el != null) {
                          return (
                            <div key={i} className="div-parent-img-related">
                              <img src={el.img_cover} className="img-related" />
                              <label className="label-img-related">
                                <b>{el.title}</b>
                              </label>
                            </div>
                          );
                        }
                      })
                    : null}
                </Slider>
              )}
            </div>
          </Typography>
        </Paper>
      </>
    );
  }
}
