import {
  CONTENT_CONTAINER_OPTION_LIST_LOAD_START,
  CONTENT_CONTAINER_OPTION_LIST_LOAD_DONE,  
  ContentContainerOptionListAction,

  CONTENT_LIST_LOAD_START,
  CONTENT_LIST_LOAD_DONE,
  ContentListAction,

  CONTENT_OPEN,
  ContentOpen,

  CONTENT_EDIT,
  ContentEdit,

  CONTENT_SAVE_START,
  CONTENT_SAVE_DONE,
  CONTENT_SAVE_FAILED,
  ContentSaveAction,

  CONTENT_DELETE_START,
  CONTENT_DELETE_DONE,
  ContentDeleteAction
} from '../actionTypes'
import { service as contentService, SearchParams } from '../../services/ContentService'
import PaginatedResponse from '../../models/PaginatedResponse'
import Content, { build as buildContent } from '../../models/Content'
import OptionResponse from '../../models/OptionResponse'
import Container, { build as buildContainer } from '../../models/Container'

export function loadContentList(params?: SearchParams): (dispatch: (action: ContentListAction) => void) => void {
  console.log("action:loadContentList")
  return async dispatch => {
    dispatch({ type: CONTENT_LIST_LOAD_START })

    const list: PaginatedResponse<Content> = await contentService.search(params || {})
    console.log(list)
    dispatch({ type: CONTENT_LIST_LOAD_DONE, payload: list })
  }
}

export function openContent(payload: Content): (dispatch: (action: ContentOpen) => void) => void {
  return async dispatch => {
    dispatch({ type: CONTENT_OPEN, payload, editing:true})
  }
}

export function newContent(audio_book_id : string, label : string): (dispatch: (action: ContentOpen | ContentEdit) => void) => void {
  return async dispatch => {
    const newContent: Content = buildContent()
    newContent.audio_book_id = audio_book_id;
    let container : Container = buildContainer()
    container.id = audio_book_id;
    container.title = label;
    newContent.audio_book = container;
    dispatch({ type: CONTENT_OPEN, payload: newContent, editing:true })
    dispatch({ type: CONTENT_EDIT, payload: true })
  }
}

export function edit(): (dispatch: (action: ContentEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: CONTENT_EDIT, payload: true})
  }
}

export function cancelEdit(): (dispatch: (action: ContentEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: CONTENT_EDIT, payload: false })
  }
}

export function save(content: Content): (dispatch: (action: ContentSaveAction) => void) => void {
  return async dispatch => {
    dispatch({ type: CONTENT_SAVE_START })
    try {
      console.log("IL CONTENT")
      console.log(content)
      const updatedModel: Content = await contentService.save(content)
      dispatch({ type: CONTENT_SAVE_DONE, payload: updatedModel })
    } catch (err) {
      //dispatch({ type: CONTENT_SAVE_FAILED, payload: err.data })
    }
  }
}

export function deleteContent(id: string): (dispatch: (action: ContentDeleteAction) => void) => void {
  return async dispatch => {
    dispatch({ type: CONTENT_DELETE_START, payload: { id } })
    try {
      await contentService.deleteModel(id)
      dispatch({ type: CONTENT_DELETE_DONE, payload: { id } })
    } catch (err) {}
  }
}

export function loadContainerOption(params?: SearchParams): (dispatch: (action: ContentContainerOptionListAction) => void) => void {
  console.log("action:loadContentOption")
  return async dispatch => {
    //dispatch({ type: CONTENT_LIST_OPTION_LOAD_START })
    dispatch({ type: CONTENT_CONTAINER_OPTION_LIST_LOAD_START })

    const list: OptionResponse[] = await contentService.getContainerOptionList(params || {})
    console.log("LISTA RITORNATA DA CONTENT CONTAINER")
    console.log(list)
    dispatch({ type: CONTENT_CONTAINER_OPTION_LIST_LOAD_DONE, payload: list })
  }
}
