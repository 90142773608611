import { connect } from "react-redux";

import ContainerFormComponent, {
  DataProps as ContainerFormProps,
} from "../../components/container/ContainerForm";

import {
  CategoryListState,
  ContainerEditState,
  PeopleListState,
  PublisherListState,
} from "../../redux/state";
import {
  save,
  saveContentRelated,
  cancelEdit,
  deleteContainer,
} from "../../redux/actions/containerActions";
import { build as buildContainer } from "../../models/Container";
import { loadCategoryOption } from "../../redux/actions/categoryActions";
import { loadPublisherOption } from "../../redux/actions/publisherActions";
import { loadPeopleOption } from "../../redux/actions/peopleActions";

const mapStateToProps = ({
  containerEdit,
  categoryList,
  publisherList,
  peopleList,
}: {
  containerEdit: ContainerEditState;
  categoryList: CategoryListState;
  publisherList: PublisherListState;
  peopleList: PeopleListState;
}): ContainerFormProps => {
  const container = containerEdit.container || buildContainer();
  const categoryOption = categoryList.listOption || [];
  const publisherOption = publisherList.listOption || [];
  const peopleOption = peopleList.listOption || [];
  return {
    ...containerEdit,
    container,
    categoryOption,
    publisherOption,
    peopleOption,
  };
};

export default connect(mapStateToProps, {
  save,
  saveContentRelated,
  cancelEdit,
  deleteContainer,
  loadCategoryOption,
  loadPublisherOption,
  loadPeopleOption,
})(ContainerFormComponent as any);
