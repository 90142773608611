import React, { Component, Ref } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Pagination,
  Form,
  Col,
  InputGroup,
  Row,
} from "react-bootstrap";
import response from "../layout/img/response.svg";
import search from "../layout/img/search.svg";
import shoppings from "../layout/img/shoppings.svg";
import cand_chart from "../layout/img/cand_chart.png";
import line_chart from "../layout/img/line_chart.png";

import cloneDeep from "lodash/cloneDeep";
import DashboardModel from "../../models/Dashboard";
import Container from "../../models/Container";
import { LaptopWindows } from "@material-ui/icons";

export interface DataProps {
  dashboard: DashboardModel;
  isRedirectContainer: boolean;
  refContainer?: Ref<HTMLAnchorElement> | undefined;
}
interface Props extends DataProps {
  loadDashboard: () => void;
  newContainer: () => void;
  enableContainerRedirect: (container: Container) => void;
  disableContainerRedirect: () => void;
  newResources: () => void;
}
interface State {
  dashboardEditing: DashboardModel;
}

export default class DashboardForm extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = { dashboardEditing: cloneDeep(this.props.dashboard) };
    this.redirectToContainer = this.redirectToContainer.bind(this);
  }

  componentDidMount() {
    this.props.loadDashboard();
    this.props.disableContainerRedirect();
  }

  componentDidUpdate() {
    console.log("COMPONENT DI UPDATE");
    console.log(this.props.isRedirectContainer);

    if (this.props.isRedirectContainer == true) {
      console.log(this.context);
    }
  }

  redirectToContainer() {
    this.props.enableContainerRedirect(
      this.props.dashboard.container.last_created_container!
    );
  }

  render() {
    return (
      <>
        <Form.Row>
          <Col xs="6" className="p-5">
            <Form.Row className="first-r1-c1-dashboard">
              <Col>
                <Form.Row className="cell-header">Titoli attivi</Form.Row>
                <Form.Row className="cell-body">
                  <img src={line_chart} className="img-dashboard-chart" />
                </Form.Row>
                <Form.Row className="cell-footer">
                  <Col xs="3">
                    <label className="dashboard-label-number">
                      {this.props.dashboard.container.container_count}
                    </label>
                  </Col>
                  <Col xs="9">
                    <label>
                      {
                        this.props.dashboard.container
                          .current_week_container_count_percent
                      }
                      % questa settimana
                    </label>
                  </Col>
                </Form.Row>
              </Col>
            </Form.Row>
          </Col>
          <Col xs="6" className="p-5">
            <Form.Row className="first-r1-c2-dashboard">
              <Col>
                <Form.Row className="cell-header">Numero di review</Form.Row>
                <Form.Row className="cell-body">
                  <img src={cand_chart} className="img-dashboard-chart" />
                </Form.Row>
                <Form.Row className="cell-footer">
                  <Col xs="3">
                    <label className="dashboard-label-number">
                      {this.props.dashboard.review.review_count}
                    </label>
                  </Col>
                  <Col xs="9">
                    <label>
                      {this.props.dashboard.review.review_count}% questa
                      settimana
                    </label>
                  </Col>
                </Form.Row>
              </Col>
            </Form.Row>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col xs="8" className="p-5">
            <Form.Row>
              {/* IMPOSTARE LO STATO EDITING DEL COMPONENTE A TRUE E INIZIALIZZARE L'OGGETTO A VUOTO, POI FARE REDIRECT ALLA PAGINA*/}
              <Col className="mr-3 first-r2-c1-dashboard">
                <Link onClick={this.props.newContainer} to="/container">
                  <Form.Row>
                    <img src={response} className="dashboard-square-image" />
                  </Form.Row>
                  <Form.Row>
                    <label className="dashboard-aggiungi-audiolib">
                      APRI UN CONTENITORE
                    </label>
                  </Form.Row>
                </Link>
              </Col>
              <Col className="ml-3 first-r2-c2-dashboard">
                <Link onClick={this.props.newResources} to="/resources">
                  <Form.Row>
                    <img src={search} className="dashboard-square-image" />
                  </Form.Row>
                  <Form.Row>
                    <label className="dashboard-aggiungi-audiolib">
                      CREA UNA RISORSA
                    </label>
                  </Form.Row>
                </Link>
              </Col>
            </Form.Row>

            <Form.Row className="mt-10 first-r3-c1-dashboard">
              <Col>
                <img src={shoppings} className="dashboard-square-image-1" />
              </Col>
              <Col>
                <Form.Row className="cell-header-1">
                  <b>I CONTAINERS</b>
                </Form.Row>
                <Form.Row className="cell-body-1">
                  Aggiungi velocemente una risorsa nella piattaforma Emons
                </Form.Row>
                <Form.Row className="cell-footer">
                  <Link onClick={this.props.newResources} to="/resources">
                    <Button variant="" className="btn-r3-c1-dashboard">
                      AGGIUNGI
                    </Button>
                  </Link>
                </Form.Row>
              </Col>
            </Form.Row>
          </Col>
          <Col xs="4" className="p-5">
            <Form.Row className="first-r2-c3-dashboard">
              <Col>
                <div className="p-8">
                  <Form.Row className="cell-header-3">
                    ULTIMO AUDIOLIBRO
                  </Form.Row>
                  <Form.Row className="cell-body-3">
                    <Col>
                      <Form.Row>
                        <span className="container-title-dashboard">
                          {
                            this.props.dashboard.container
                              .last_created_container?.title
                          }
                        </span>
                      </Form.Row>
                      <Form.Row>
                        <span className="container-subtitle-dashboard">
                          {
                            this.props.dashboard.container
                              .last_created_container?.subtitle
                          }
                        </span>
                      </Form.Row>
                    </Col>
                  </Form.Row>
                  <Form.Row className="cell-footer-3">
                    <Link onClick={this.redirectToContainer} to="/container">
                      <Button variant="" className="btn-r2-c3-dashboard">
                        AGGIORNA
                      </Button>
                    </Link>
                  </Form.Row>
                </div>
                <Form.Row className="cell-img-3">
                  <img
                    src={
                      this.props.dashboard.container.last_created_container
                        ?.img_cover
                    }
                    className="dashboard-image-2"
                  />
                </Form.Row>
              </Col>
            </Form.Row>
          </Col>
        </Form.Row>
      </>
    );
  }
}
