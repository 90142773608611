import {
  CATEGORY_OPEN,
  CategoryOpen,
  CATEGORY_EDIT,
  CategoryEdit,
  CATEGORY_SAVE_START,
  CATEGORY_SAVE_DONE,
  CATEGORY_SAVE_FAILED,
  CategorySaveAction,
  CATEGORY_DELETE_START,
  CATEGORY_DELETE_DONE,
  CategoryDeleteAction,
  ASSET_SAVE_START,
  ASSET_SAVE_DONE,
  ASSET_SAVE_FAILED,
  AssetSaveAction,
} from "../actionTypes";
import { CategoryEditState } from "../state";

const initialState: CategoryEditState = {
  editing: false,
  sending: false,
  errors: {},
  saveSucceeded: false,
};

export default function reducer(
  state: CategoryEditState = initialState,
  action:
    | CategoryOpen
    | CategoryEdit
    | CategorySaveAction
    | CategoryDeleteAction
    | AssetSaveAction
) {
  switch (action.type) {
    case CATEGORY_OPEN: {
      const category = action.payload;
      return { ...initialState, category, editing: action.editing };
    }

    case CATEGORY_EDIT: {
      return { ...state, ...initialState, editing: action.payload };
    }

    case CATEGORY_SAVE_START: {
      return { ...state, sending: true, errors: {}, saveSucceeded: false };
    }

    case CATEGORY_SAVE_DONE: {
      return {
        ...state,
        editing: false,
        sending: false,
        errors: {},
        category: action.payload,
        saveSucceeded: true,
      };
    }

    case CATEGORY_SAVE_FAILED: {
      return {
        ...state,
        editing: true,
        sending: false,
        errors: action.payload,
        saveSucceeded: false,
      };
    }

    case CATEGORY_DELETE_START: {
      return { ...state, sending: true };
    }

    case CATEGORY_DELETE_DONE: {
      return { ...state, sending: false, category: undefined };
    }

    case ASSET_SAVE_START: {
      return { ...state, sending: true };
    }

    case ASSET_SAVE_DONE:
    case ASSET_SAVE_FAILED: {
      return { ...state, sending: false };
    }

    default:
      return state;
  }
}
