import { connect } from "react-redux";

import BookDetailsComponent from "../../components/books/BookDetails";

import { AudioBookEditState } from "../../redux/state";
import { edit } from "../../redux/actions/audioBookActions";

const mapStateToProps = ({
  audioBookEdit,
}: {
  audioBookEdit: AudioBookEditState;
}) => audioBookEdit || {};

export default connect(mapStateToProps, { edit })(BookDetailsComponent);
