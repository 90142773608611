import Container, { build as buildContainer } from "./Container";

export default interface DashboardModel {
  container: {
    container_count?: number;
    current_week_container_count?: number;
    last_week_container_count?: number;
    current_week_container_count_percent?: number;
    last_created_container?: Container;
  };
  review: {
    review_count: number;
  };
}

export function build() {
  return {
    container: {
      container_count: 0,
      current_week_container_count: 0,
      last_week_container_count: 0,
      current_week_container_count_percent: 0,
      last_created_container: buildContainer(),
    },
    review: {
      review_count: 0,
    },
  };
}
