import { AxiosRequestConfig } from "axios";

import HttpClientService, {
  HttpClientResponse,
} from "./HttpClientServiceLaravel";
import PaginatedResponse from "../models/PaginatedResponse";
import People from "../models/People";
import OptionResponse from "../models/OptionResponse";

export interface SearchParams {
  page?: number;
  search?: string;
}

export default class PeopleService extends HttpClientService {
  async searchAuthors(search: string): Promise<PaginatedResponse<People>> {
    const params: any = { type: "Author" };
    if ("string" === typeof search && "" !== search) {
      params.search = search;
    }

    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/v1/people",
      params,
    };

    const response: HttpClientResponse<PaginatedResponse<
      People
    >> = await this.doRequest(request);
    return response.data;
  }

  async saveAuthor(model: People): Promise<People> {
    let request: AxiosRequestConfig;
    const data = { ...model, type: "Author", id: undefined };

    if (model.id) {
      request = {
        method: "PATCH",
        url: `/api/v1/people/${model.id}`,
        data,
      };
    } else {
      request = {
        method: "POST",
        url: "/api/v1/people",
        data,
      };
    }
    const response: HttpClientResponse<People> = await this.doRequest(request);
    return response.data;
  }

  async search(params: SearchParams): Promise<PaginatedResponse<People>> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/v1/people",
      params,
    };

    const response: HttpClientResponse<PaginatedResponse<
      People
    >> = await this.doRequest(request);
    console.log(response);
    return response.data;
  }

  async save(model: People): Promise<People> {
    let request: AxiosRequestConfig;
    const data: any = { ...model, id: undefined };
    if (data.authors) {
      data.overrideAuthors = data.authors.map((a: any) => a.id);
      delete data.authors;
    }

    if (model.id) {
      request = {
        method: "PATCH",
        url: `/api/v1/people/${model.id}`,
        data: { people: data },
      };
    } else {
      request = {
        method: "POST",
        url: "/api/v1/people",
        data: { people: data },
      };
    }

    const response: HttpClientResponse<People> = await this.doRequest(request);
    return response.data;
  }

  async deleteModel(id: string): Promise<boolean> {
    const request: AxiosRequestConfig = {
      method: "DELETE",
      url: `/api/v1/people/${id}`,
    };

    await this.doRequest(request);
    return true;
  }

  async getOptionList(params: SearchParams): Promise<OptionResponse[]> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/v1/peopleoption",
      params,
    };

    const response: HttpClientResponse<OptionResponse[]> = await this.doRequest(
      request
    );
    console.log(response);
    return response.data;
  }
}

export const service = new PeopleService();
