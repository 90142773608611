import {
  PEOPLE_OPEN,
  PeopleOpen,
  PEOPLE_EDIT,
  PeopleEdit,
  PEOPLE_SAVE_START,
  PEOPLE_SAVE_DONE,
  PEOPLE_SAVE_FAILED,
  PeopleSaveAction,
  PEOPLE_DELETE_START,
  PEOPLE_DELETE_DONE,
  PeopleDeleteAction,
  ASSET_SAVE_START,
  ASSET_SAVE_DONE,
  ASSET_SAVE_FAILED,
  AssetSaveAction,
} from "../actionTypes";
import { PeopleEditState } from "../state";

const initialState: PeopleEditState = {
  editing: false,
  sending: false,
  errors: {},
  saveSucceeded: false,
};

export default function reducer(
  state: PeopleEditState = initialState,
  action:
    | PeopleOpen
    | PeopleEdit
    | PeopleSaveAction
    | PeopleDeleteAction
    | AssetSaveAction
) {
  switch (action.type) {
    case PEOPLE_OPEN: {
      const people = action.payload;
      return { ...initialState, people, editing: action.editing };
    }

    case PEOPLE_EDIT: {
      return { ...state, ...initialState, editing: action.payload };
    }

    case PEOPLE_SAVE_START: {
      return { ...state, sending: true, errors: {}, saveSucceeded: false };
    }

    case PEOPLE_SAVE_DONE: {
      return {
        ...state,
        editing: false,
        sending: false,
        errors: {},
        people: action.payload,
        saveSucceeded: true,
      };
    }

    case PEOPLE_SAVE_FAILED: {
      return {
        ...state,
        editing: true,
        sending: false,
        errors: action.payload,
        saveSucceeded: false,
      };
    }

    case PEOPLE_DELETE_START: {
      return { ...state, sending: true };
    }

    case PEOPLE_DELETE_DONE: {
      return { ...state, sending: false, people: undefined };
    }

    case ASSET_SAVE_START: {
      return { ...state, sending: true };
    }

    case ASSET_SAVE_DONE:
    case ASSET_SAVE_FAILED: {
      return { ...state, sending: false };
    }

    default:
      return state;
  }
}
