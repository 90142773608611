import Content from "./Content";

export default interface Container extends Record<string, any> {
  id: string;
  isbn: string;
  title: string;
  properties: string;
  product_id: string;
  subtitle: string;
  img_cover: string;
  type: string;
  kind: string;
  duration_total: string;
  categories?: string[];
  publishers?: string[];
  contributors?: string[];
  chapters: Content[];
}

export function build() {
  return {
    id: "",
    isbn: "",
    title: "",
    properties: "",
    product_id: "",
    subtitle: "",
    img_cover: "",
    type: "",
    duration_total: "",
    kind: "",
    chapters: [],
  };
}
