import React from "react";
import { Button, Table } from "react-bootstrap";
import join from "lodash/join";

import ContentForm from "../../containers/content/ContentForm";
import Content from "../../models/Content";

interface Props {
  content?: Content;
  editing: boolean;

  edit: () => void;
}

export default function ContentDetails(props: Props) {
  if (!props.content) {
    return <p className="font-italic">Select a content from the list</p>;
  }
  console.log(props);
  const { content } = props;

  return (
    <>
      <div className="mt-4 mb-4">{<ContentForm />}</div>
    </>
  );
}

function DetailsTable({ content }: { content: Content }) {
  return (
    <Table size="sm">
      <tbody>
        <tr>
          <th>ID</th>
          <td>{content.id}</td>
        </tr>
        <tr>
          <th>Name</th>
          <td>{content.name}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{content.description}</td>
        </tr>
      </tbody>
    </Table>
  );
}
