import {

    TRACKPREVIEW_LIST_LOAD_START,
    TRACKPREVIEW_LIST_LOAD_DONE,
    TrackPreviewListAction

} from '../actionTypes'

import PaginatedResponse from '../../models/PaginatedResponse';
import TrackPreview from '../../models/TrackPreview';

import { service as trackPreviewService, SearchParams } from '../../services/TrackPreviewService';
import { TrackPreviewSaveAction, TRACK_PREVIEW_SAVE_START, TRACK_PREVIEW_SAVE_DONE, TrackPreviewDeleteAction, TRACK_PREVIEW_DELETE_START, TRACK_PREVIEW_DELETE_DONE } from '../actionTypes';

export function loadTrackPreviewList(params?: SearchParams): (dispatch: (action: TrackPreviewListAction) => void) => void {
    console.log("action:loadTrackPreviewList")

    return async dispatch => {
        dispatch({ type: TRACKPREVIEW_LIST_LOAD_START})

        const list: PaginatedResponse<TrackPreview> = await trackPreviewService.search(params || {})
        console.log(list)
        dispatch({ type: TRACKPREVIEW_LIST_LOAD_DONE, payload: list })
    }
}


export function save(trackPreview: TrackPreview) : (dispatch: (action: TrackPreviewSaveAction | TrackPreviewListAction) => void) => void {

    console.log("AZIONE TRACK_PREVIEW_SAVE")
    return async dispatch => {
        dispatch({ type: TRACK_PREVIEW_SAVE_START})
        try {
            console.log("STARTING SAVE")
            console.log(trackPreview)
            const updatedModel: TrackPreview = await trackPreviewService.save(trackPreview)
            dispatch({ type: TRACK_PREVIEW_SAVE_DONE, payload: updatedModel })
            const list = await trackPreviewService.search({search: trackPreview.audio_bookfk})
            dispatch({ type: TRACKPREVIEW_LIST_LOAD_DONE, payload: list })
        } catch (err) {
            console.error(err)
        }
    }
}

export function deleteTrackPreview(model: TrackPreview) : (dispatch: (action: TrackPreviewDeleteAction | TrackPreviewListAction) => void) => void {
    console.log("ACTION DELETE TRACK PREVIEW START")
    return async dispatch => {
        dispatch({ type : TRACK_PREVIEW_DELETE_START, payload: { model }})
        try {
            await trackPreviewService.deleteModel(model)
            dispatch({ type: TRACK_PREVIEW_DELETE_DONE, payload: {model}})
            const list = await trackPreviewService.search({search: model.audio_bookfk})
            dispatch({ type: TRACKPREVIEW_LIST_LOAD_DONE, payload: list })
        } catch (e) {}
    }
}