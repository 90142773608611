import {
  AUTHOR_SAVE_START,
  AUTHOR_SAVE_DONE,
  AUTHOR_SAVE_FAILED,
  AuthorSaveAction,

  AUTHOR_NEW,
  AuthorNew
} from '../actionTypes'

import { service } from '../../services/PersonService'
import Author from '../../models/Author'

export function save(author: Author): (dispatch: (action: AuthorSaveAction | AuthorNew) => void) => void {
  return async dispatch => {
    dispatch({ type: AUTHOR_SAVE_START })
    try {
      const updatedModel: Author = await service.saveAuthor(author)
      dispatch({ type: AUTHOR_SAVE_DONE, payload: updatedModel })
      dispatch({ type: AUTHOR_NEW, payload: false })
    } catch (err) {
      //dispatch({ type: AUTHOR_SAVE_FAILED, payload: err.data })
      
    }
  }
}

export function edit(): (dispatch: (action: AuthorNew) => void) => void {
  return async dispatch => {
    dispatch({ type: AUTHOR_NEW, payload: true })
  }
}

export function cancelEdit(): (dispatch: (action: AuthorNew) => void) => void {
  return async dispatch => {
    dispatch({ type: AUTHOR_NEW, payload: false })
  }
}
