import clone from "lodash/clone";
import findIndex from "lodash/findIndex";

import {
  CHAPTERS_LOAD_START,
  CHAPTERS_LOAD_DONE,
  ChaptersAction,
  CHAPTER_SAVE_DONE,
  ChapterSaveAction,
  CHAPTER_DELETE_DONE,
  ChapterDeleteAction,
} from "../actionTypes";
import { ChaptersState } from "../state";
import Chapter from "../../models/Chapter";

const initialState: ChaptersState = { loading: false, chapters: [] };

export default function reducer(
  state: ChaptersState = initialState,
  action: ChaptersAction | ChapterSaveAction | ChapterDeleteAction
) {
  switch (action.type) {
    case CHAPTERS_LOAD_START: {
      return { ...state, loading: true };
    }

    case CHAPTERS_LOAD_DONE: {
      return { ...state, loading: false, chapters: action.payload };
    }

    case CHAPTER_SAVE_DONE: {
      const chapters: Chapter[] = clone(state.chapters);
      const updated: Chapter = action.payload;

      const index = findIndex(chapters, (entry) => entry.id === updated.id);
      if (index >= 0) {
        chapters[index] = updated;
      } else {
        chapters.push(updated);
      }
      return { ...state, chapters };
    }

    case CHAPTER_DELETE_DONE: {
      const { id } = action.payload;
      const chapters: Chapter[] = clone(state.chapters);

      const index = findIndex(chapters, (entry) => entry.id === id);
      if (index >= 0) {
        chapters.splice(index, 1);
      }
      return { ...state, chapters };
    }

    default:
      return state;
  }
}
