import { connect } from "react-redux";

import ContentDetailsComponent from "../../components/content/ContentDetails";

import { ContentEditState } from "../../redux/state";
import { edit } from "../../redux/actions/contentActions";

const mapStateToProps = ({ contentEdit }: { contentEdit: ContentEditState }) =>
  contentEdit || {};

export default connect(mapStateToProps, { edit })(ContentDetailsComponent);
