import {
  DASHBOARD_LOAD_START,
  DASHBOARD_LOAD_DONE,
  DashboardAction,
  DASHBOARD_REDIRECT_CONTAINER_TYPE,
  CONTAINER_OPEN,
  ContainerOpen,
  CONTAINER_EDIT,
  ContainerEdit,

} from '../actionTypes'
import { service as dashboardService } from '../../services/DashboardService'
import { service as containerService } from '../../services/ContainerService'
import DashboardModel from '../../models/Dashboard'
import Container from '../../models/Container'

export function loadDashboard(): (dispatch: (action: DashboardAction | ContainerOpen) => void) => void {
  console.log("action:loadDashboard")
  return async dispatch => {
    dispatch({ type: DASHBOARD_LOAD_START })

    const dashboard: DashboardModel = await dashboardService.loadDashboard()
    console.log("ACTION - DASHBOARD")
    console.log(dashboard)
    dispatch({ type: DASHBOARD_LOAD_DONE, payload: dashboard })
    //dispatch({ type: CONTAINER_OPEN, payload: dashboard.container.last_created_container!, editing: true })
  }
}

export function enableContainerRedirect(container :Container): (dispatch: (action: DashboardAction | ContainerOpen) => void) => void {
  
  return async dispatch => {

    dispatch({ type:CONTAINER_OPEN, payload: container, editing:true })
    //dispatch({ type: DASHBOARD_REDIRECT_CONTAINER_TYPE, isRedirectContainer:true })
  }
}

export function disableContainerRedirect(): (dispatch: (action: DashboardAction) => void) => void {
  
  return async dispatch => {
    dispatch({ type: DASHBOARD_REDIRECT_CONTAINER_TYPE, isRedirectContainer:false })
  }
}




