import React, { Component } from "react";
import PaginatedResponse from "../../models/PaginatedResponse";
import TrackPreview from "../../models/TrackPreview";
import TrackPreviewListComponent, {
  SearchParams,
} from "../../components/trackPreview/TrackPreviewList";
import {
  loadTrackPreviewList,
  save,
  deleteTrackPreview,
} from "../../redux/actions/trackPreviewActions";
import { StoreState } from "../../redux/state";
import { connect } from "react-redux";
import Container from "../../models/Container";

interface Props {
  loading: boolean;
  list: PaginatedResponse<TrackPreview>;
  container: Container;
  loadTrackPreviewList: (params: SearchParams) => void;
  //searchFunction: (params: SearchParams) => void
  save: (trackPreview: TrackPreview) => void;
  deleteTrackPreview: (model: TrackPreview) => void;
}

class TrackPreviewList extends Component<Props> {
  componentDidMount() {
    console.log("componentDidMount");
    console.log("before load");
    this.props.loadTrackPreviewList({ search: this.props.container.id });
    console.log(this.props.list);
    console.log("after load");
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.list.currentPage == prevProps.list.currentPage) {
      if (this.props.list.entries.length != prevProps.list.entries.length) {
        this.props.loadTrackPreviewList({ search: this.props.container.id });
      }
    }
  }

  render() {
    console.log("render");
    const {
      loading,
      list,
      container,
      loadTrackPreviewList,
      save,
      deleteTrackPreview,
    } = this.props;

    return (
      <TrackPreviewListComponent
        loading={loading}
        list={list}
        searchTrackPreview={loadTrackPreviewList}
        searchFunction={loadTrackPreviewList}
        save={save}
        deleteTrackPreview={deleteTrackPreview}
        container={container}
      />
    );
  }
}

const mapStateToProps = (store: StoreState) => {
  return store.trackPreview;
};

export default connect(mapStateToProps, {
  loadTrackPreviewList,
  save,
  deleteTrackPreview,
})(TrackPreviewList);
