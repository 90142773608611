import { connect } from "react-redux";

import ChapterDetailsComponent from "../../components/chapters/ChapterDetails";

import { ChapterEditState } from "../../redux/state";
import { edit } from "../../redux/actions/chapterActions";

const mapStateToProps = ({ chapterEdit }: { chapterEdit: ChapterEditState }) =>
  chapterEdit || {};

export default connect(mapStateToProps, { edit })(ChapterDetailsComponent);
