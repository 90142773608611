import React, { Component, useState, useEffect } from "react";
import Select from "react-select";
import {
  Form,
  ButtonGroup,
  Button,
  Card,
  Col,
  Modal,
  Table,
} from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import FormInputErrors, { hasErrors } from "../lib/FormInputErrors";
import DeleteButtonClickConfirm from "../lib/DeleteButtonClickConfirm";

import Container from "../../models/Container";
import { Divider } from "@material-ui/core";
import { TypeContainersOption } from "../../models/Constant";
import { BaseComponent } from "../BaseComponent";
import OptionResponse from "../../models/OptionResponse";
import MultiselectOption from "../../models/MultiselectOption";
import { Console } from "console";
import ContentList from "../../containers/content/ContentList";
import ContainerHasContent from "../../models/ContainerHasContent";
import Content from "../../models/Content";
import imgplaceholder from "../layout/img/upload_image_placeholder.png";
import SVG from "react-inlinesvg";
import DeleteButtonClickListModal from "../lib/DeleteButtonClickListModal";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import TrackPreviewList from "../../containers/trackPreview/TrackPreviewList";
import TrackPreview from "../../models/TrackPreview";

export interface DataProps {
  container: Container;
  categoryOption: OptionResponse[];
  publisherOption: OptionResponse[];
  peopleOption: OptionResponse[];
  sending: boolean;
  errors: Errors;
}

interface Props extends DataProps {
  save: (container: Container) => void;
  cancelEdit: () => void;
  deleteContainer: (id: string) => void;
  loadCategoryOption: () => void;
  loadPublisherOption: () => void;
  loadPeopleOption: () => void;
  saveContentRelated: (
    container_id: string,
    containerHasContent: ContainerHasContent[]
  ) => void;
}

interface Errors {
  id?: string[];
  title?: string[];
  subtitle?: string[];
  isbn?: string[];
  synopsis?: string[];
  type?: string[];
  duration_total?: string[];
  size_total?: string[];
  copyright?: string[];
  year_edition?: string[];
  date_publish?: string[];
  edition_text?: string[];
  truck_number?: string[];
}

interface State {
  containerEditing: Container;
  contentSelected: string[];
}

function PreviewModal(props: { container: Container }) {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [fileName, setFileName] = useState("");

  const [fileInput, setFileInput] = useState<HTMLInputElement | null>(null);
  useEffect(() => {
    setFileInput(
      document.getElementById("track_preview_input") as HTMLInputElement
    );
  });

  const handleTrackPreviewUpload = () => {
    const formsend = document.getElementById(
      "track_preview_input"
    ) as HTMLInputElement;
    //fileInput?.click();
    formsend.click();
  };

  return (
    <>
      <Button
        onClick={handleShow}
        size="sm"
        variant="secondary"
        className="btn-custom-secondary"
      >
        Modifica
      </Button>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Aggiungi o modifica tracce preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TrackPreviewList container={props.container} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annulla
          </Button>
          <Button variant="primary" onClick={handleTrackPreviewUpload}>
            Salva modifiche
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ContentRelatedModal(props: {
  CheckboxChange: (event: any, content: Content) => void;
  listSelected?: string[];
  saveContentRelated: (closeModal: () => void) => void;
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        onClick={handleShow}
        size="sm"
        variant="secondary"
        className="btn-custom-secondary"
      >
        Modifica
      </Button>

      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Body>
          <ContentList
            modal={true}
            checkboxChange={props.CheckboxChange}
            listSelected={props.listSelected}
            sending={false}
            uploading={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => props.saveContentRelated(handleClose)}
          >
            Salva modifiche
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default class ContainerForm extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    let contSelected: string[] = [];
    this.props.container.chapters?.map((el, i) => {
      contSelected.push(el.id);
    });
    console.log(contSelected);
    this.state = {
      containerEditing: cloneDeep(this.props.container),
      contentSelected: contSelected,
    };

    this.save = this.save.bind(this);
    this.setValue = this.setValue.bind(this);
    this.saveContentRelated = this.saveContentRelated.bind(this);
  }

  save() {
    this.props.save(this.state.containerEditing);
  }
  componentDidMount() {
    this.props.loadCategoryOption();
    this.props.loadPublisherOption();
    this.props.loadPeopleOption();

    const { containerEditing } = this.state;
    console.log("CONTAINER_EDITING");
    console.log(containerEditing);
    if (containerEditing.properties != "") {
      let properties = JSON.parse(containerEditing.properties);

      containerEditing.categories = properties.categories;
      containerEditing.publishers = properties.publishers;
      containerEditing.contributors = properties.contributors;
    }

    this.setState({ containerEditing });
  }
  componentWillUnmount() {
    this.props.cancelEdit();
  }

  handleContentCheckboxChange = (event: any, content: Content) => {
    const contentSelected = cloneDeep(this.state.contentSelected);
    if (event.target.checked) {
      contentSelected.push(content.id);
    } else {
      const index = contentSelected.indexOf(content.id, 0);
      if (index > -1) {
        contentSelected.splice(index, 1);
      }
    }

    this.setState({ contentSelected: contentSelected });
  };

  saveContentRelated(closeDialog: () => void) {
    closeDialog();
    const contentSelected = cloneDeep(this.state.contentSelected);
    let containerhasContentSelected: ContainerHasContent[] = [];
    let containerEditing = cloneDeep(this.state.containerEditing);
    contentSelected.map((el, i) => {
      containerhasContentSelected.push({
        container_id: containerEditing.id,
        content_id: el,
      });
    });
    this.props.saveContentRelated(
      containerEditing.id,
      containerhasContentSelected
    );
    /*this.props.getResources(containerEditing.id);
    this.props.openResources(containerEditing);
    */
  }

  setValueMultiselect(attribute: string, options: string[]) {
    const containerEditing = cloneDeep(this.state.containerEditing);
    console.log("MULTISELECT OPTION");
    console.log(attribute);
    console.log(options);
    containerEditing[attribute] = options;
    this.setState({ containerEditing });
  }
  setStringValue(attribute: string, options: string | undefined) {
    const containerEditing = cloneDeep(this.state.containerEditing);

    containerEditing[attribute] = options;
    this.setState({ containerEditing });
  }

  setValue(
    attribute: string,
    { target: { value } }: { target: { value: string } }
  ) {
    const containerEditing = cloneDeep(this.state.containerEditing);

    containerEditing[attribute] = value;
    this.setState({ containerEditing });
  }

  async setValueImage(attribute: string, value?: FileList | null) {
    console.log("setValueImage");
    console.log(value);
    const containerEditing = cloneDeep(this.state.containerEditing);
    var reader = new FileReader();
    if (value == null) {
      containerEditing[attribute] = "";
      this.setState({ containerEditing });
    } else {
      reader.readAsDataURL(value?.item(0) || new File([], ""));
      var parentThis = this;
      reader.onload = function() {
        containerEditing[attribute] = reader.result?.toString() || "";
        parentThis.setState({ containerEditing });
      };
      reader.onerror = function(error) {
        alert("Error: " + error);
      };
      //this.props.save(this.state.resourcesEditing)
    }
  }

  render() {
    const {
      sending,
      errors,
      container: { id },
      deleteContainer,
    } = this.props;
    const { containerEditing } = this.state;
    const isNewRecord = !id;
    console.log(containerEditing);

    let containerTypeOptionSelected = null;
    if (
      containerEditing.kind != null &&
      TypeContainersOption.find((f) => f.value == containerEditing.kind) !=
        undefined
    ) {
      console.log("Selezionato");
      console.log(
        TypeContainersOption.find((f) => f.value == containerEditing.kind)
      );
      containerTypeOptionSelected = TypeContainersOption.find(
        (f) => f.value == containerEditing.kind
      );
    }

    let categoryOptionSelected: MultiselectOption[] = [];

    {
      this.props.categoryOption.map((el, i) => {
        if (
          containerEditing.categories?.find((f) => f == el.value) != undefined
        ) {
          categoryOptionSelected.push(el);
        }
      });
    }

    let publisherOptionSelected: MultiselectOption[] = [];

    {
      this.props.publisherOption.map((el, i) => {
        if (
          containerEditing.publishers?.find((f) => f == el.value) != undefined
        ) {
          publisherOptionSelected.push(el);
        }
      });
    }

    let peopleOptionSelected: MultiselectOption[] = [];

    {
      this.props.peopleOption.map((el, i) => {
        if (
          containerEditing.contributors?.find((f) => f == el.value) != undefined
        ) {
          peopleOptionSelected.push(el);
        }
      });
    }

    var url = "url(".concat(containerEditing.img_cover || "").concat(")");
    const relatedContent: Content[] = this.props.container.chapters;

    let elementVal = 0;
    if (relatedContent != undefined) {
      switch (relatedContent.length) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
          elementVal = relatedContent.length;
          break;
        default:
          elementVal = 5;
          break;
      }
    }

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: elementVal,
      slidesToScroll: elementVal,
    };

    console.log("CONTAINER BEFORE RENDER");
    console.log(this.props.container.chapters);

    return (
      <>
        <Paper className="custom-paper">
          <Typography variant="h5" component="h3"></Typography>

          <Typography component="div">
            <Form>
              <Form.Row className="mt-4 ">
                <Col xs md lg="8">
                  <Form.Row>
                    <Col xs md lg="3">
                      <Form.Group>
                        <div
                          className="image-input image-input-outline"
                          id="kt_image_1"
                        >
                          <div
                            className="image-input-wrapper"
                            style={{ background: url }}
                          >
                            <img
                              className="placeholder-image"
                              hidden={
                                containerEditing.img_cover != null &&
                                containerEditing.img_cover != ""
                              }
                              src={imgplaceholder}
                            />
                            <label
                              style={{
                                width: "100%",
                                height: "100%",
                                opacity: 0,
                              }}
                              className="custom-image-edit-icon-btn btn btn-xs btn-icon btn-white btn-hover-text-primary btn-shadow"
                              data-action="change"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="Change avatar"
                            >
                              <i className="fa fa-pen icon-sm text-muted"></i>
                              <Form.Control
                                isInvalid={hasErrors(errors, "img_cover")}
                                disabled={sending}
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  this.setValueImage(
                                    "img_cover",
                                    event.currentTarget.files
                                  )
                                }
                              />
                              <input
                                type="hidden"
                                name="profile_avatar_remove"
                              />
                            </label>
                          </div>

                          <label
                            hidden={
                              containerEditing.img_cover == null ||
                              containerEditing.img_cover == ""
                            }
                            className="custom-image-edit-icon-btn btn btn-xs btn-icon btn-white btn-hover-text-primary btn-shadow"
                            data-action="change"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Change avatar"
                          >
                            <i className="fa fa-pen icon-sm text-muted"></i>
                            <Form.Control
                              isInvalid={hasErrors(errors, "img_cover")}
                              disabled={sending}
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                this.setValueImage(
                                  "img_cover",
                                  event.currentTarget.files
                                )
                              }
                            />
                            <input type="hidden" name="profile_avatar_remove" />
                          </label>
                          <Button
                            className="custom-image-edit-icon-btn btn btn-xs btn-icon btn-white btn-hover-text-primary btn-shadow"
                            variant=""
                            data-action="remove"
                            disabled={sending}
                            hidden={
                              containerEditing.img_cover == null ||
                              containerEditing.img_cover == ""
                            }
                            data-toggle="tooltip"
                            title="Cancel avatar"
                            onClick={() =>
                              this.setValueImage("img_cover", null)
                            }
                          >
                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>

                    <Col xs md lg="8" className="ml-4">
                      <Form.Group>
                        <Form.Row>
                          <Form.Label className="text-dark-75 font-weight-bolder">
                            {containerEditing.title || ""}
                          </Form.Label>
                        </Form.Row>
                        <Form.Row>
                          <Form.Label className="">
                            {containerEditing.subtitle}
                          </Form.Label>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Col>
                <Col xs md lg="4">
                  <Form.Row>
                    <Col xs md lg="4">
                      <Form.Group>
                        <Form.Row>
                          <Form.Label className="text-dark-75 font-weight-bolder"></Form.Label>
                        </Form.Row>
                      </Form.Group>
                    </Col>

                    <Col xs md lg="8">
                      <Form.Group>
                        <Form.Row className="mr-2">
                          <Select
                            className="w-100"
                            placeholder="Tipo di risorsa"
                            options={TypeContainersOption}
                            value={containerTypeOptionSelected}
                            onChange={(selectedOption) => {
                              console.log(selectedOption);
                              this.setStringValue(
                                "kind",
                                selectedOption?.value
                              );
                            }}
                          />
                        </Form.Row>
                        <Form.Row className="mt-4 box-ref">
                          <Col>
                            <Form.Row>
                              <Form.Label className="text-dark-100 font-weight-bolder custom-font-size-24">
                                {containerEditing.linked_res_count}
                              </Form.Label>
                            </Form.Row>
                            <Form.Row>
                              <Form.Label className="text-dark-100 font-weight-bolder custom-font-light">
                                Risorse collegate
                              </Form.Label>
                            </Form.Row>
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                </Col>
              </Form.Row>
            </Form>
          </Typography>
        </Paper>
        <Paper className="custom-paper">
          <Typography variant="h5" component="h3">
            Gestione container
          </Typography>
          <div className="separator separator-solid my-7"></div>
          <Typography component="div">
            <Form>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Streaming completato
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    {/*
                    <Form.Control
                      isInvalid={hasErrors(errors, 'streaming_completed')}
                      className="custom-input"
                      disabled
                      type="text" value={containerEditing.streaming_completed || 'No'}
                      onChange={event => this.setValue('streaming_completed', event)} />
                      */}
                    <Form.Label>
                      {containerEditing.streaming_completed || "No"}
                    </Form.Label>
                    {/*<FormInputErrors elementId={`container-${id}-streaming_completed-field`} errors={errors.streaming_completed} />*/}
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Ultimo aggiornamento
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    {/*}
                    <Form.Control
                      placeholder="gg/mm/aaaa hh:mm"
                      isInvalid={hasErrors(errors, 'updated_at')}
                      className="custom-input"
                      disabled
                      type="text" value={containerEditing.updated_at || ''}
                      onChange={event => this.setValue('updated_at', event)} />

                      */}
                    <Form.Label>
                      {containerEditing.updated_at || "gg/mm/aaaa hh:mm"}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Titolo
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      placeholder="Inserisci il titolo"
                      isInvalid={hasErrors(errors, "title")}
                      className="custom-input"
                      disabled={sending}
                      type="text"
                      value={containerEditing.title || ""}
                      onChange={(event) => this.setValue("title", event)}
                    />
                    <FormInputErrors
                      elementId={`container-${id}-title-field`}
                      errors={errors.title}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Sottotitolo
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      placeholder="Inserisci il sottotitolo"
                      isInvalid={hasErrors(errors, "subtitle")}
                      className="custom-input"
                      disabled={sending}
                      type="text"
                      value={containerEditing.subtitle || ""}
                      onChange={(event) => this.setValue("subtitle", event)}
                    />
                    <FormInputErrors
                      elementId={`container-${id}-subtitle-field`}
                      errors={errors.subtitle}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      ISBN
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      placeholder="ISBN"
                      isInvalid={hasErrors(errors, "isbn")}
                      className="custom-input"
                      disabled={sending}
                      type="text"
                      value={containerEditing.isbn || ""}
                      onChange={(event) => this.setValue("isbn", event)}
                    />
                    <FormInputErrors
                      elementId={`container-${id}-isbn-field`}
                      errors={errors.isbn}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Sinossi
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      placeholder="Inserisci una sinossi"
                      as="textarea"
                      rows={5}
                      className="custom-input"
                      isInvalid={hasErrors(errors, "synopsis")}
                      disabled={sending}
                      type="text"
                      value={containerEditing.synopsis || ""}
                      onChange={(event) => this.setValue("synopsis", event)}
                    />
                    <FormInputErrors
                      elementId={`container-${id}-synopsis-field`}
                      errors={errors.synopsis}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Categorie
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Select
                    isMulti
                    className="custom-input"
                    options={this.props.categoryOption}
                    value={categoryOptionSelected}
                    placeholder="Seleziona una categoria"
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      let selArray: string[] = [];
                      selectedOption.map((o) => {
                        selArray.push(o.value);
                      });
                      this.setValueMultiselect("categories", selArray);
                    }}
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Editori
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Select
                    isMulti
                    className="custom-input"
                    options={this.props.publisherOption}
                    value={publisherOptionSelected}
                    placeholder="Seleziona gli editori"
                    onChange={(selectedOption) => {
                      console.log(selectedOption);
                      let selArray: string[] = [];
                      selectedOption.map((o) => {
                        selArray.push(o.value);
                      });
                      this.setValueMultiselect("publishers", selArray);
                    }}
                  />
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Contributori
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Select
                    isMulti
                    className="custom-input"
                    options={this.props.peopleOption}
                    value={peopleOptionSelected}
                    placeholder="Seleziona i contributori"
                    onChange={(selectedOption) => {
                      console.log("CONTRIBUTORI SELEZIONATI");
                      console.log(selectedOption);
                      let selArray: string[] = [];
                      selectedOption.map((o) => {
                        selArray.push(o.value);
                      });

                      console.log("CONTRIBUTORI SELEZIONATI");
                      console.log(selArray);
                      this.setValueMultiselect("contributors", selArray);
                    }}
                  />
                </Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Dimensioni
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="8">
                  <Form.Group>
                    <Form.Row>
                      <Col xs md lg="5">
                        {/*}
                        <Form.Control type="text"
                          isInvalid={hasErrors(errors, 'duration_total')}
                          className='custom-input'
                          disabled={sending}
                          value={containerEditing.duration_total || ''}
                          placeholder="hh:mm"
                          onChange={event => this.setValue('duration_total', event)} />
                        <FormInputErrors elementId={`resources-${id}-duration_total-field`} errors={errors.duration_total} />
                        */}
                        <Form.Label>
                          {containerEditing.duration_total || "hh:mm"}
                        </Form.Label>
                      </Col>
                      <Col xs md lg="5">
                        {/*
                        <Form.Control type="number"
                          isInvalid={hasErrors(errors, 'size_total')}
                          className='custom-input'
                          disabled={sending}
                          value={containerEditing.size_total || ''}
                          placeholder="Dim. tot (Mb)"
                          onChange={event => this.setValue('size_total', event)} />
                        <FormInputErrors elementId={`resources-${id}-size_total-field`} errors={errors.size_total} />
                        */}
                        <Form.Label>
                          {containerEditing.size_total || "Dim. tot (Mb)"}
                        </Form.Label>
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Copyright
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      placeholder="All right reserved @"
                      className="custom-input"
                      isInvalid={hasErrors(errors, "copyright")}
                      disabled={sending}
                      type="text"
                      value={containerEditing.copyright || ""}
                      onChange={(event) => this.setValue("copyright", event)}
                    />
                    <FormInputErrors
                      elementId={`container-${id}-copyright-field`}
                      errors={errors.copyright}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Date
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="8">
                  <Form.Group>
                    <Form.Row>
                      <Col xs md lg="5">
                        <Form.Control
                          type="date"
                          isInvalid={hasErrors(errors, "date_publish")}
                          className="custom-input"
                          disabled={sending}
                          value={containerEditing.date_publish || ""}
                          placeholder="Date di pubblicazione"
                          onChange={(event) =>
                            this.setValue("date_publish", event)
                          }
                        />
                        <FormInputErrors
                          elementId={`resources-${id}-date_publish-field`}
                          errors={errors.date_publish}
                        />
                      </Col>
                      <Col xs md lg="5">
                        <Form.Control
                          type="number"
                          isInvalid={hasErrors(errors, "year_edition")}
                          className="custom-input"
                          disabled={sending}
                          value={containerEditing.year_edition || ""}
                          placeholder="Anno di edizione"
                          onChange={(event) =>
                            this.setValue("year_edition", event)
                          }
                        />
                        <FormInputErrors
                          elementId={`resources-${id}-date_selling-field`}
                          errors={errors.year_edition}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Edizione del testo
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      placeholder="1° Edizione mondadotir(I.E.)"
                      className="custom-input"
                      isInvalid={hasErrors(errors, "edition_text")}
                      disabled={sending}
                      type="text"
                      value={containerEditing.edition_text || ""}
                      onChange={(event) => this.setValue("edition_text", event)}
                    />
                    <FormInputErrors
                      elementId={`container-${id}-edition_text-field`}
                      errors={errors.edition_text}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Audio previews
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Control
                      placeholder=""
                      className="custom-input"
                      isInvalid={hasErrors(errors, "edition_text")}
                      disabled={sending}
                      type="text"
                      value={containerEditing.edition_text || ""}
                      onChange={(event) => this.setValue("edition_text", event)}
                    />
                    <FormInputErrors
                      elementId={`container-${id}-edition_text-field`}
                      errors={errors.edition_text}
                    />
                  </Form.Group>
                </Col>
                <Col xs md lg="4">
                  <Form.Group>
                    <div>
                      <PreviewModal container={this.state.containerEditing} />
                    </div>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row className="mt-4 ">
                <Col xs md lg="4">
                  <Form.Group>
                    <Form.Label className="text-dark-75 font-weight-bolder">
                      Numero tracce
                    </Form.Label>
                  </Form.Group>
                </Col>

                <Col xs md lg="4">
                  <Form.Group>
                    {/*
                    <Form.Control
                      placeholder="Numero tracce"
                      className="custom-input"
                      isInvalid={hasErrors(errors, 'truck_number')}
                      disabled
                      type="text" value={containerEditing.truck_number || ''}
                      onChange={event => this.setValue('truck_number', event)} />
                    <FormInputErrors elementId={`container-${id}-truck_number-field`} errors={errors.truck_number} />

                    */}

                    <Form.Label>
                      {containerEditing.truck_number || "Numero tracce"}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Form.Row>

              <div className="separator separator-solid my-7"></div>
              <Form.Row className="mt-4 justify-content-between">
                {/*
                  !isNewRecord ?
                    <DeleteButtonClickConfirm
                      label="Delete container" labelConfirm="Click to confirm" size="sm"
                      action={() => deleteContainer(id)}
                      disabled={sending} />
                    :
                    <div />
                */}
                <div />
                <div>
                  <Button
                    onClick={this.props.cancelEdit}
                    disabled={sending}
                    size="sm"
                    variant="link"
                    className="marginButton"
                  >
                    Annulla
                  </Button>
                  <Button
                    onClick={this.save}
                    disabled={sending}
                    size="sm"
                    variant="primary"
                  >
                    Salva modifiche
                  </Button>
                </div>
              </Form.Row>
            </Form>
          </Typography>
        </Paper>
        <Paper className="custom-paper">
          <Typography variant="h5" component="h3">
            <Form.Row className="mt-4 justify-content-between">
              Related content
              <div>
                <ContentRelatedModal
                  CheckboxChange={this.handleContentCheckboxChange}
                  listSelected={this.state.contentSelected}
                  saveContentRelated={this.saveContentRelated}
                />
              </div>
            </Form.Row>
          </Typography>
          <div className="separator separator-solid my-7"></div>
          <Typography component="div">
            <div>
              {elementVal < 5 ? (
                <Form.Row>
                  {this.props.container.chapters == undefined
                    ? null
                    : this.props.container.chapters.map((el, i) => {
                        return (
                          <Col xs="2" style={{ textAlign: "center" }}>
                            <div key={i} className="div-parent-img-related">
                              <img src={el.img_cover} className="img-related" />
                              <label className="label-img-related">
                                <b>{el.title}</b>
                              </label>
                            </div>
                          </Col>
                        );
                      })}
                </Form.Row>
              ) : (
                <Slider {...settings}>
                  {this.props.container.chapters == undefined
                    ? null
                    : this.props.container.chapters.map((el, i) => {
                        return (
                          <div key={i} className="div-parent-img-related">
                            <img src={el.img_cover} className="img-related" />
                            <label className="label-img-related">
                              <b>{el.title}</b>
                            </label>
                          </div>
                        );
                      })}
                </Slider>
              )}
            </div>
          </Typography>
        </Paper>
      </>
    );
  }
}
