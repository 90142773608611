import Container from "./Container";

export default interface Resources extends Record<string, any> {
  id: string;
  type: string;
  title: string;
  subtitle: string;
  isbn: string;
  description: string;
  image_cover: string;
  date_publish: string;
  date_selling?: string;
  status_selling: string;
  size_total: string;
  duration_total: string;
  categories?: string[];
  containers: Container[];
}

export function build() {
  return {
    id: "",
    type: "",
    title: "",
    subtitle: "",
    isbn: "",
    description: "",
    image_cover: "",
    date_publish: "",
    date_selling: "",
    status_selling: "",
    size_total: "",
    duration_total: "",
    categories: [],
    containers: [],
  };
}
