import PaginatedResponse from "../../models/PaginatedResponse";
import TrackPreview from "../../models/TrackPreview";
import Loader from "../Loader";
import React, { useState, useEffect } from "react";

import store from "../../redux/store";
import Container from "../../models/Container";

import { Button, Col, Form, Table, Modal } from "react-bootstrap";

import PaginationComponent from "../lib/PaginationComponent";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import imgplaceholder from "../layout/img/upload_image_placeholder.png";

export interface SearchParams {
  page?: number;
  search?: string;
}

interface Props {
  loading: boolean;
  list: PaginatedResponse<TrackPreview>;

  container: Container;

  searchTrackPreview: (params: SearchParams) => void;
  searchFunction: (params: SearchParams) => void;
  save: (trackPreview: TrackPreview) => void;
  deleteTrackPreview: (model: TrackPreview) => void;
}

export default function TrackPreviewList(props: Props) {
  const [fileToUpload, setFileToUpload] = useState("");
  const [fileToUploadName, setFileToUploadName] = useState("");

  const uploadFileHandle = (e: any) => {
    setFileToUpload(e.target.files[0]);
    setFileToUploadName(e.target.files[0].name);
  };
  /*
  const uploadFile = async (event: any) => {
    event.preventDefault()

    if (fileToUpload == "" || fileToUpload == null || fileToUpload == undefined) {
      setFileToUploadName("Devi prima caricare un file")
      return
    }
    const ff: any = fileToUpload;
    var fr = new FileReader()

    console.log("file")
    console.log(fileToUpload)
    fr.readAsDataURL(ff)
    fr.onload = () => {
      const tr: TrackPreview = {id: "id", track_number: 1, audio_bookfk: props.container.id, name: fileToUploadName, file: "", uploadFile: fr.result?.toString()};
      const t = props.save(tr)
      
      setFileToUpload("")
      setFileToUploadName("")
    }

    fr.onerror = (e) => {
      console.error(e)
    }

    
    
    
  }
*/

  const uploadFile = async (event: any) => {
    event.preventDefault();

    if (
      fileToUpload == "" ||
      fileToUpload == null ||
      fileToUpload == undefined
    ) {
      setFileToUploadName("Devi prima caricare un file");
      return;
    }

    const tr: TrackPreview = {
      id: "id",
      track_number: 1,
      audio_bookfk: props.container.id,
      name: fileToUploadName,
      file: "",
      uploadFile: fileToUpload,
    };
    const t = props.save(tr);
    setFileToUpload("");
    setFileToUploadName("");
  };
  const deleteTrackPreviewHandle = (model: TrackPreview) => {
    props.deleteTrackPreview(model);
  };

  // File managing

  if (props.loading) {
    return <Loader rows={10} />;
  }

  const list: PaginatedResponse<TrackPreview> = props.list;
  console.log("table component trackPreview");
  console.log(props);
  console.log("table component trackPreview");

  const tableEntries = list.entries.map((b, i) => {
    const open = (event: any) => {
      event.preventDefault();
    };

    return (
      <tr key={"audio-preview-entry-rand"}>
        <td></td>
        <td className="">
          <div>{"#" + b.track_number}</div>
        </td>
        <td className="">
          <div>{b.name}</div>
        </td>
        <td className="button-td" style={{ textAlign: "right" }}>
          <a
            onClick={() => deleteTrackPreviewHandle(b)}
            href="#"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
            </span>
          </a>
          {/*}
              <DeleteButtonClickListModal 
                deleteAction={deletePeople} 
                idDelete={1}
                />*/}
        </td>
      </tr>
    );
  });

  return (
    <>
      {0 === props.list.entries.length ? (
        <p className="font-italic">No preview matches your search criteria</p>
      ) : (
        <>
          <Table hover responsive size="sm" className="people-list-table">
            <thead className="MuiPaper-rounded">
              <tr>
                <th
                  style={{
                    border: "none",
                    borderTopLeftRadius: "6px",
                    borderBottomLeftRadius: "6px",
                  }}
                ></th>
                <th style={{ border: "none" }}>ID</th>
                <th style={{ border: "none", color: "#B5B5C3" }}>
                  NOME TRACCIA AUDIO
                </th>
                <th
                  style={{
                    border: "none",
                    borderTopRightRadius: "6px",
                    borderBottomRightRadius: "6px",
                  }}
                ></th>
              </tr>
            </thead>

            <tbody>{tableEntries}</tbody>
          </Table>
        </>
      )}

      <Form>
        <Form.Row>
          <Col lg={3}>
            <label className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
              Aggiungi le tracce audio preview
            </label>
          </Col>
          <Col>
            <Form.Group>
              <div className="image-input image-input-outline" id="kt_image_1">
                <div
                  className="image-input-wrapper"
                  style={{ width: "200px", background: "#f1faff" }}
                >
                  <img className="placeholder-image" src={imgplaceholder} />
                  <h4 style={{ marginTop: 60 }}>
                    {fileToUploadName == "" ? "" : fileToUploadName}
                  </h4>
                  <label
                    style={{ width: "100%", height: "100%", opacity: 0 }}
                    className="custom-image-edit-icon-btn btn btn-xs btn-icon  btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                  >
                    <i className="fa fa-pen icon-sm text-muted"></i>
                    <Form.Control
                      //isInvalid={hasErrors(errors, 'image_square')}
                      //disabled={sending}
                      type="file"
                      accept=".mp3"
                      onChange={uploadFileHandle}
                    />
                    <input type="hidden" name="profile_avatar_remove" />
                  </label>
                </div>

                <label
                  hidden={fileToUpload == null || fileToUpload == ""}
                  className="custom-image-edit-icon-btn btn btn-xs btn-icon  btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Change avatar"
                >
                  <i className="fa fa-pen icon-sm text-muted"></i>
                  <Form.Control
                    //isInvalid={hasErrors(errors, 'image_square')}
                    //disabled={sending}
                    type="file"
                    accept=".mp3"
                    //onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setValueImage('image_square', event.currentTarget.files)}
                  />
                  <input type="hidden" name="profile_avatar_remove" />
                </label>

                <Button
                  className="custom-image-edit-icon-btn btn btn-xs btn-icon btn-white btn-hover-text-primary btn-shadow"
                  variant=""
                  data-action="remove"
                  //disabled={sending}
                  hidden={fileToUpload == null || fileToUpload == ""}
                  data-toggle="tooltip"
                  title="Cancel avatar"
                  onClick={() => {
                    setFileToUpload("");
                    setFileToUploadName("");
                  }}
                >
                  <i className="ki ki-bold-close icon-xs text-muted"></i>
                </Button>
              </div>
            </Form.Group>
          </Col>
        </Form.Row>

        <button
          hidden
          onClick={uploadFile}
          id="track_preview_input"
          type="submit"
          className="btn btn-primary"
        >
          Carica file
        </button>
      </Form>
    </>
  );
}
