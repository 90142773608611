import CurrentUser from "../models/CurrentUser";
import PaginatedResponse from "../models/PaginatedResponse";
import AudioBook from "../models/AudioBook";
import Chapter from "../models/Chapter";
import Asset from "../models/Asset";
import Author from "../models/Author";
import Publisher from "../models/Publisher";
import People from "../models/People";
import Category from "../models/Category";
import Resources from "../models/Resources";
import Content from "../models/Content";
import Container from "../models/Container";
import OptionResponse from "../models/OptionResponse";
import DashboardModel from "../models/Dashboard";
import ImportResponse from "../models/ImportResponse";
import TrackPreview from "../models/TrackPreview";

// login
export const LOGIN_START = "LOGIN_START";
export const LOGIN_DONE = "LOGIN_DONE";
export const LOGIN_FAILED = "LOGIN_FAILED";

type LOGIN_START_TYPE = typeof LOGIN_START;
type LOGIN_DONE_TYPE = typeof LOGIN_DONE;
type LOGIN_FAILED_TYPE = typeof LOGIN_FAILED;

interface LoginStart {
  type: LOGIN_START_TYPE;
}

interface LoginDone {
  type: LOGIN_DONE_TYPE;
}

interface LoginFailed {
  type: LOGIN_FAILED_TYPE;
}

export type LoginAction = LoginStart | LoginDone | LoginFailed;

// current user
export const CURRENT_USER_LOAD_START = "CURRENT_USER_LOAD_START";
export const CURRENT_USER_LOAD_DONE = "CURRENT_USER_LOAD_DONE";
export const CURRENT_USER_LOAD_FAILED = "CURRENT_USER_LOAD_FAILED";

type CURRENT_USER_LOAD_START_TYPE = typeof CURRENT_USER_LOAD_START;
type CURRENT_USER_LOAD_DONE_TYPE = typeof CURRENT_USER_LOAD_DONE;
type CURRENT_USER_LOAD_FAILED_TYPE = typeof CURRENT_USER_LOAD_FAILED;

interface CurrentUserLoadStart {
  type: CURRENT_USER_LOAD_START_TYPE;
}

interface CurrentUserLoadDone {
  type: CURRENT_USER_LOAD_DONE_TYPE;
  payload: CurrentUser;
}

interface CurrentUserLoadFailed {
  type: CURRENT_USER_LOAD_FAILED_TYPE;
}

export type CurrentUserAction =
  | CurrentUserLoadStart
  | CurrentUserLoadDone
  | CurrentUserLoadFailed;

// AudioBook list
export const AUDIO_BOOKS_LIST_LOAD_START = "AUDIO_BOOKS_LIST_LOAD_START";
export const AUDIO_BOOKS_LIST_LOAD_DONE = "AUDIO_BOOKS_LIST_LOAD_DONE";

type AUDIO_BOOKS_LIST_LOAD_START_TYPE = typeof AUDIO_BOOKS_LIST_LOAD_START;
type AUDIO_BOOKS_LIST_LOAD_DONE_TYPE = typeof AUDIO_BOOKS_LIST_LOAD_DONE;

interface AudioBooksListLoadStart {
  type: AUDIO_BOOKS_LIST_LOAD_START_TYPE;
}

interface AudioBooksListLoadDone {
  type: AUDIO_BOOKS_LIST_LOAD_DONE_TYPE;
  payload: PaginatedResponse<AudioBook>;
}

export type AudioBooksListAction =
  | AudioBooksListLoadStart
  | AudioBooksListLoadDone;

// AudioBook open
export const AUDIO_BOOK_OPEN = "AUDIO_BOOK_OPEN";
type AUDIO_BOOK_OPEN_TYPE = typeof AUDIO_BOOK_OPEN;

export interface AudioBookOpen {
  type: AUDIO_BOOK_OPEN_TYPE;
  payload: AudioBook;
}

// AudioBook edit
export const AUDIO_BOOK_EDIT = "AUDIO_BOOK_EDIT";
type AUDIO_BOOK_EDIT_TYPE = typeof AUDIO_BOOK_EDIT;

export interface AudioBookEdit {
  type: AUDIO_BOOK_EDIT_TYPE;
  payload: boolean;
}

// AudioBook save
export const AUDIO_BOOK_SAVE_START = "AUDIO_BOOK_SAVE_START";
type AUDIO_BOOK_SAVE_START_TYPE = typeof AUDIO_BOOK_SAVE_START;
export const AUDIO_BOOK_SAVE_DONE = "AUDIO_BOOK_SAVE_DONE";
type AUDIO_BOOK_SAVE_DONE_TYPE = typeof AUDIO_BOOK_SAVE_DONE;
export const AUDIO_BOOK_SAVE_FAILED = "AUDIO_BOOK_SAVE_FAILED";
type AUDIO_BOOK_SAVE_FAILED_TYPE = typeof AUDIO_BOOK_SAVE_FAILED;

interface AudioBookSaveStart {
  type: AUDIO_BOOK_SAVE_START_TYPE;
}

interface AudioBookSaveDone {
  type: AUDIO_BOOK_SAVE_DONE_TYPE;
  payload: AudioBook;
}

interface AudioBookSaveFailed {
  type: AUDIO_BOOK_SAVE_FAILED_TYPE;
  payload: object;
}

export type AudioBookSaveAction =
  | AudioBookSaveStart
  | AudioBookSaveDone
  | AudioBookSaveFailed;

// AudioBook delete
export const AUDIO_BOOK_DELETE_START = "AUDIO_BOOK_DELETE_START";
type AUDIO_BOOK_DELETE_START_TYPE = typeof AUDIO_BOOK_DELETE_START;
export const AUDIO_BOOK_DELETE_DONE = "AUDIO_BOOK_DELETE_DONE";
type AUDIO_BOOK_DELETE_DONE_TYPE = typeof AUDIO_BOOK_DELETE_DONE;

interface AudioBookDeleteStart {
  type: AUDIO_BOOK_DELETE_START_TYPE;
  payload: { id: string };
}

interface AudioBookDeleteDone {
  type: AUDIO_BOOK_DELETE_DONE_TYPE;
  payload: { id: string };
}

export type AudioBookDeleteAction = AudioBookDeleteStart | AudioBookDeleteDone;

// Chapter list
export const CHAPTERS_LOAD_START = "CHAPTERS_LOAD_START";
export const CHAPTERS_LOAD_DONE = "CHAPTERS_LOAD_DONE";

type CHAPTERS_LOAD_START_TYPE = typeof CHAPTERS_LOAD_START;
type CHAPTERS_LOAD_DONE_TYPE = typeof CHAPTERS_LOAD_DONE;

interface ChaptersLoadStart {
  type: CHAPTERS_LOAD_START_TYPE;
}

interface ChaptersLoadDone {
  type: CHAPTERS_LOAD_DONE_TYPE;
  payload: Chapter[];
}

export type ChaptersAction = ChaptersLoadStart | ChaptersLoadDone;

// Chapter open
export const CHAPTER_OPEN = "CHAPTER_OPEN";
type CHAPTER_OPEN_TYPE = typeof CHAPTER_OPEN;

export interface ChapterOpen {
  type: CHAPTER_OPEN_TYPE;
  payload: Chapter;
}

// Chapter edit
export const CHAPTER_EDIT = "CHAPTER_EDIT";
type CHAPTER_EDIT_TYPE = typeof CHAPTER_EDIT;

export interface ChapterEdit {
  type: CHAPTER_EDIT_TYPE;
  payload: boolean;
}

// Chapter reload
export const CHAPTER_RELOAD = "CHAPTER_RELOAD";
type CHAPTER_RELOAD_TYPE = typeof CHAPTER_RELOAD;

export interface ChapterReload {
  type: CHAPTER_RELOAD_TYPE;
  payload: Chapter;
}

// Chapter save
export const CHAPTER_SAVE_START = "CHAPTER_SAVE_START";
type CHAPTER_SAVE_START_TYPE = typeof CHAPTER_SAVE_START;
export const CHAPTER_SAVE_DONE = "CHAPTER_SAVE_DONE";
type CHAPTER_SAVE_DONE_TYPE = typeof CHAPTER_SAVE_DONE;
export const CHAPTER_SAVE_FAILED = "CHAPTER_SAVE_FAILED";
type CHAPTER_SAVE_FAILED_TYPE = typeof CHAPTER_SAVE_FAILED;

interface ChapterSaveStart {
  type: CHAPTER_SAVE_START_TYPE;
}

interface ChapterSaveDone {
  type: CHAPTER_SAVE_DONE_TYPE;
  payload: Chapter;
}

interface ChapterSaveFailed {
  type: CHAPTER_SAVE_FAILED_TYPE;
  payload: object;
}

export type ChapterSaveAction =
  | ChapterSaveStart
  | ChapterSaveDone
  | ChapterSaveFailed;

// Chapter delete
export const CHAPTER_DELETE_START = "CHAPTER_DELETE_START";
type CHAPTER_DELETE_START_TYPE = typeof CHAPTER_DELETE_START;
export const CHAPTER_DELETE_DONE = "CHAPTER_DELETE_DONE";
type CHAPTER_DELETE_DONE_TYPE = typeof CHAPTER_DELETE_DONE;

interface ChapterDeleteStart {
  type: CHAPTER_DELETE_START_TYPE;
  payload: { id: string };
}

interface ChapterDeleteDone {
  type: CHAPTER_DELETE_DONE_TYPE;
  payload: { id: string };
}

export type ChapterDeleteAction = ChapterDeleteStart | ChapterDeleteDone;

// EXCEL upload
export const EXCEL_UPLOAD_START = "EXCEL_UPLOAD_START";
type EXCEL_UPLOAD_START_TYPE = typeof EXCEL_UPLOAD_START;
export const EXCEL_UPLOAD_DONE = "EXCEL_UPLOAD_DONE";
type EXCEL_UPLOAD_DONE_TYPE = typeof EXCEL_UPLOAD_DONE;

interface ExcelUploadStart {
  type: EXCEL_UPLOAD_START_TYPE;
}

interface ExcelUploadDone {
  type: EXCEL_UPLOAD_DONE_TYPE;
  importResponse?: ImportResponse;
}
// EXCEL download
export const EXCEL_DOWNLOAD_START = "EXCEL_DOWNLOAD_START";
type EXCEL_DOWNLOAD_START_TYPE = typeof EXCEL_DOWNLOAD_START;
export const EXCEL_DOWNLOAD_DONE = "EXCEL_DOWNLOAD_DONE";
type EXCEL_DOWNLOAD_DONE_TYPE = typeof EXCEL_DOWNLOAD_DONE;

interface ExcelDownloadStart {
  type: EXCEL_DOWNLOAD_START_TYPE;
}

interface ExcelDownloadDone {
  type: EXCEL_DOWNLOAD_DONE_TYPE;
}
export type ExcelAction =
  | ExcelUploadStart
  | ExcelUploadDone
  | ExcelDownloadStart
  | ExcelDownloadDone;

// Asset save/upload
export const ASSET_UPLOAD_START = "ASSET_UPLOAD_START";
type ASSET_UPLOAD_START_TYPE = typeof ASSET_UPLOAD_START;
export const ASSET_UPLOAD_DONE = "ASSET_UPLOAD_DONE";
type ASSET_UPLOAD_DONE_TYPE = typeof ASSET_UPLOAD_DONE;
export const ASSET_SAVE_START = "ASSET_SAVE_START";
type ASSET_SAVE_START_TYPE = typeof ASSET_SAVE_START;
export const ASSET_SAVE_DONE = "ASSET_SAVE_DONE";
type ASSET_SAVE_DONE_TYPE = typeof ASSET_SAVE_DONE;
export const ASSET_SAVE_FAILED = "ASSET_SAVE_FAILED";
type ASSET_SAVE_FAILED_TYPE = typeof ASSET_SAVE_FAILED;

interface AssetUploadStart {
  type: ASSET_UPLOAD_START_TYPE;
}

interface AssetUploadDone {
  type: ASSET_UPLOAD_DONE_TYPE;
}

interface AssetSaveStart {
  type: ASSET_SAVE_START_TYPE;
}

interface AssetSaveDone {
  type: ASSET_SAVE_DONE_TYPE;
  payload: Asset;
}

interface AssetSaveFailed {
  type: ASSET_SAVE_FAILED_TYPE;
  payload: object;
}

export type AssetSaveAction =
  | AssetUploadStart
  | AssetUploadDone
  | AssetSaveStart
  | AssetSaveDone
  | AssetSaveFailed;

// Asset delete
export const ASSET_DELETE_START = "ASSET_DELETE_START";
type ASSET_DELETE_START_TYPE = typeof ASSET_DELETE_START;
export const ASSET_DELETE_DONE = "ASSET_DELETE_DONE";
type ASSET_DELETE_DONE_TYPE = typeof ASSET_DELETE_DONE;

interface AssetDeleteStart {
  type: ASSET_DELETE_START_TYPE;
  payload: { id: string };
}

interface AssetDeleteDone {
  type: ASSET_DELETE_DONE_TYPE;
  payload: { id: string };
}

export type AssetDeleteAction = AssetDeleteStart | AssetDeleteDone;

// Author save
export const AUTHOR_SAVE_START = "AUTHOR_SAVE_START";
type AUTHOR_SAVE_START_TYPE = typeof AUTHOR_SAVE_START;
export const AUTHOR_SAVE_DONE = "AUTHOR_SAVE_DONE";
type AUTHOR_SAVE_DONE_TYPE = typeof AUTHOR_SAVE_DONE;
export const AUTHOR_SAVE_FAILED = "AUTHOR_SAVE_FAILED";
type AUTHOR_SAVE_FAILED_TYPE = typeof AUTHOR_SAVE_FAILED;

interface AuthorSaveStart {
  type: AUTHOR_SAVE_START_TYPE;
}

interface AuthorSaveDone {
  type: AUTHOR_SAVE_DONE_TYPE;
  payload: Author;
}

interface AuthorSaveFailed {
  type: AUTHOR_SAVE_FAILED_TYPE;
  payload: object;
}

export type AuthorSaveAction =
  | AuthorSaveStart
  | AuthorSaveDone
  | AuthorSaveFailed;

// Author new
export const AUTHOR_NEW = "AUTHOR_NEW";
type AUTHOR_NEW_TYPE = typeof AUTHOR_NEW;

export interface AuthorNew {
  type: AUTHOR_NEW_TYPE;
  payload: boolean;
}

// Publisher list
export const PUBLISHER_LIST_LOAD_START = "PUBLISHER_LIST_LOAD_START";
export const PUBLISHER_LIST_LOAD_DONE = "PUBLISHER_LIST_LOAD_DONE";

type PUBLISHER_LIST_LOAD_START_TYPE = typeof PUBLISHER_LIST_LOAD_START;
type PUBLISHER_LIST_LOAD_DONE_TYPE = typeof PUBLISHER_LIST_LOAD_DONE;

interface PublisherListLoadStart {
  type: PUBLISHER_LIST_LOAD_START_TYPE;
}

interface PublisherListLoadDone {
  type: PUBLISHER_LIST_LOAD_DONE_TYPE;
  payload: PaginatedResponse<Publisher>;
}

export type PublisherListAction =
  | PublisherListLoadStart
  | PublisherListLoadDone;
//Publisher list option
export const PUBLISHER_LIST_OPTION_LOAD_START =
  "PUBLISHER_LIST_OPTION_LOAD_START";
export const PUBLISHER_LIST_OPTION_LOAD_DONE =
  "PUBLISHER_LIST_OPTION_LOAD_DONE";

type PUBLISHER_LIST_LOAD_OPTION_START_TYPE = typeof PUBLISHER_LIST_OPTION_LOAD_START;
type PUBLISHER_LIST_LOAD_OPTION_DONE_TYPE = typeof PUBLISHER_LIST_OPTION_LOAD_DONE;

interface PublisherListLoadOptionStart {
  type: PUBLISHER_LIST_LOAD_OPTION_START_TYPE;
}

interface PublisherListLoadOptionDone {
  type: PUBLISHER_LIST_LOAD_OPTION_DONE_TYPE;
  payload: OptionResponse[];
}

export type PublisherListOptionAction =
  | PublisherListLoadOptionStart
  | PublisherListLoadOptionDone;

// Publisher open
export const PUBLISHER_OPEN = "PUBLISHER_OPEN";
type PUBLISHER_OPEN_TYPE = typeof PUBLISHER_OPEN;

export interface PublisherOpen {
  type: PUBLISHER_OPEN_TYPE;
  payload: Publisher;
  editing: boolean;
}

// Publisher edit
export const PUBLISHER_EDIT = "PUBLISHER_EDIT";
type PUBLISHER_EDIT_TYPE = typeof PUBLISHER_EDIT;

export interface PublisherEdit {
  type: PUBLISHER_EDIT_TYPE;
  payload: boolean;
}

// Publisher save
export const PUBLISHER_SAVE_START = "PUBLISHER_SAVE_START";
type PUBLISHER_SAVE_START_TYPE = typeof PUBLISHER_SAVE_START;
export const PUBLISHER_SAVE_DONE = "PUBLISHER_SAVE_DONE";
type PUBLISHER_SAVE_DONE_TYPE = typeof PUBLISHER_SAVE_DONE;
export const PUBLISHER_SAVE_FAILED = "PUBLISHER_SAVE_FAILED";
type PUBLISHER_SAVE_FAILED_TYPE = typeof PUBLISHER_SAVE_FAILED;

interface PublisheraveStart {
  type: PUBLISHER_SAVE_START_TYPE;
}

interface PublisheraveDone {
  type: PUBLISHER_SAVE_DONE_TYPE;
  payload: Publisher;
}

interface PublisheraveFailed {
  type: PUBLISHER_SAVE_FAILED_TYPE;
  payload: object;
}

export type PublisherSaveAction =
  | PublisheraveStart
  | PublisheraveDone
  | PublisheraveFailed;

// Publisher delete
export const PUBLISHER_DELETE_START = "PUBLISHER_DELETE_START";
type PUBLISHER_DELETE_START_TYPE = typeof PUBLISHER_DELETE_START;
export const PUBLISHER_DELETE_DONE = "PUBLISHER_DELETE_DONE";
type PUBLISHER_DELETE_DONE_TYPE = typeof PUBLISHER_DELETE_DONE;

interface PublisherDeleteStart {
  type: PUBLISHER_DELETE_START_TYPE;
  payload: { id: string };
}

interface PublisherDeleteDone {
  type: PUBLISHER_DELETE_DONE_TYPE;
  payload: { id: string };
}

export type PublisherDeleteAction = PublisherDeleteStart | PublisherDeleteDone;

// People list
export const PEOPLE_LIST_LOAD_START = "PEOPLE_LIST_LOAD_START";
export const PEOPLE_LIST_LOAD_DONE = "PEOPLE_LIST_LOAD_DONE";

type PEOPLE_LIST_LOAD_START_TYPE = typeof PEOPLE_LIST_LOAD_START;
type PEOPLE_LIST_LOAD_DONE_TYPE = typeof PEOPLE_LIST_LOAD_DONE;

interface PeopleListLoadStart {
  type: PEOPLE_LIST_LOAD_START_TYPE;
}

interface PeopleListLoadDone {
  type: PEOPLE_LIST_LOAD_DONE_TYPE;
  payload: PaginatedResponse<People>;
}

export type PeopleListAction = PeopleListLoadStart | PeopleListLoadDone;

//People list option
export const PEOPLE_LIST_OPTION_LOAD_START = "PEOPLE_LIST_OPTION_LOAD_START";
export const PEOPLE_LIST_OPTION_LOAD_DONE = "PEOPLE_LIST_OPTION_LOAD_DONE";

type PEOPLE_LIST_LOAD_OPTION_START_TYPE = typeof PEOPLE_LIST_OPTION_LOAD_START;
type PEOPLE_LIST_LOAD_OPTION_DONE_TYPE = typeof PEOPLE_LIST_OPTION_LOAD_DONE;

interface PeopleListLoadOptionStart {
  type: PEOPLE_LIST_LOAD_OPTION_START_TYPE;
}

interface PeopleListLoadOptionDone {
  type: PEOPLE_LIST_LOAD_OPTION_DONE_TYPE;
  payload: OptionResponse[];
}

export type PeopleListOptionAction =
  | PeopleListLoadOptionStart
  | PeopleListLoadOptionDone;

// People open
export const PEOPLE_OPEN = "PEOPLE_OPEN";
type PEOPLE_OPEN_TYPE = typeof PEOPLE_OPEN;

export interface PeopleOpen {
  type: PEOPLE_OPEN_TYPE;
  payload: People;
  editing: boolean;
}

// People edit
export const PEOPLE_EDIT = "PEOPLE_EDIT";
type PEOPLE_EDIT_TYPE = typeof PEOPLE_EDIT;

export interface PeopleEdit {
  type: PEOPLE_EDIT_TYPE;
  payload: boolean;
}

// People save
export const PEOPLE_SAVE_START = "PEOPLE_SAVE_START";
type PEOPLE_SAVE_START_TYPE = typeof PEOPLE_SAVE_START;
export const PEOPLE_SAVE_DONE = "PEOPLE_SAVE_DONE";
type PEOPLE_SAVE_DONE_TYPE = typeof PEOPLE_SAVE_DONE;
export const PEOPLE_SAVE_FAILED = "PEOPLE_SAVE_FAILED";
type PEOPLE_SAVE_FAILED_TYPE = typeof PEOPLE_SAVE_FAILED;

interface PeopleaveStart {
  type: PEOPLE_SAVE_START_TYPE;
}

interface PeopleaveDone {
  type: PEOPLE_SAVE_DONE_TYPE;
  payload: People;
}

interface PeopleaveFailed {
  type: PEOPLE_SAVE_FAILED_TYPE;
  payload: object;
}

export type PeopleSaveAction = PeopleaveStart | PeopleaveDone | PeopleaveFailed;

// People delete
export const PEOPLE_DELETE_START = "PEOPLE_DELETE_START";
type PEOPLE_DELETE_START_TYPE = typeof PEOPLE_DELETE_START;
export const PEOPLE_DELETE_DONE = "PEOPLE_DELETE_DONE";
type PEOPLE_DELETE_DONE_TYPE = typeof PEOPLE_DELETE_DONE;

interface PeopleDeleteStart {
  type: PEOPLE_DELETE_START_TYPE;
  payload: { id: string };
}

interface PeopleDeleteDone {
  type: PEOPLE_DELETE_DONE_TYPE;
  payload: { id: string };
}

export type PeopleDeleteAction = PeopleDeleteStart | PeopleDeleteDone;

// Dashboard
export const DASHBOARD_LOAD_START = "DASHBOARD_LOAD_START";
export const DASHBOARD_LOAD_DONE = "DASHBOARD_LOAD_DONE";
export const DASHBOARD_REDIRECT_CONTAINER_TYPE =
  "DASHBOARD_REDIRECT_CONTAINER_TYPE";

type DASHBOARD_LOAD_START_TYPE = typeof DASHBOARD_LOAD_START;
type DASHBOARD_LOAD_DONE_TYPE = typeof DASHBOARD_LOAD_DONE;

type DASHBOARD_REDIRECT_CONTAINER_TYPE = typeof DASHBOARD_REDIRECT_CONTAINER_TYPE;

interface DashboardLoadStart {
  type: DASHBOARD_LOAD_START_TYPE;
}

interface DashboardLoadDone {
  type: DASHBOARD_LOAD_DONE_TYPE;
  payload: DashboardModel;
}

interface DashboardRedirectContainer {
  type: DASHBOARD_REDIRECT_CONTAINER_TYPE;
  isRedirectContainer: boolean;
}

export type DashboardAction =
  | DashboardLoadStart
  | DashboardLoadDone
  | DashboardRedirectContainer;

// Category list
export const CATEGORY_LIST_LOAD_START = "CATEGORY_LIST_LOAD_START";
export const CATEGORY_LIST_LOAD_DONE = "CATEGORY_LIST_LOAD_DONE";

type CATEGORY_LIST_LOAD_START_TYPE = typeof CATEGORY_LIST_LOAD_START;
type CATEGORY_LIST_LOAD_DONE_TYPE = typeof CATEGORY_LIST_LOAD_DONE;

interface CategoryListLoadStart {
  type: CATEGORY_LIST_LOAD_START_TYPE;
}

interface CategoryListLoadDone {
  type: CATEGORY_LIST_LOAD_DONE_TYPE;
  payload: PaginatedResponse<Category>;
}
export type CategoryListAction = CategoryListLoadStart | CategoryListLoadDone;

export const CATEGORY_LIST_OPTION_LOAD_START =
  "CATEGORY_LIST_OPTION_LOAD_START";
export const CATEGORY_LIST_OPTION_LOAD_DONE = "CATEGORY_LIST_OPTION_LOAD_DONE";

type CATEGORY_LIST_LOAD_OPTION_START_TYPE = typeof CATEGORY_LIST_OPTION_LOAD_START;
type CATEGORY_LIST_LOAD_OPTION_DONE_TYPE = typeof CATEGORY_LIST_OPTION_LOAD_DONE;

interface CategoryListLoadOptionStart {
  type: CATEGORY_LIST_LOAD_OPTION_START_TYPE;
}

interface CategoryListLoadOptionDone {
  type: CATEGORY_LIST_LOAD_OPTION_DONE_TYPE;
  payload: OptionResponse[];
}

export type CategoryListOptionAction =
  | CategoryListLoadOptionStart
  | CategoryListLoadOptionDone;

// Category open
export const CATEGORY_OPEN = "CATEGORY_OPEN";
type CATEGORY_OPEN_TYPE = typeof CATEGORY_OPEN;

export interface CategoryOpen {
  type: CATEGORY_OPEN_TYPE;
  payload: Category;
  editing: boolean;
}

// Category edit
export const CATEGORY_EDIT = "CATEGORY_EDIT";
type CATEGORY_EDIT_TYPE = typeof CATEGORY_EDIT;

export interface CategoryEdit {
  type: CATEGORY_EDIT_TYPE;
  payload: boolean;
}

// Category save
export const CATEGORY_SAVE_START = "CATEGORY_SAVE_START";
type CATEGORY_SAVE_START_TYPE = typeof CATEGORY_SAVE_START;
export const CATEGORY_SAVE_DONE = "CATEGORY_SAVE_DONE";
type CATEGORY_SAVE_DONE_TYPE = typeof CATEGORY_SAVE_DONE;
export const CATEGORY_SAVE_FAILED = "CATEGORY_SAVE_FAILED";
type CATEGORY_SAVE_FAILED_TYPE = typeof CATEGORY_SAVE_FAILED;

interface CategoryaveStart {
  type: CATEGORY_SAVE_START_TYPE;
}

interface CategoryaveDone {
  type: CATEGORY_SAVE_DONE_TYPE;
  payload: Category;
}

interface CategoryaveFailed {
  type: CATEGORY_SAVE_FAILED_TYPE;
  payload: object;
}

export type CategorySaveAction =
  | CategoryaveStart
  | CategoryaveDone
  | CategoryaveFailed;

// Category delete
export const CATEGORY_DELETE_START = "CATEGORY_DELETE_START";
type CATEGORY_DELETE_START_TYPE = typeof CATEGORY_DELETE_START;
export const CATEGORY_DELETE_DONE = "CATEGORY_DELETE_DONE";
type CATEGORY_DELETE_DONE_TYPE = typeof CATEGORY_DELETE_DONE;

interface CategoryDeleteStart {
  type: CATEGORY_DELETE_START_TYPE;
  payload: { id: string };
}

interface CategoryDeleteDone {
  type: CATEGORY_DELETE_DONE_TYPE;
  payload: { id: string };
}

export type CategoryDeleteAction = CategoryDeleteStart | CategoryDeleteDone;

// Resources list
export const RESOURCES_LIST_LOAD_START = "RESOURCES_LIST_LOAD_START";
export const RESOURCES_LIST_LOAD_DONE = "RESOURCES_LIST_LOAD_DONE";

type RESOURCES_LIST_LOAD_START_TYPE = typeof RESOURCES_LIST_LOAD_START;
type RESOURCES_LIST_LOAD_DONE_TYPE = typeof RESOURCES_LIST_LOAD_DONE;

interface ResourcesListLoadStart {
  type: RESOURCES_LIST_LOAD_START_TYPE;
}

interface ResourcesListLoadDone {
  type: RESOURCES_LIST_LOAD_DONE_TYPE;
  payload: PaginatedResponse<Resources>;
}

export type ResourcesListAction =
  | ResourcesListLoadStart
  | ResourcesListLoadDone;

// Resources
export const RESOURCES_LOAD_START = "RESOURCES_LOAD_START";
export const RESOURCES_LOAD_DONE = "RESOURCES_LOAD_DONE";

type RESOURCES_LOAD_START_TYPE = typeof RESOURCES_LOAD_START;
type RESOURCES_LOAD_DONE_TYPE = typeof RESOURCES_LOAD_DONE;

interface ResourcesLoadStart {
  type: RESOURCES_LOAD_START_TYPE;
}

interface ResourcesLoadDone {
  type: RESOURCES_LOAD_DONE_TYPE;
  payload: Resources;
  editing: boolean;
}

export type ResourcesAction = ResourcesLoadStart | ResourcesLoadDone;

// Resources open
export const RESOURCES_OPEN = "RESOURCES_OPEN";
type RESOURCES_OPEN_TYPE = typeof RESOURCES_OPEN;

export interface ResourcesOpen {
  type: RESOURCES_OPEN_TYPE;
  payload: Resources;
  editing: boolean;
}

// Resources edit
export const RESOURCES_EDIT = "RESOURCES_EDIT";
type RESOURCES_EDIT_TYPE = typeof RESOURCES_EDIT;

export interface ResourcesEdit {
  type: RESOURCES_EDIT_TYPE;
  payload: boolean;
}

// Resources save
export const RESOURCES_SAVE_START = "RESOURCES_SAVE_START";
type RESOURCES_SAVE_START_TYPE = typeof RESOURCES_SAVE_START;
export const RESOURCES_SAVE_DONE = "RESOURCES_SAVE_DONE";
type RESOURCES_SAVE_DONE_TYPE = typeof RESOURCES_SAVE_DONE;
export const RESOURCES_SAVE_FAILED = "RESOURCES_SAVE_FAILED";
type RESOURCES_SAVE_FAILED_TYPE = typeof RESOURCES_SAVE_FAILED;

interface ResourcesaveStart {
  type: RESOURCES_SAVE_START_TYPE;
}

interface ResourcesaveDone {
  type: RESOURCES_SAVE_DONE_TYPE;
  payload: Resources;
}

interface ResourcesaveFailed {
  type: RESOURCES_SAVE_FAILED_TYPE;
  payload: object;
}

export type ResourcesSaveAction =
  | ResourcesaveStart
  | ResourcesaveDone
  | ResourcesaveFailed;

// Resources save
export const RESOURCES_SAVE_RELATION_START = "RESOURCES_SAVE_RELATION_START";
type RESOURCES_SAVE_RELATION_START_TYPE = typeof RESOURCES_SAVE_RELATION_START;
export const RESOURCES_SAVE_RELATION_DONE = "RESOURCES_SAVE_RELATION_DONE";
type RESOURCES_SAVE_RELATION_DONE_TYPE = typeof RESOURCES_SAVE_RELATION_DONE;
export const RESOURCES_SAVE_RELATION_FAILED = "RESOURCES_SAVE_RELATION_FAILED";
type RESOURCES_SAVE_RELATION_FAILED_TYPE = typeof RESOURCES_SAVE_RELATION_FAILED;

interface ResourcesaveSaveRelationStart {
  type: RESOURCES_SAVE_RELATION_START_TYPE;
}

interface ResourceSaveRelationDone {
  type: RESOURCES_SAVE_RELATION_DONE_TYPE;
  payload: Resources;
}

interface ResourceSaveRelationFailed {
  type: RESOURCES_SAVE_RELATION_FAILED_TYPE;
  payload: object;
}

export type ResourcesSaveRelationAction =
  | ResourcesaveSaveRelationStart
  | ResourceSaveRelationDone
  | ResourceSaveRelationFailed;

// Resources delete
export const RESOURCES_DELETE_START = "RESOURCES_DELETE_START";
type RESOURCES_DELETE_START_TYPE = typeof RESOURCES_DELETE_START;
export const RESOURCES_DELETE_DONE = "RESOURCES_DELETE_DONE";
type RESOURCES_DELETE_DONE_TYPE = typeof RESOURCES_DELETE_DONE;

interface ResourcesDeleteStart {
  type: RESOURCES_DELETE_START_TYPE;
  payload: { id: string };
}

interface ResourcesDeleteDone {
  type: RESOURCES_DELETE_DONE_TYPE;
  payload: { id: string };
}

export type ResourcesDeleteAction = ResourcesDeleteStart | ResourcesDeleteDone;

// Content list
export const CONTENT_LIST_LOAD_START = "CONTENT_LIST_LOAD_START";
export const CONTENT_LIST_LOAD_DONE = "CONTENT_LIST_LOAD_DONE";

type CONTENT_LIST_LOAD_START_TYPE = typeof CONTENT_LIST_LOAD_START;
type CONTENT_LIST_LOAD_DONE_TYPE = typeof CONTENT_LIST_LOAD_DONE;

interface ContentListLoadStart {
  type: CONTENT_LIST_LOAD_START_TYPE;
}

interface ContentListLoadDone {
  type: CONTENT_LIST_LOAD_DONE_TYPE;
  payload: PaginatedResponse<Content>;
}

export type ContentListAction = ContentListLoadStart | ContentListLoadDone;

// Content - ContainerOption list
export const CONTENT_CONTAINER_OPTION_LIST_LOAD_START =
  "CONTENT_CONTAINER_OPTION_LIST_LOAD_START";
export const CONTENT_CONTAINER_OPTION_LIST_LOAD_DONE =
  "CONTENT_CONTAINER_OPTION_LIST_LOAD_DONE";

type CONTENT_CONTAINER_OPTION_LIST_LOAD_START_TYPE = typeof CONTENT_CONTAINER_OPTION_LIST_LOAD_START;
type CONTENT_CONTAINER_OPTION_LIST_LOAD_DONE_TYPE = typeof CONTENT_CONTAINER_OPTION_LIST_LOAD_DONE;

interface ContentContainerOptionListLoadStart {
  type: CONTENT_CONTAINER_OPTION_LIST_LOAD_START_TYPE;
}

interface ContentContainerOptionListLoadDone {
  type: CONTENT_CONTAINER_OPTION_LIST_LOAD_DONE_TYPE;
  payload: OptionResponse[];
}

export type ContentContainerOptionListAction =
  | ContentContainerOptionListLoadStart
  | ContentContainerOptionListLoadDone;

// Content open
export const CONTENT_OPEN = "CONTENT_OPEN";
type CONTENT_OPEN_TYPE = typeof CONTENT_OPEN;

export interface ContentOpen {
  type: CONTENT_OPEN_TYPE;
  payload: Content;
  editing: boolean;
}

// Content edit
export const CONTENT_EDIT = "CONTENT_EDIT";
type CONTENT_EDIT_TYPE = typeof CONTENT_EDIT;

export interface ContentEdit {
  type: CONTENT_EDIT_TYPE;
  payload: boolean;
}

// Content save
export const CONTENT_SAVE_START = "CONTENT_SAVE_START";
type CONTENT_SAVE_START_TYPE = typeof CONTENT_SAVE_START;
export const CONTENT_SAVE_DONE = "CONTENT_SAVE_DONE";
type CONTENT_SAVE_DONE_TYPE = typeof CONTENT_SAVE_DONE;
export const CONTENT_SAVE_FAILED = "CONTENT_SAVE_FAILED";
type CONTENT_SAVE_FAILED_TYPE = typeof CONTENT_SAVE_FAILED;

interface ContentaveStart {
  type: CONTENT_SAVE_START_TYPE;
}

interface ContentaveDone {
  type: CONTENT_SAVE_DONE_TYPE;
  payload: Content;
}

interface ContentaveFailed {
  type: CONTENT_SAVE_FAILED_TYPE;
  payload: object;
}

export type ContentSaveAction =
  | ContentaveStart
  | ContentaveDone
  | ContentaveFailed;

// Content delete
export const CONTENT_DELETE_START = "CONTENT_DELETE_START";
type CONTENT_DELETE_START_TYPE = typeof CONTENT_DELETE_START;
export const CONTENT_DELETE_DONE = "CONTENT_DELETE_DONE";
type CONTENT_DELETE_DONE_TYPE = typeof CONTENT_DELETE_DONE;

interface ContentDeleteStart {
  type: CONTENT_DELETE_START_TYPE;
  payload: { id: string };
}

interface ContentDeleteDone {
  type: CONTENT_DELETE_DONE_TYPE;
  payload: { id: string };
}

export type ContentDeleteAction = ContentDeleteStart | ContentDeleteDone;
export type ContentListOptionAction =
  | ContentListLoadOptionStart
  | ContentListLoadOptionDone;
//ContentOption
export const CONTENT_LIST_OPTION_LOAD_START = "CONTENT_LIST_OPTION_LOAD_START";
export const CONTENT_LIST_OPTION_LOAD_DONE = "CONTENT_LIST_OPTION_LOAD_DONE";

type CONTENT_LIST_LOAD_OPTION_START_TYPE = typeof CONTENT_LIST_OPTION_LOAD_START;
type CONTENT_LIST_LOAD_OPTION_DONE_TYPE = typeof CONTENT_LIST_OPTION_LOAD_DONE;

interface ContentListLoadOptionStart {
  type: CONTENT_LIST_LOAD_OPTION_START_TYPE;
}

interface ContentListLoadOptionDone {
  type: CONTENT_LIST_LOAD_OPTION_DONE_TYPE;
  payload: OptionResponse[];
}

// Container list
export const CONTAINER_LIST_LOAD_START = "CONTAINER_LIST_LOAD_START";
export const CONTAINER_LIST_LOAD_DONE = "CONTAINER_LIST_LOAD_DONE";

type CONTAINER_LIST_LOAD_START_TYPE = typeof CONTAINER_LIST_LOAD_START;
type CONTAINER_LIST_LOAD_DONE_TYPE = typeof CONTAINER_LIST_LOAD_DONE;

interface ContainerListLoadStart {
  type: CONTAINER_LIST_LOAD_START_TYPE;
}

interface ContainerListLoadDone {
  type: CONTAINER_LIST_LOAD_DONE_TYPE;
  payload: PaginatedResponse<Container>;
}

export type ContainerListAction =
  | ContainerListLoadStart
  | ContainerListLoadDone;
export type ContainerListOptionAction =
  | ContainerListLoadOptionStart
  | ContainerListLoadOptionDone;
//ContainerOption
export const CONTAINER_LIST_OPTION_LOAD_START =
  "CONTAINER_LIST_OPTION_LOAD_START";
export const CONTAINER_LIST_OPTION_LOAD_DONE =
  "CONTAINER_LIST_OPTION_LOAD_DONE";

type CONTAINER_LIST_LOAD_OPTION_START_TYPE = typeof CONTAINER_LIST_OPTION_LOAD_START;
type CONTAINER_LIST_LOAD_OPTION_DONE_TYPE = typeof CONTAINER_LIST_OPTION_LOAD_DONE;

interface ContainerListLoadOptionStart {
  type: CONTAINER_LIST_LOAD_OPTION_START_TYPE;
}

interface ContainerListLoadOptionDone {
  type: CONTAINER_LIST_LOAD_OPTION_DONE_TYPE;
  payload: OptionResponse[];
}

// Container open
export const CONTAINER_OPEN = "CONTAINER_OPEN";
type CONTAINER_OPEN_TYPE = typeof CONTAINER_OPEN;

export interface ContainerOpen {
  type: CONTAINER_OPEN_TYPE;
  payload: Container;
  editing: boolean;
}

// Container edit
export const CONTAINER_EDIT = "CONTAINER_EDIT";
type CONTAINER_EDIT_TYPE = typeof CONTAINER_EDIT;

export interface ContainerEdit {
  type: CONTAINER_EDIT_TYPE;
  payload: boolean;
}

// Container save
export const CONTAINER_SAVE_START = "CONTAINER_SAVE_START";
type CONTAINER_SAVE_START_TYPE = typeof CONTAINER_SAVE_START;
export const CONTAINER_SAVE_DONE = "CONTAINER_SAVE_DONE";
type CONTAINER_SAVE_DONE_TYPE = typeof CONTAINER_SAVE_DONE;
export const CONTAINER_SAVE_FAILED = "CONTAINER_SAVE_FAILED";
type CONTAINER_SAVE_FAILED_TYPE = typeof CONTAINER_SAVE_FAILED;

interface ContaineraveStart {
  type: CONTAINER_SAVE_START_TYPE;
}

interface ContaineraveDone {
  type: CONTAINER_SAVE_DONE_TYPE;
  payload: Container;
}

interface ContaineraveFailed {
  type: CONTAINER_SAVE_FAILED_TYPE;
  payload: object;
}

export type ContainerSaveAction =
  | ContaineraveStart
  | ContaineraveDone
  | ContaineraveFailed;

// Container save RELATION
export const CONTAINER_SAVE_RELATION_START = "CONTAINER_SAVE_RELATION_START";
type CONTAINER_SAVE_RELATION_START_TYPE = typeof CONTAINER_SAVE_RELATION_START;
export const CONTAINER_SAVE_RELATION_DONE = "CONTAINER_SAVE_RELATION_DONE";
type CONTAINER_SAVE_RELATION_DONE_TYPE = typeof CONTAINER_SAVE_RELATION_DONE;
export const CONTAINER_SAVE_RELATION_FAILED = "CONTAINER_SAVE_RELATION_FAILED";
type CONTAINER_SAVE_RELATION_FAILED_TYPE = typeof CONTAINER_SAVE_RELATION_FAILED;

interface ContaineraveSaveRelationStart {
  type: CONTAINER_SAVE_RELATION_START_TYPE;
}

interface ContaineraveSaveRelationDone {
  type: CONTAINER_SAVE_RELATION_DONE_TYPE;
  payload: Container;
}

interface ContaineraveSaveRelationFailed {
  type: CONTAINER_SAVE_RELATION_FAILED_TYPE;
  payload: object;
}

export type ContainerSaveRelationAction =
  | ContaineraveSaveRelationStart
  | ContaineraveSaveRelationDone
  | ContaineraveSaveRelationFailed;

// Container delete
export const CONTAINER_DELETE_START = "CONTAINER_DELETE_START";
type CONTAINER_DELETE_START_TYPE = typeof CONTAINER_DELETE_START;
export const CONTAINER_DELETE_DONE = "CONTAINER_DELETE_DONE";
type CONTAINER_DELETE_DONE_TYPE = typeof CONTAINER_DELETE_DONE;

interface ContainerDeleteStart {
  type: CONTAINER_DELETE_START_TYPE;
  payload: { id: string };
}

interface ContainerDeleteDone {
  type: CONTAINER_DELETE_DONE_TYPE;
  payload: { id: string };
}

export type ContainerDeleteAction = ContainerDeleteStart | ContainerDeleteDone;

// TrackPreview

export const TRACKPREVIEW_LIST_LOAD_START = "TRACKPREVIEW_LIST_LOAD_START";
export const TRACKPREVIEW_LIST_LOAD_DONE = "TRACKPREVIEW_LIST_LOAD_DONE";

type TRACKPREVIEW_LIST_LOAD_START_TYPE = typeof TRACKPREVIEW_LIST_LOAD_START;
type TRACKPREVIEW_LIST_LOAD_DONET_TYPE = typeof TRACKPREVIEW_LIST_LOAD_DONE;

interface TrackPreviewLoadStart {
  type: TRACKPREVIEW_LIST_LOAD_START_TYPE;
}

interface TrackPreviewLoadDone {
  type: TRACKPREVIEW_LIST_LOAD_DONET_TYPE;
  payload: PaginatedResponse<TrackPreview>;
}

export type TrackPreviewListAction =
  | TrackPreviewLoadStart
  | TrackPreviewLoadDone;

// TrackPreview Save

export const TRACK_PREVIEW_SAVE_START = "TRACK_PREVIEW_SAVE_START";
type TRACK_PREVIEW_SAVE_START_TYPE = typeof TRACK_PREVIEW_SAVE_START;

export const TRACK_PREVIEW_SAVE_DONE = "TRACK_PREVIEW_SAVE_DONE";
type TRACK_PREVIEW_SAVE_DONE_TYPE = typeof TRACK_PREVIEW_SAVE_DONE;

interface TrackPreviewSaveStart {
  type: TRACK_PREVIEW_SAVE_START_TYPE;
}

interface TrackPreviewSaveDone {
  type: TRACK_PREVIEW_SAVE_DONE_TYPE;
  payload: TrackPreview;
}

export type TrackPreviewSaveAction =
  | TrackPreviewSaveStart
  | TrackPreviewSaveDone;

// TrackPreview Delete
export const TRACK_PREVIEW_DELETE_START = "TRACK_PREVIEW_DELETE_START";
type TRACK_PREVIEW_DELETE_START_TYPE = typeof TRACK_PREVIEW_DELETE_START;

export const TRACK_PREVIEW_DELETE_DONE = "TRACK_PREVIEW_DELETE_DONE";
type TRACK_PREVIEW_DELETE_DONE_TYPE = typeof TRACK_PREVIEW_DELETE_DONE;

interface TrackPreviewDeleteStart {
  type: TRACK_PREVIEW_DELETE_START_TYPE;
  payload: { model: TrackPreview };
}

interface TrackPreviewDeleteDone {
  type: TRACK_PREVIEW_DELETE_DONE_TYPE;
  payload: { model: TrackPreview };
}

export type TrackPreviewDeleteAction =
  | TrackPreviewDeleteStart
  | TrackPreviewDeleteDone;
