import React, { Component } from "react";
import Select from "react-select";
import { Form, ButtonGroup, Button, Card, Col } from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";

import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import FormInputErrors, { hasErrors } from "../lib/FormInputErrors";
import DeleteButtonClickConfirm from "../lib/DeleteButtonClickConfirm";

import Content from "../../models/Content";
import OptionResponse from "../../models/OptionResponse";
import imgplaceholder from "../layout/img/upload_image_placeholder.png";
import audioplaceholder from "../layout/img/upload_audio_placeholder.png";
import audioloadedplaceholder from "../layout/img/upload_audio_loaded_placeholder.png";

export interface DataProps {
  content: Content;
  peopleOption: OptionResponse[];
  sending: boolean;
  errors: Errors;
}

interface Props extends DataProps {
  save: (content: Content) => void;
  cancelEdit: () => void;
  deleteContent: (id: string) => void;
  loadPeopleOption: () => void;
}

interface Errors {
  id?: string[];
  title?: string[];
}

interface State {
  contentEditing: Content;
}

export default class ContentForm extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = { contentEditing: cloneDeep(this.props.content) };

    this.save = this.save.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  save() {
    console.log("QUI IN SAVE");
    console.log(this.state.contentEditing);
    this.props.save(this.state.contentEditing);
  }

  componentDidMount() {
    this.props.loadPeopleOption();
  }

  componentWillUnmount() {
    this.props.cancelEdit();
  }

  setValue(
    attribute: string,
    { target: { value } }: { target: { value: string } }
  ) {
    const contentEditing = cloneDeep(this.state.contentEditing);

    contentEditing[attribute] = value;
    this.setState({ contentEditing });
  }
  setStringValue(attribute: string, options: string | undefined) {
    const contentEditing = cloneDeep(this.state.contentEditing);

    contentEditing[attribute] = options;
    console.log("QUI UPDATE STRING");
    console.log(contentEditing);
    this.setState({ contentEditing });
  }
  async setValueFile(attribute: string, value?: FileList | null) {
    const contentEditing = cloneDeep(this.state.contentEditing);
    var reader = new FileReader();
    if (value == null) {
      contentEditing[attribute] = "";
      this.setState({ contentEditing });
    } else {
      console.log("FILE OK READING");
      reader.readAsDataURL(value?.item(0) || new File([], ""));
      var parentThis = this;
      reader.onload = function() {
        console.log("FILE OK... READ DATA URL");
        console.log(value?.item(0));
        contentEditing[attribute] = reader.result?.toString() || "";

        //console.log(contentEditing)
        parentThis.setState({ contentEditing });
      };
      reader.onloadend = () => {
        if (value?.item(0) !== null) {
          // this.setStringValue("track_filename", value?.item(0)!.name);
          this.setStringValue("track_filename", value?.item(0)?.name);
        } else {
          this.setStringValue("track_filename", "NULL");
        }
      };
      reader.onerror = function(error) {
        alert("Error: " + error);
      };
      //this.props.save(this.state.resourcesEditing)
    }
  }

  updateFileInfo(event: React.ChangeEvent<HTMLInputElement>) {
    this.setValueFile("file_track", event.currentTarget.files);
    this.setStringValue("track_filename", event.currentTarget.files?.[0].name);
  }

  render() {
    const {
      sending,
      errors,
      content: { id },
      deleteContent,
    } = this.props;
    const { contentEditing } = this.state;
    const isNewRecord = !id;

    let narratorOptionSelected = null;
    console.log(contentEditing.narrator_id);
    console.log(this.props.peopleOption);
    if (
      contentEditing.narrator_id != null &&
      this.props.peopleOption.find(
        (f) => f.value == contentEditing.narrator_id
      ) != undefined
    ) {
      console.log("AUTORE");
      narratorOptionSelected = this.props.peopleOption.find(
        (f) => f.value == contentEditing.narrator_id
      );
    }

    console.log(narratorOptionSelected);
    console.log("TITOLO");
    console.log(contentEditing.audio_book?.title);

    console.log("CONTENT EDIT");
    console.log(contentEditing);
    var url = "url(".concat(audioloadedplaceholder || "").concat(")");
    return (
      <Paper className="custom-paper">
        <Typography variant="h5" component="h3">
          <b>
            "{contentEditing.audio_book?.title}" - Aggiungi/Modifica capitolo
          </b>
        </Typography>
        <div className="separator separator-solid my-7"></div>
        <Typography component="div">
          <Form>
            <Form.Row className="mt-4 ">
              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label className="text-dark-75 font-weight-bolder">
                    Streaming completato
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label>
                    {(contentEditing.streaming_completed ? "Sì" : "No") || "No"}
                  </Form.Label>
                  {/*
                  <Form.Control
                    isInvalid={hasErrors(errors, 'streaming_completed')}
                    className="custom-input"
                    disabled
                    type="text" value={(contentEditing.streaming_completed? "Sì":"No") || 'No'}
                    onChange={event => this.setValue('streaming_completed', event)} />
                  {/*<FormInputErrors elementId={`container-${id}-streaming_completed-field`} errors={errors.streaming_completed} />
                  */}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="mt-4 ">
              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label className="text-dark-75 font-weight-bolder">
                    Ultimo aggiornamento
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label>
                    {contentEditing.updated_at || "gg/mm/aaaa hh:mm"}
                  </Form.Label>
                  {/*<Form.Control
                    placeholder="gg/mm/aaaa hh:mm"
                    isInvalid={hasErrors(errors, 'updated_at')}
                    className="custom-input"
                    disabled
                    type="text" value={contentEditing.updated_at || ''}
                    onChange={event => this.setValue('updated_at', event)} />
                  */}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="mt-4 ">
              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label className="text-dark-75 font-weight-bolder">
                    Nome traccia
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col xs md lg="4">
                <Form.Group>
                  <Form.Control
                    placeholder="Nome traccia"
                    isInvalid={hasErrors(errors, "title")}
                    className="custom-input"
                    type="text"
                    value={contentEditing.title || ""}
                    onChange={(event) => this.setValue("title", event)}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="mt-4 ">
              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label className="text-dark-75 font-weight-bolder">
                    Id traccia
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col xs md lg="4">
                <Form.Group>
                  <Form.Control
                    placeholder="Id traccia"
                    isInvalid={hasErrors(errors, "track_id")}
                    className="custom-input"
                    type="text"
                    value={contentEditing.track_id || ""}
                    onChange={(event) => this.setValue("track_id", event)}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="mt-4 ">
              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label className="text-dark-75 font-weight-bolder">
                    Carica file audio
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col xs md lg="4">
                <Form.Group>
                  <div
                    className="image-input image-input-outline"
                    id="kt_image_1"
                  >
                    <div className="image-input-wrapper">
                      <img
                        className="placeholder-image"
                        hidden={
                          contentEditing.file_track != null &&
                          contentEditing.file_track != ""
                        }
                        src={audioplaceholder}
                      />
                      <img
                        className="placeholder-image"
                        hidden={
                          contentEditing.file_track == null ||
                          contentEditing.file_track == ""
                        }
                        src={audioloadedplaceholder}
                      />
                    </div>

                    <label
                      className="custom-image-edit-icon-btn btn btn-xs btn-icon  btn-white btn-hover-text-primary btn-shadow"
                      data-action="change"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Change avatar"
                    >
                      <i className="fa fa-pen icon-sm text-muted"></i>
                      <Form.Control
                        isInvalid={hasErrors(errors, "file_track")}
                        disabled={sending}
                        type="file"
                        accept=".mp3"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => this.updateFileInfo(event)}
                      />
                      <input type="hidden" name="profile_avatar_remove" />
                    </label>

                    <Button
                      className="custom-image-edit-icon-btn btn btn-xs btn-icon btn-white btn-hover-text-primary btn-shadow"
                      variant=""
                      data-action="remove"
                      disabled={sending}
                      hidden={
                        contentEditing.file_track == null ||
                        contentEditing.file_track == ""
                      }
                      data-toggle="tooltip"
                      title="Cancel avatar"
                      onClick={() => this.setValueFile("file_track", null)}
                    >
                      <i className="ki ki-bold-close icon-xs text-muted"></i>
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="mt-4 ">
              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label className="text-dark-75 font-weight-bolder">
                    ISBN
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col xs md lg="4">
                <Form.Group>
                  <Form.Control
                    placeholder="ISBN di riferimento"
                    isInvalid={hasErrors(errors, "isbn")}
                    className="custom-input"
                    type="text"
                    value={contentEditing.isbn || ""}
                    onChange={(event) => this.setValue("isbn", event)}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="mt-4 ">
              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label className="text-dark-75 font-weight-bolder">
                    Dimensioni
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label>
                    {`${contentEditing.track_duration} minuti` || "hh:mm"}
                  </Form.Label>
                  {/*
                  <Form.Control
                    placeholder="hh:mm"
                    isInvalid={hasErrors(errors, 'track_duration')}
                    className="custom-input"
                    type="text" value={contentEditing.track_duration || ''}
                    onChange={event => this.setValue('track_duration', event)} />
                  */}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="mt-4 ">
              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label className="text-dark-75 font-weight-bolder">
                    Autore
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col xs md lg="4">
                <Select
                  className="custom-input"
                  options={this.props.peopleOption}
                  value={narratorOptionSelected}
                  placeholder="Seleziona autore"
                  onChange={(selectedOption) => {
                    this.setStringValue("narrator_id", selectedOption?.value);
                  }}
                />
              </Col>
            </Form.Row>
            <Form.Row className="mt-4 ">
              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label className="text-dark-75 font-weight-bolder">
                    Numero tracce
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label>
                    {contentEditing.track_number || "Nr. tracce"}
                  </Form.Label>
                  {/*
                  <Form.Control
                    placeholder="Nr. tracce"
                    isInvalid={hasErrors(errors, 'track_number')}
                    className="custom-input"
                    type="text" value={contentEditing.track_number || ''}
                    onChange={event => this.setValue('track_number', event)} />
                  */}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="mt-4 ">
              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label className="text-dark-75 font-weight-bolder">
                    Nome file
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col xs md lg="4">
                <Form.Group>
                  <Form.Control
                    placeholder="Example"
                    isInvalid={hasErrors(errors, "track_filename")}
                    className="custom-input"
                    type="text"
                    value={contentEditing.track_filename || ""}
                    onChange={(event) => this.setValue("track_filename", event)}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row className="mt-4 ">
              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label className="text-dark-75 font-weight-bolder">
                    Peso traccia
                  </Form.Label>
                </Form.Group>
              </Col>

              <Col xs md lg="4">
                <Form.Group>
                  <Form.Label>
                    {`${contentEditing.track_weight} Mb` || "Peso Tot. (Mb)"}
                  </Form.Label>
                  {/*
                  <Form.Control
                    placeholder="Peso Tot. (Mb)"
                    isInvalid={hasErrors(errors, 'track_weight')}
                    className="custom-input"
                    disabled
                    type="text" value={contentEditing.track_weight || ''}
                    onChange={event => this.setValue('track_weight', event)} />
                */}
                </Form.Group>
              </Col>
            </Form.Row>

            <div className="separator separator-solid my-7"></div>

            <Form.Row className="mt-4 justify-content-between">
              {/*
                !isNewRecord ?
                  <DeleteButtonClickConfirm
                    label="Delete content" labelConfirm="Click to confirm" size="sm"
                    action={() => deleteContent(id)}
                    disabled={sending} />
                  :
                  <div />
              */}
              <div />
              <div>
                <Button
                  onClick={this.props.cancelEdit}
                  disabled={sending}
                  size="sm"
                  variant="link"
                  className="marginButton"
                >
                  Annulla
                </Button>
                <Button
                  onClick={this.save}
                  disabled={sending}
                  size="sm"
                  variant="primary"
                >
                  Salva modifiche
                </Button>
              </div>
            </Form.Row>
          </Form>
        </Typography>
      </Paper>
    );
  }
}
