import React, { Component } from "react";
import { Button } from "react-bootstrap";

interface Props {
  label: any;
  labelConfirm: any;
  size: "sm" | "lg" | undefined;
  disabled?: boolean;
  action: () => void;
}

interface State {
  activated: boolean;
}

export default class DeleteButtonClickConfirm extends Component<Props, State> {
  timeoutId?: number;

  constructor(props: Props, state: State) {
    super(props, state);

    this.state = { activated: false };

    this.setActive = this.setActive.bind(this);
    this.setInactive = this.setInactive.bind(this);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
  }

  setActive() {
    this.setState({ activated: true });
    this.timeoutId = window.setTimeout(this.setInactive, 2000);
  }

  setInactive() {
    this.setState({ activated: false });
  }

  render() {
    const { label, labelConfirm, action, size, disabled } = this.props;
    const { activated } = this.state;

    if (activated) {
      return (
        <Button
          variant="danger"
          size={size}
          disabled={disabled}
          onClick={action}
        >
          {labelConfirm}
        </Button>
      );
    } else {
      return (
        <Button
          variant="warning"
          size={size}
          disabled={disabled}
          onClick={this.setActive}
        >
          {label}
        </Button>
      );
    }
  }
}
