import { AxiosRequestConfig } from "axios";

import HttpClientService, {
  HttpClientResponse,
} from "./HttpClientServiceLaravel";
import Asset from "../models/Asset";

export default class ExcelService extends HttpClientService {
  async upload(file: File, lineNumber?: string): Promise<any> {
    let par = lineNumber != undefined ? "/" + lineNumber : "";

    return new Promise((resolve, reject) => {
      var reader: FileReader = new FileReader();
      var excelService: ExcelService = this;
      reader.onloadend = async function(event) {
        // The file's text will be printed here
        let string: string;
        //console.log(event.target?.result)
        string = event.target?.result?.toString() || "";

        const response: HttpClientResponse<any> = await excelService.doRequest({
          method: "POST",
          url: "/api/import" + par,
          data: { file: string },
        });

        resolve(response.data);
      };
      let readedFile = reader.readAsDataURL(file);
    });

    //let readedFile = reader.readAsDataURL(file);
    //return
  }

  async donwload(): Promise<any> {
    var baseURL = process.env.REACT_APP_API_LARAVEL_URL;
    let url = baseURL + "/api/export";
    var win = window.open(url, "_blank");
    win!.focus();
  }
  /*
  async downloadas(): Promise<any> {
    
    const response: HttpClientResponse<any> = await this.doRequest({
      method: 'GET',
      url: '/api/export',
      responseType: "blob",
    })
    return response.data
  }
}*/
}

export const service = new ExcelService();
