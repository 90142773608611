import React from "react";
import { Button, Table } from "react-bootstrap";
import join from "lodash/join";

import PublisherForm from "../../containers/publisher/PublisherForm";
import Publisher from "../../models/Publisher";

interface Props {
  publisher?: Publisher;
  editing: boolean;

  edit: () => void;
}

export default function PublisherDetails(props: Props) {
  if (!props.publisher) {
    return <p className="font-italic">Select a publisher from the list</p>;
  }
  console.log(props);
  const { publisher } = props;

  return (
    <>
      <div className="mt-4 mb-4">{<PublisherForm />}</div>
    </>
  );
}

function DetailsTable({ publisher }: { publisher: Publisher }) {
  return (
    <Table size="sm">
      <tbody>
        <tr>
          <th>ID</th>
          <td>{publisher.id}</td>
        </tr>
        <tr>
          <th>Name</th>
          <td>{publisher.name}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{publisher.description}</td>
        </tr>
      </tbody>
    </Table>
  );
}
