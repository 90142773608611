import React from "react";
import { Button, Table } from "react-bootstrap";
import join from "lodash/join";

import ResourcesForm from "../../containers/resources/ResourcesForm";
import Resources from "../../models/Resources";

interface Props {
  resources?: Resources;
  editing: boolean;

  edit: () => void;
}

export default function ResourcesDetails(props: Props) {
  if (!props.resources) {
    return <p className="font-italic">Select a resources from the list</p>;
  }
  console.log(props);
  const { resources } = props;

  return (
    <>
      <div className="mt-4 mb-4">{<ResourcesForm />}</div>
    </>
  );
}

function DetailsTable({ resources }: { resources: Resources }) {
  return (
    <Table size="sm">
      <tbody>
        <tr>
          <th>ID</th>
          <td>{resources.id}</td>
        </tr>
        <tr>
          <th>Name</th>
          <td>{resources.name}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{resources.description}</td>
        </tr>
      </tbody>
    </Table>
  );
}
