export default interface People extends Record<string, any> {
  id: string;
  first_name: string;
  last_name?: string;
  description: string;
  image_square?: string;
  type: string;
}

export function build() {
  return {
    id: "",
    first_name: "",
    last_name: "",
    description: "",
    image_square: "",
    type: "",
  };
}
