import React from "react";
import { Row, Col, ListGroup, Button, ButtonGroup } from "react-bootstrap";

import Loader from "../Loader";

import Chapter from "../../models/Chapter";

interface Props {
  chapters: Chapter[];
  loading: boolean;
  sending: boolean;

  openChapter: (model: Chapter) => void;
  newChapter: () => void;
  moveChapter: (model: Chapter, offset: number) => void;
}

export default function ChaptersList(props: Props) {
  const { loading, sending, chapters, openChapter, moveChapter } = props;

  if (loading) {
    return <Loader />;
  }

  const newChapterButton = (
    <div className="text-right">
      <Button size="sm" disabled={sending} onClick={props.newChapter}>
        Add new chapter
      </Button>
    </div>
  );

  if (0 === chapters.length) {
    return (
      <>
        <p className="font-italic">No chapter has been added yet</p>
        {newChapterButton}
      </>
    );
  }

  const items = chapters.map((c) => {
    return (
      <ListGroup.Item key={`chapter-entry-${c.id}`}>
        <Row>
          <Col xs={6}>
            <Button
              disabled={sending}
              onClick={() => openChapter(c)}
              variant="link"
            >
              {c.title}
            </Button>
          </Col>
          <Col xs={6} className="text-right">
            <ButtonGroup>
              <Button
                onClick={() => moveChapter(c, -1)}
                disabled={sending || 1 === c.position}
                variant="secondary"
                size="sm"
              >
                <span className="oi oi-arrow-thick-top"></span>
              </Button>
              <Button
                onClick={() => moveChapter(c, 1)}
                disabled={sending || chapters.length === c.position}
                variant="secondary"
                size="sm"
              >
                <span className="oi oi-arrow-thick-bottom"></span>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  });

  return (
    <>
      <ListGroup className="mb-4">{items}</ListGroup>
      {newChapterButton}
    </>
  );
}
