import { AxiosRequestConfig } from "axios";

import HttpClientService, {
  HttpClientResponse,
} from "./HttpClientServiceLaravel";
import PaginatedResponse from "../models/PaginatedResponse";
import Content from "../models/Content";
import OptionResponse from "../models/OptionResponse";

export interface SearchParams {
  page?: number;
  search?: string;
}

export default class ContentService extends HttpClientService {
  async search(params: SearchParams): Promise<PaginatedResponse<Content>> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/v1/content",
      params,
    };

    const response: HttpClientResponse<PaginatedResponse<
      Content
    >> = await this.doRequest(request);
    console.log(response);
    return response.data;
  }

  async save(model: Content): Promise<Content> {
    let request: AxiosRequestConfig;
    const data: any = { ...model, id: undefined };
    if (data.authors) {
      data.overrideAuthors = data.authors.map((a: any) => a.id);
      delete data.authors;
    }
    console.log("MODEEEL");
    console.log(model);
    //return model;

    if (model.id) {
      request = {
        method: "PATCH",
        url: `/api/v1/content/${model.id}`,
        data: { content: data },
      };
    } else {
      request = {
        method: "POST",
        url: "/api/v1/content",
        data: { content: data },
      };
    }

    const response: HttpClientResponse<Content> = await this.doRequest(request);
    return response.data;
  }

  async getContainerOptionList(
    params: SearchParams
  ): Promise<OptionResponse[]> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/v1/containeroption",
      params,
    };

    const response: HttpClientResponse<OptionResponse[]> = await this.doRequest(
      request
    );
    console.log(response);
    return response.data;
  }
  async getOptionList(params: SearchParams): Promise<OptionResponse[]> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/v1/containeroption",
      params,
    };

    const response: HttpClientResponse<OptionResponse[]> = await this.doRequest(
      request
    );
    console.log(response);
    return response.data;
  }

  async deleteModel(id: string): Promise<boolean> {
    const request: AxiosRequestConfig = {
      method: "DELETE",
      url: `/api/v1/content/${id}`,
    };

    await this.doRequest(request);
    return true;
  }
}

export const service = new ContentService();
