import { AxiosRequestConfig } from "axios";

import HttpClientService, { HttpClientResponse } from "./HttpClientService";
import Chapter from "../models/Chapter";

export default class ChapterService extends HttpClientService {
  async list(audioBookId: string): Promise<Chapter[]> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/v1/chapters",
      params: { audioBookId },
    };

    const response: HttpClientResponse<Chapter[]> = await this.doRequest(
      request
    );
    return response.data;
  }

  async getChapter(id: string): Promise<Chapter> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: `/v1/chapters/${id}`,
    };

    const response: HttpClientResponse<Chapter> = await this.doRequest(request);
    return response.data;
  }

  async save(model: Chapter): Promise<Chapter> {
    let request: AxiosRequestConfig;
    const data = { ...model, id: undefined, assets: undefined };

    if (model.id) {
      request = {
        method: "PATCH",
        url: `/v1/chapters/${model.id}`,
        data,
      };
    } else {
      request = {
        method: "POST",
        url: "/v1/chapters",
        data: { ...data, position: undefined },
      };
    }
    const response: HttpClientResponse<Chapter> = await this.doRequest(request);
    return response.data;
  }

  async deleteModel(id: string): Promise<boolean> {
    const request: AxiosRequestConfig = {
      method: "DELETE",
      url: `/v1/chapters/${id}`,
    };

    await this.doRequest(request);
    return true;
  }
}

export const service = new ChapterService();
