import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { PublisherPage } from "./pages/PublisherPage";
import { PeoplePage } from "./pages/PeoplePage";
import { CategoryPage } from "./pages/CategoryPage";
import { ResourcesPage } from "./pages/ResourcesPage";
import { ContentPage } from "./pages/ContentPage";
import { ContainerPage } from "./pages/ContainerPage";
import '../admin-ui/components/layout/css/index';

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/publisher" component={PublisherPage} />
        <ContentRoute path="/people" component={PeoplePage} />
        <ContentRoute path="/category" component={CategoryPage} />
        <ContentRoute path="/resources" component={ResourcesPage} />
        <ContentRoute path="/content" component={ContentPage} />
        <ContentRoute path="/container" component={ContainerPage} />
        
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
