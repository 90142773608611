import {
  PUBLISHER_LIST_OPTION_LOAD_START,
  PUBLISHER_LIST_OPTION_LOAD_DONE,
  PublisherListOptionAction,

  PUBLISHER_LIST_LOAD_START,
  PUBLISHER_LIST_LOAD_DONE,
  PublisherListAction,

  PUBLISHER_OPEN,
  PublisherOpen,

  PUBLISHER_EDIT,
  PublisherEdit,

  PUBLISHER_SAVE_START,
  PUBLISHER_SAVE_DONE,
  PUBLISHER_SAVE_FAILED,
  PublisherSaveAction,

  PUBLISHER_DELETE_START,
  PUBLISHER_DELETE_DONE,
  PublisherDeleteAction
} from '../actionTypes'
import { service as publisherService, SearchParams } from '../../services/PublisherService'
import PaginatedResponse from '../../models/PaginatedResponse'
import Publisher, { build as buildPublisher } from '../../models/Publisher'
import OptionResponse from '../../models/OptionResponse'

export function loadPublisherList(params?: SearchParams): (dispatch: (action: PublisherListAction) => void) => void {
  console.log("action:loadPublisherList")
  return async dispatch => {
    dispatch({ type: PUBLISHER_LIST_LOAD_START })

    const list: PaginatedResponse<Publisher> = await publisherService.search(params || {})
    console.log(list)
    dispatch({ type: PUBLISHER_LIST_LOAD_DONE, payload: list })
  }
}

export function loadPublisherOption(params?: SearchParams): (dispatch: (action: PublisherListOptionAction) => void) => void {
  console.log("action:loadCategoryOption")
  return async dispatch => {
    dispatch({ type: PUBLISHER_LIST_OPTION_LOAD_START })

    const list: OptionResponse[] = await publisherService.getOptionList(params || {})
    console.log(list)
    dispatch({ type: PUBLISHER_LIST_OPTION_LOAD_DONE, payload: list })
  }
}

export function openPublisher(payload: Publisher): (dispatch: (action: PublisherOpen) => void) => void {
  return async dispatch => {
    dispatch({ type: PUBLISHER_OPEN, payload, editing: true })
  }
}

export function newPublisher(): (dispatch: (action: PublisherOpen | PublisherEdit) => void) => void {
  return async dispatch => {
    const newPublisher: Publisher = buildPublisher()
    dispatch({ type: PUBLISHER_OPEN, payload: newPublisher, editing: true })
    dispatch({ type: PUBLISHER_EDIT, payload: true })
  }
}

export function edit(): (dispatch: (action: PublisherEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: PUBLISHER_EDIT, payload: true })
  }
}

export function cancelEdit(): (dispatch: (action: PublisherEdit) => void) => void {
  return async dispatch => {
    dispatch({ type: PUBLISHER_EDIT, payload: false })
  }
}

export function save(publisher: Publisher): (dispatch: (action: PublisherSaveAction) => void) => void {
  return async dispatch => {
    dispatch({ type: PUBLISHER_SAVE_START })
    try {
      const updatedModel: Publisher = await publisherService.save(publisher)
      dispatch({ type: PUBLISHER_SAVE_DONE, payload: updatedModel })
    } catch (err) {
      //dispatch({ type: PUBLISHER_SAVE_FAILED, payload: err.data })
    }
  }
}

export function deletePublisher(id: string): (dispatch: (action: PublisherDeleteAction) => void) => void {
  return async dispatch => {
    dispatch({ type: PUBLISHER_DELETE_START, payload: { id } })
    try {
      await publisherService.deleteModel(id)
      dispatch({ type: PUBLISHER_DELETE_DONE, payload: { id } })

    } catch (err) { }
  }
}
