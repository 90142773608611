import currentUser from "./currentUserReducer";
import login from "./loginReducer";
import audioBooksList from "./audioBooksListReducer";
import editAudioBook from "./editAudioBookReducer";
import chaptersList from "./chaptersListReducer";
import editChapter from "./editChapterReducer";
import editAuthor from "./editAuthorReducer";
import publisherList from "./publisherListReducer";
import editPublisher from "./editPublisherReducer";
import peopleList from "./peopleListReducer";
import editPeople from "./editPeopleReducer";
import containerList from "./containerListReducer";
import editContainer from "./editContainerReducer";
import contentList from "./contentListReducer";
import editContent from "./editContentReducer";
import categoryList from "./categoryListReducer";
import editCategory from "./editCategoryReducer";
import resourcesList from "./resourcesListReducer";
import editResources from "./editResourcesReducer";
import editDashboard from "./dashboardReducer";
import TrackPreview from "./trackPreviewReducer";

export default {
  currentUser,
  login,
  audioBooksList,
  audioBookEdit: editAudioBook,
  publisherList,
  publisherEdit: editPublisher,
  peopleList,
  peopleEdit: editPeople,
  categoryList,
  categoryEdit: editCategory,
  resourcesList,
  resourcesEdit: editResources,
  contentList,
  contentEdit: editContent,
  containerList,
  containerEdit: editContainer,
  chaptersList,
  chapterEdit: editChapter,
  authorEdit: editAuthor,
  editDashboard,
  trackPreview: TrackPreview,
};
