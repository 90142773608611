import React, { Component } from "react";
import { Form, ButtonGroup, Button, Card } from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";

import FormInputErrors, { hasErrors } from "../lib/FormInputErrors";
import DeleteButtonClickConfirm from "../lib/DeleteButtonClickConfirm";
import AuthorsForm from "../../containers/books/AuthorsForm";

import AudioBook from "../../models/AudioBook";
import Author from "../../models/Author";

export interface DataProps {
  audioBook: AudioBook;
  sending: boolean;
  errors: Errors;
}

interface Props extends DataProps {
  save: (book: AudioBook) => void;
  cancelEdit: () => void;
  deleteAudioBook: (id: string) => void;
}

interface Errors {
  title?: string[];
  productId?: string[];
  kind?: string[];
  isbn?: string[];
  rssFeedLink?: string[];
  summary?: string[];
  category?: string[];
  director?: string[];
  duration?: string[];
  release?: string[];
  translator?: string[];
  bookEdition?: string[];
  publishingDate?: string[];
}

interface State {
  bookEditing: AudioBook;
}

export default class BookForm extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);

    this.state = { bookEditing: cloneDeep(this.props.audioBook) };

    this.save = this.save.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setAuthors = this.setAuthors.bind(this);
  }

  save() {
    this.props.save(this.state.bookEditing);
  }

  setValue(
    attribute: string,
    { target: { value } }: { target: { value: string } }
  ) {
    const bookEditing = cloneDeep(this.state.bookEditing);
    bookEditing[attribute] = value;
    this.setState({ bookEditing });
  }

  setAuthors(authors: Author[]) {
    const bookEditing = cloneDeep(this.state.bookEditing);
    bookEditing.authors = authors;
    this.setState({ bookEditing });
  }

  render() {
    const {
      sending,
      errors,
      audioBook: { id },
      deleteAudioBook,
    } = this.props;
    const { bookEditing } = this.state;
    const isNewRecord = !id;

    return (
      <Form>
        <Form.Group>
          <Form.Label>Type</Form.Label>
          <Form.Control
            as="select"
            isInvalid={hasErrors(errors, "kind")}
            disabled={sending}
            onChange={(event) => this.setValue("kind", event)}
            defaultValue={bookEditing.kind}
            custom
          >
            <option></option>
            <option value="AUDIO_BOOK">Audio book</option>
            <option value="PODCAST">Podcast</option>
            <option value="AUDIO_SERIES">Audio series</option>
          </Form.Control>
          <FormInputErrors
            elementId={`book-${id}-kind-field`}
            errors={errors.kind}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Product ID</Form.Label>
          <Form.Control
            isInvalid={hasErrors(errors, "productId")}
            disabled={false}
            type="text"
            value={bookEditing.productId || ""}
            onChange={(event) => this.setValue("productId", event)}
          />
          <FormInputErrors
            elementId={`book-${id}-productId-field`}
            errors={errors.productId}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control
            isInvalid={hasErrors(errors, "title")}
            disabled={sending}
            type="text"
            value={bookEditing.title || ""}
            onChange={(event) => this.setValue("title", event)}
          />
          <FormInputErrors
            elementId={`book-${id}-title-field`}
            errors={errors.title}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>ISBN</Form.Label>
          <Form.Control
            isInvalid={hasErrors(errors, "isbn")}
            disabled={sending}
            type="text"
            value={bookEditing.isbn || ""}
            onChange={(event) => this.setValue("isbn", event)}
          />
          <FormInputErrors
            elementId={`book-${id}-isbn-field`}
            errors={errors.isbn}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>RSS feed link</Form.Label>
          <Form.Control
            isInvalid={false}
            disabled={sending}
            type="text"
            value={bookEditing.rssFeedLink || ""}
            onChange={(event) => this.setValue("rssFeedLink", event)}
          />
          <FormInputErrors
            elementId={`book-${id}-rssFeedLink-field`}
            errors={errors.rssFeedLink}
          />
        </Form.Group>

        <Form.Group>
          <Card>
            <Card.Body>
              <Form.Label>Authors</Form.Label>
              <AuthorsForm
                selectedAuthors={bookEditing.authors}
                setAuthors={this.setAuthors}
              />
            </Card.Body>
          </Card>
        </Form.Group>

        <Form.Group>
          <Form.Label>Summary</Form.Label>
          <Form.Control
            isInvalid={hasErrors(errors, "summary")}
            disabled={sending}
            as="textarea"
            value={bookEditing.summary || ""}
            onChange={(event) => this.setValue("summary", event)}
          />
          <FormInputErrors
            elementId={`book-${id}-summary-field`}
            errors={errors.summary}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Category</Form.Label>
          <Form.Control
            isInvalid={hasErrors(errors, "category")}
            disabled={sending}
            type="text"
            value={bookEditing.category || ""}
            onChange={(event) => this.setValue("category", event)}
          />
          <FormInputErrors
            elementId={`book-${id}-category-field`}
            errors={errors.category}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Director</Form.Label>
          <Form.Control
            isInvalid={hasErrors(errors, "director")}
            disabled={sending}
            type="text"
            value={bookEditing.director || ""}
            onChange={(event) => this.setValue("director", event)}
          />
          <FormInputErrors
            elementId={`book-${id}-director-field`}
            errors={errors.director}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Duration</Form.Label>
          <Form.Control
            isInvalid={hasErrors(errors, "duration")}
            disabled={sending}
            type="text"
            value={bookEditing.duration || ""}
            onChange={(event) => this.setValue("duration", event)}
          />
          <FormInputErrors
            elementId={`book-${id}-duration-field`}
            errors={errors.duration}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Release</Form.Label>
          <Form.Control
            isInvalid={hasErrors(errors, "release")}
            disabled={sending}
            type="text"
            value={bookEditing.release || ""}
            onChange={(event) => this.setValue("release", event)}
          />
          <FormInputErrors
            elementId={`book-${id}-release-field`}
            errors={errors.release}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Translator</Form.Label>
          <Form.Control
            isInvalid={hasErrors(errors, "translator")}
            disabled={sending}
            type="text"
            value={bookEditing.translator || ""}
            onChange={(event) => this.setValue("translator", event)}
          />
          <FormInputErrors
            elementId={`book-${id}-translator-field`}
            errors={errors.translator}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Edition</Form.Label>
          <Form.Control
            isInvalid={hasErrors(errors, "bookEdition")}
            disabled={sending}
            type="text"
            value={bookEditing.bookEdition || ""}
            onChange={(event) => this.setValue("bookEdition", event)}
          />
          <FormInputErrors
            elementId={`book-${id}-bookEdition-field`}
            errors={errors.bookEdition}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Publishing date</Form.Label>
          <Form.Control
            isInvalid={hasErrors(errors, "publishingDate")}
            disabled={sending}
            type="text"
            value={bookEditing.publishingDate || ""}
            onChange={(event) => this.setValue("publishingDate", event)}
          />
          <FormInputErrors
            elementId={`book-${id}-publishingDate-field`}
            errors={errors.publishingDate}
          />
        </Form.Group>

        <Form.Row className="mt-4 justify-content-between">
          {!isNewRecord ? (
            <DeleteButtonClickConfirm
              label="Delete book"
              labelConfirm="Click to confirm"
              size="sm"
              action={() => deleteAudioBook(id)}
              disabled={sending}
            />
          ) : (
            <div />
          )}

          <ButtonGroup>
            {!isNewRecord && (
              <Button
                onClick={this.props.cancelEdit}
                disabled={sending}
                size="sm"
                variant="secondary"
              >
                Cancel
              </Button>
            )}
            <Button
              onClick={this.save}
              disabled={sending}
              size="sm"
              variant="primary"
            >
              Save
            </Button>
          </ButtonGroup>
        </Form.Row>
      </Form>
    );
  }
}
