import React, { Component } from "react";
import { Form, ButtonGroup, Button, Card, Col } from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";

import FormInputErrors, { hasErrors } from "../lib/FormInputErrors";
import DeleteButtonClickConfirm from "../lib/DeleteButtonClickConfirm";

import Publisher from "../../models/Publisher";
import KTImageInput from "../../../_metronic/_assets/js/components/image-input";
import { DriveEta } from "@material-ui/icons";
import imgplaceholder from "../layout/img/upload_image_placeholder.png";

export interface DataProps {
  publisher: Publisher;
  sending: boolean;
  errors: Errors;
}

interface Props extends DataProps {
  save: (publisher: Publisher) => void;
  cancelEdit: () => void;
  deletePublisher: (id: string) => void;
}

interface Errors {
  id?: string[];
  name?: string[];
  description?: string[];
  logo?: string[];
  link_external?: string[];
}

interface State {
  publisherEditing: Publisher;
}

export default class PublisherForm extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = { publisherEditing: cloneDeep(this.props.publisher) };

    this.save = this.save.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setValueImage = this.setValueImage.bind(this);
  }

  componentWillUnmount() {
    this.props.cancelEdit();
  }

  save() {
    console.log(this.state.publisherEditing);

    this.props.save(this.state.publisherEditing);
  }

  setValue(
    attribute: string,
    { target: { value } }: { target: { value: string } }
  ) {
    const publisherEditing = cloneDeep(this.state.publisherEditing);

    publisherEditing[attribute] = value;
    this.setState({ publisherEditing });
  }
  async setValueImage(attribute: string, value?: FileList | null) {
    console.log(value);
    const publisherEditing = cloneDeep(this.state.publisherEditing);
    var reader = new FileReader();
    if (value == null) {
      publisherEditing[attribute] = "";
      this.setState({ publisherEditing });
    } else {
      reader.readAsDataURL(value?.item(0) || new File([], ""));
      var parentThis = this;
      reader.onload = function() {
        publisherEditing[attribute] = reader.result?.toString() || "";
        parentThis.setState({ publisherEditing });
      };
      reader.onerror = function(error) {
        alert("Error: " + error);
      };
    }
  }

  getBase64Image(id: String) {
    var avatar1 = new KTImageInput(id);
  }

  /*
  <div className="image-input image-input-outline" id="kt_image_1">
            <div className="image-input-wrapper" style={{ background: url}}></div>
  
            <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
              <i className="fa fa-pen icon-sm text-muted"></i>
              <input type="file" name="profile_avatar" accept=".png, .jpg, .jpeg" />
              <input type="hidden" name="profile_avatar_remove"/>
            </label>
  
            <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
              <i className="ki ki-bold-close icon-xs text-muted"></i>
            </span>
          </div>
   */
  render() {
    const {
      sending,
      errors,
      publisher: { id },
      deletePublisher,
    } = this.props;
    const { publisherEditing } = this.state;
    const isNewRecord = !id;
    console.log(publisherEditing);
    this.getBase64Image("kt_image_1");
    var url = "url(".concat(publisherEditing.logo || "").concat(")");
    return (
      <Form>
        <Form.Group>
          <Form.Label></Form.Label>
          <Form.Control
            isInvalid={hasErrors(errors, "id")}
            disabled={true}
            hidden={true}
            type="text"
            value={publisherEditing.id || ""}
            onChange={(event) => this.setValue("id", event)}
          />
          <FormInputErrors
            elementId={`publisher-${id}-id-field`}
            errors={errors.id}
          />
        </Form.Group>

        <Form.Row className="mt-4 ">
          <Col xs md lg="4">
            <Form.Group>
              <div className="image-input image-input-outline" id="kt_image_1">
                <div
                  className="image-input-wrapper"
                  style={{ background: url }}
                >
                  <img
                    className="placeholder-image"
                    hidden={
                      publisherEditing.logo != "" &&
                      publisherEditing.logo != null
                    }
                    src={imgplaceholder}
                  />
                  <label
                    style={{ width: "100%", height: "100%", opacity: 0 }}
                    className="custom-image-edit-icon-btn btn btn-xs btn-icon  btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                  >
                    <i className="fa fa-pen icon-sm text-muted"></i>
                    <Form.Control
                      isInvalid={hasErrors(errors, "image_square")}
                      disabled={sending}
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        this.setValueImage("logo", event.currentTarget.files)
                      }
                    />
                    <input type="hidden" name="profile_avatar_remove" />
                  </label>
                </div>

                <label
                  hidden={
                    publisherEditing.logo == null || publisherEditing.logo == ""
                  }
                  className="custom-image-edit-icon-btn btn btn-xs btn-icon  btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Change avatar"
                >
                  <i className="fa fa-pen icon-sm text-muted"></i>
                  <Form.Control
                    isInvalid={hasErrors(errors, "logo")}
                    disabled={sending}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.setValueImage("logo", event.currentTarget.files)
                    }
                  />
                  <input type="hidden" name="profile_avatar_remove" />
                </label>

                <Button
                  className="custom-image-edit-icon-btn btn btn-xs btn-icon btn-white btn-hover-text-primary btn-shadow"
                  variant=""
                  data-action="remove"
                  disabled={sending}
                  hidden={
                    publisherEditing.logo == null || publisherEditing.logo == ""
                  }
                  data-toggle="tooltip"
                  title="Cancel avatar"
                  onClick={() => this.setValueImage("logo", null)}
                >
                  <i className="ki ki-bold-close icon-xs text-muted"></i>
                </Button>
              </div>
            </Form.Group>
          </Col>
          <Col xs md lg="3">
            <Form.Group>
              <Form.Row>
                <Form.Label className="text-dark-75 font-weight-bolder">
                  {publisherEditing.name || ""}
                </Form.Label>
              </Form.Row>
              <Form.Row>
                <Form.Label className="">
                  <i className="duotone-text-icon text-muted icon-1x r-5"></i>
                  Editore
                </Form.Label>
              </Form.Row>
            </Form.Group>
          </Col>
          <Col xs md lg="3">
            <Form.Group>
              <Form.Label></Form.Label>
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row className="mt-4 ">
          <Col xs md lg="4">
            <Form.Group>
              <label className="text-dark-75 font-weight-bolder">Nome</label>
            </Form.Group>
          </Col>
          <Col xs md lg="6">
            <Form.Group>
              <Form.Control
                isInvalid={hasErrors(errors, "name")}
                className="custom-input"
                placeholder="Inserisci il nome"
                disabled={sending}
                hidden={false}
                type="text"
                value={publisherEditing.name || ""}
                onChange={(event) => this.setValue("name", event)}
              />
              <FormInputErrors
                elementId={`publisher-${id}-name-field`}
                errors={errors.name}
              />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row className="mt-4 ">
          <Col xs md lg="4">
            <Form.Group>
              <label className="text-dark-75 font-weight-bolder">
                Description
              </label>
            </Form.Group>
          </Col>
          <Col xs md lg="6">
            <Form.Group>
              <Form.Control
                as="textarea"
                rows={5}
                isInvalid={hasErrors(errors, "description")}
                disabled={sending}
                className="custom-input"
                placeholder="Inserisci una descrizione"
                type="text"
                value={publisherEditing.description || ""}
                onChange={(event) => this.setValue("description", event)}
              />
              <FormInputErrors
                elementId={`publisher-${id}-description-field`}
                errors={errors.description}
              />
            </Form.Group>
          </Col>
        </Form.Row>

        <Form.Row className="mt-4 ">
          <Col xs md lg="4">
            <Form.Group>
              <label className="text-dark-75 font-weight-bolder">
                Link external
              </label>
            </Form.Group>
          </Col>
          <Col xs md lg="6">
            <Form.Group>
              <Form.Control
                className="custom-input"
                isInvalid={hasErrors(errors, "link_external")}
                disabled={sending}
                type="text"
                value={publisherEditing.link_external || ""}
                onChange={(event) => this.setValue("link_external", event)}
              />
              <FormInputErrors
                elementId={`publisher-${id}-link_external-field`}
                errors={errors.link_external}
              />
            </Form.Group>
          </Col>
        </Form.Row>

        <div className="separator separator-solid my-7"></div>

        <Form.Row className="mt-4 justify-content-between">
          {/*
            !isNewRecord ?
              <DeleteButtonClickConfirm
                label="Delete publisher" labelConfirm="Click to confirm" size="sm"
                action={() => deletePublisher(id)}
                disabled={sending} />
              :
              <div />
          */}
          <div />
          <div>
            <Button
              onClick={this.props.cancelEdit}
              disabled={sending}
              size="sm"
              variant="link"
              className="marginButton"
            >
              Annulla
            </Button>

            <Button
              onClick={this.save}
              disabled={sending}
              size="sm"
              variant="primary"
            >
              Salva modifiche
            </Button>
          </div>
        </Form.Row>
      </Form>
    );
  }
}
