import clone from "lodash/clone";
import findIndex from "lodash/findIndex";

import {
  RESOURCES_LIST_LOAD_START,
  RESOURCES_LIST_LOAD_DONE,
  ResourcesListAction,
  RESOURCES_SAVE_DONE,
  ResourcesSaveAction,
  RESOURCES_DELETE_DONE,
  ResourcesDeleteAction,
} from "../actionTypes";
import { ResourcesListState } from "../state";
import PaginatedResponse, { empty } from "../../models/PaginatedResponse";
import Resources from "../../models/Resources";

const initialState: ResourcesListState = { loading: false, list: empty };

export default function reducer(
  state: ResourcesListState = initialState,
  action: ResourcesListAction | ResourcesSaveAction | ResourcesDeleteAction
) {
  switch (action.type) {
    case RESOURCES_LIST_LOAD_START: {
      return { ...state, loading: true };
    }

    case RESOURCES_LIST_LOAD_DONE: {
      console.log("reducer:RESOURCES_LIST_LOAD_DONE");
      console.log(action.payload);
      console.log("reducer:RESOURCES_LIST_LOAD_DONE - END");
      return { ...state, loading: false, list: action.payload };
    }

    case RESOURCES_SAVE_DONE: {
      const entries: Resources[] = clone(state.list.entries);
      const updated: Resources = action.payload;

      const index = findIndex(entries, (entry) => entry.id === updated.id);
      if (index >= 0) {
        entries[index] = updated;
      } else {
        entries.push(updated);
      }
      const list: PaginatedResponse<Resources> = { ...state.list, entries };
      return { ...state, list };
    }

    case RESOURCES_DELETE_DONE: {
      const { id } = action.payload;
      const entries: Resources[] = clone(state.list.entries);

      const index = findIndex(entries, (entry) => entry.id === id);
      if (index >= 0) {
        entries.splice(index, 1);
      }
      const list: PaginatedResponse<Resources> = { ...state.list, entries };
      return { ...state, list };
    }

    default:
      return state;
  }
}
