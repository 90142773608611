import React, { Component, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

interface Props {
  message: string[];
  show: boolean;
  callbackClose: () => void;
}

interface State {
  activated: boolean;
}

function ModalInfoComponent(props: {
  message: string[];
  show1: boolean;
  callbackClose: () => void;
}) {
  const [show, setShow] = useState(props.show1);

  const handleClose = () => {
    props.callbackClose();
    setShow(false);
  };
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="modal-delete-body">
          <div className="modal-delete-body-div">
            <label className="modal-delete-subtitle">
              {props.message.map((message) => {
                return (
                  <>
                    {message}
                    <br />
                  </>
                );
              })}
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-delete-footer">
          <Button variant="secondary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default class ModalInfo extends Component<Props, State> {
  timeoutId?: number;

  constructor(props: Props, state: State) {
    super(props, state);

    this.state = { activated: false };

    this.setModal = this.setModal.bind(this);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
    }
  }

  setModal() {
    this.setState({ activated: true });
  }

  render() {
    return (
      <ModalInfoComponent
        show1={this.props.show}
        message={this.props.message}
        callbackClose={this.props.callbackClose}
      />
    );
  }
}
