import { connect } from "react-redux";

import PeopleFormComponent, {
  DataProps as PeopleFormProps,
} from "../../components/people/PeopleForm";

import { PeopleEditState } from "../../redux/state";
import {
  save,
  cancelEdit,
  deletePeople,
} from "../../redux/actions/peopleActions";
import { build as buildPeople } from "../../models/People";

const mapStateToProps = ({
  peopleEdit,
}: {
  peopleEdit: PeopleEditState;
}): PeopleFormProps => {
  const people = peopleEdit.people || buildPeople();
  return { ...peopleEdit, people };
};

export default connect(mapStateToProps, { save, cancelEdit, deletePeople })(
  PeopleFormComponent as any
);
