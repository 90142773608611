import {
  CONTENT_OPEN,
  ContentOpen,
  CONTENT_EDIT,
  ContentEdit,
  CONTENT_SAVE_START,
  CONTENT_SAVE_DONE,
  CONTENT_SAVE_FAILED,
  ContentSaveAction,
  CONTENT_DELETE_START,
  CONTENT_DELETE_DONE,
  ContentDeleteAction,
  ASSET_SAVE_START,
  ASSET_SAVE_DONE,
  ASSET_SAVE_FAILED,
  AssetSaveAction,
} from "../actionTypes";
import { ContentEditState } from "../state";

const initialState: ContentEditState = {
  editing: false,
  sending: false,
  errors: {},
  saveSucceeded: false,
};

export default function reducer(
  state: ContentEditState = initialState,
  action:
    | ContentOpen
    | ContentEdit
    | ContentSaveAction
    | ContentDeleteAction
    | AssetSaveAction
) {
  switch (action.type) {
    case CONTENT_OPEN: {
      const content = action.payload;
      return { ...initialState, content, editing: action.editing };
    }

    case CONTENT_EDIT: {
      return { ...state, ...initialState, editing: action.payload };
    }

    case CONTENT_SAVE_START: {
      return { ...state, sending: true, errors: {}, saveSucceeded: false };
    }

    case CONTENT_SAVE_DONE: {
      return {
        ...state,
        editing: false,
        sending: false,
        errors: {},
        content: action.payload,
        saveSucceeded: true,
      };
    }

    case CONTENT_SAVE_FAILED: {
      return {
        ...state,
        editing: true,
        sending: false,
        errors: action.payload,
        saveSucceeded: false,
      };
    }

    case CONTENT_DELETE_START: {
      return { ...state, sending: true };
    }

    case CONTENT_DELETE_DONE: {
      return { ...state, sending: false, content: undefined };
    }

    case ASSET_SAVE_START: {
      return { ...state, sending: true };
    }

    case ASSET_SAVE_DONE:
    case ASSET_SAVE_FAILED: {
      return { ...state, sending: false };
    }

    default:
      return state;
  }
}
