import React, { Component } from "react";
import { connect } from "react-redux";

import PaginatedResponse from "../../models/PaginatedResponse";
import Resources from "../../models/Resources";

import ResourcesListComponent, {
  SearchParams,
} from "../../components/resources/ResourcesList";
import {
  loadResourcesList,
  openResources,
  newResources,
  deleteResources,
} from "../../redux/actions/resourcesActions";
import { StoreState } from "../../redux/state";

interface Props {
  loading: boolean;
  list: PaginatedResponse<Resources>;

  loadResourcesList: (params: SearchParams) => void;
  openResources: (resources: Resources) => void;
  newResources: () => void;
  deleteResources: (id: string) => void;
}

class ResourcesList extends Component<Props> {
  componentDidMount() {
    console.log("componentDidMount");
    console.log("before load");
    this.props.loadResourcesList({});
    console.log("after load");
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.list.currentPage == prevProps.list.currentPage) {
      if (this.props.list.entries.length != prevProps.list.entries.length) {
        this.props.loadResourcesList({ search: this.props.list.search });
      }
    }
  }
  render() {
    console.log("render");
    const {
      loading,
      list,
      openResources,
      newResources,
      loadResourcesList,
      deleteResources,
    } = this.props;
    const openById = (id: string) => {
      const resources: Resources | undefined = list.entries.find(
        (b) => b.id === id
      );
      if (resources) {
        openResources(resources);
      }
    };

    return (
      <ResourcesListComponent
        loading={loading}
        list={list}
        openResources={openById}
        newResources={newResources}
        searchResources={loadResourcesList}
        searchFunction={loadResourcesList}
        deleteResources={deleteResources}
      />
    );
  }
}

const mapStateToProps = (store: StoreState) => {
  return store.resourcesList;
};

export default connect(mapStateToProps, {
  loadResourcesList,
  openResources,
  newResources,
  deleteResources,
})(ResourcesList);
