import React, { Component } from "react";
import { connect } from "react-redux";

import PaginatedResponse from "../../models/PaginatedResponse";
import Content from "../../models/Content";

import ContentListComponent, {
  SearchParams,
} from "../../components/content/ContentList";
import {
  loadContainerOption,
  loadContentList,
  openContent,
  newContent,
  deleteContent,
} from "../../redux/actions/contentActions";
import {
  upload,
  download,
  resetUpload,
} from "../../redux/actions/excelActions";
//import { upload} from '../../redux/actions/assetActions'

import { StoreState, ContainerListState } from "../../redux/state";
import OptionResponse from "../../models/OptionResponse";
import Asset from "../../models/Asset";
import ImportResponse from "../../models/ImportResponse";

interface Props {
  importResponse?: ImportResponse;
  modal: boolean;
  listSelected?: string[];
  loading: boolean;
  list: PaginatedResponse<Content>;
  listContainerOption: OptionResponse[];
  loadContentList: (params: SearchParams) => void;
  //searchByContainer: (id : string) => void
  openContent: (content: Content) => void;
  newContent: (audio_book_id: string, label: string) => void;
  loadContainerOption: () => void;
  checkboxChange?: (event: any, content: Content) => void;
  upload: (file: File) => void;
  download: () => void;
  deleteContent: (id: string) => void;
  uploading: boolean;
  sending: boolean;
  resetUpload: () => void;
}
var listOption: OptionResponse[];
class ContentList extends Component<Props> {
  componentDidMount() {
    console.log("componentDidMount");
    console.log("before load");
    this.props.loadContainerOption();
    this.props.loadContentList({});
    console.log("after load");
  }
  componentDidUpdate(prevProps: Props) {
    console.log("UPDATE");
    console.log(this.props);
    if (this.props.list.currentPage == prevProps.list.currentPage) {
      if (this.props.list.entries.length != prevProps.list.entries.length) {
        this.props.loadContentList({
          search: this.props.list.search,
          parent_id: this.props.list.parent_id,
        });
      }
    }
  }

  componentWillUnmount() {}
  render() {
    console.log("render");

    const {
      importResponse,
      modal,
      listSelected,
      loading,
      list,
      listContainerOption,
      openContent,
      newContent,
      loadContentList,
      checkboxChange,
      upload,
      download,
      uploading,
      sending,
      deleteContent,
      resetUpload,
    } = this.props;
    const openById = (id: string) => {
      const content: Content | undefined = list.entries.find(
        (b) => b.id === id
      );
      if (content) {
        openContent(content);
      }
    };

    return (
      <ContentListComponent
        importResponse={importResponse}
        modal={modal}
        loading={loading}
        list={list}
        listOption={listContainerOption}
        listSelected={listSelected}
        openContent={openById}
        newContent={newContent}
        //searchByContainer={loadContentList}
        searchContent={loadContentList}
        searchFunction={loadContentList}
        loadContainerOption={loadContainerOption}
        checkboxChange={checkboxChange}
        deleteContent={deleteContent}
        upload={upload}
        download={download}
        uploading={uploading}
        sending={sending}
        resetUpload={resetUpload}
      />
    );
  }
}
interface OwnProps {
  chapterId: string;
  assets: Asset[];
}

const mapStateToProps = (store: StoreState) => {
  return store.contentList;
};

export default connect(mapStateToProps, {
  loadContentList,
  openContent,
  newContent,
  loadContainerOption,
  upload,
  download,
  deleteContent,
  resetUpload,
})(ContentList);
