import { AxiosRequestConfig } from "axios";

import HttpClientService, {
  HttpClientResponse,
} from "./HttpClientServiceLaravel";
import PaginatedResponse from "../models/PaginatedResponse";
import Category from "../models/Category";
import OptionResponse from "../models/OptionResponse";

export interface SearchParams {
  page?: number;
  search?: string;
}

export default class CategoryService extends HttpClientService {
  async search(params: SearchParams): Promise<PaginatedResponse<Category>> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/v1/category",
      params,
    };

    const response: HttpClientResponse<PaginatedResponse<
      Category
    >> = await this.doRequest(request);
    console.log(response);
    return response.data;
  }

  async save(model: Category): Promise<Category> {
    let request: AxiosRequestConfig;
    const data: any = { ...model, id: undefined };
    if (data.authors) {
      data.overrideAuthors = data.authors.map((a: any) => a.id);
      delete data.authors;
    }

    if (model.id) {
      request = {
        method: "PATCH",
        url: `/api/v1/category/${model.id}`,
        data: { category: data },
      };
    } else {
      request = {
        method: "POST",
        url: "/api/v1/category",
        data: { category: data },
      };
    }

    const response: HttpClientResponse<Category> = await this.doRequest(
      request
    );
    return response.data;
  }

  async deleteModel(id: string): Promise<boolean> {
    console.log("delete category");
    const request: AxiosRequestConfig = {
      method: "DELETE",
      url: `/api/v1/category/${id}`,
    };

    await this.doRequest(request);
    return true;
  }

  async getOptionList(params: SearchParams): Promise<OptionResponse[]> {
    const request: AxiosRequestConfig = {
      method: "GET",
      url: "/api/v1/categoryoption",
      params,
    };

    const response: HttpClientResponse<OptionResponse[]> = await this.doRequest(
      request
    );
    console.log(response);
    return response.data;
  }
}

export const service = new CategoryService();
