import React, { useState } from "react";
import {
  Table,
  Button,
  Pagination,
  Form,
  Col,
  InputGroup,
  Row,
} from "react-bootstrap";
import SVG from "react-inlinesvg";
import Loader from "../Loader";
import PaginatedResponse from "../../models/PaginatedResponse";
import Publisher from "../../models/Publisher";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Typography } from "@material-ui/core";
import PaginationComponent from "../lib/PaginationComponent";
import DeleteButtonClickListModal from "../lib/DeleteButtonClickListModal";

export interface SearchParams {
  page?: number;
  search?: string;
}

interface Props {
  loading: boolean;
  list: PaginatedResponse<Publisher>;

  searchPublisher: (params: SearchParams) => void;
  openPublisher: (id: string) => void;
  deletePublisher: (id: string) => void;
  newPublisher: () => void;
  searchFunction: (params: SearchParams) => void;
}

export default function PublisherList(props: Props) {
  if (props.loading) {
    return <Loader rows={10} />;
  }

  const newPublisherButton = (
    <div className="text-right">
      <Button
        size="sm"
        className="btn-custom-secondary"
        variant=""
        onClick={props.newPublisher}
      >
        Nuovo Editore
      </Button>
    </div>
  );

  const list: PaginatedResponse<Publisher> = props.list;
  const tableEntries = props.list.entries.map((b) => {
    const open = (event: any) => {
      event.preventDefault();
      props.openPublisher(b.id);
    };

    const remove = (event: any) => {
      event.preventDefault();
      props.deletePublisher(b.id);
    };

    return (
      <tr key={`publisher-entry-${b.id}`}>
        <td className="pl-0 td-image">
          <img src={b.logo} className="image-icon" alt="" />
        </td>
        <td className="td-name">
          <label className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
            {b.name}
          </label>
          <div>
            <a href="#" className="text-dark-50 ">
              Editore
            </a>
          </div>
        </td>
        <td className="td-description" style={{ color: "#B5B5C3" }}>
          <div>{b.description}</div>
        </td>
        <td className="button-td" style={{ textAlign: "right" }}>
          <a
            onClick={open}
            href="#"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </a>
          <DeleteButtonClickListModal
            deleteAction={props.deletePublisher}
            idDelete={b.id}
          />
        </td>
      </tr>
    );
  });

  return (
    <>
      <Row className="title-row">
        <Col xs={7}>
          <Typography variant="h5" component="h3">
            <b>Anagrafiche Editori</b>
          </Typography>
        </Col>
        <Col xs={3}>
          <SearchForm {...props} />
        </Col>
        <Col xs={2}>{newPublisherButton}</Col>
      </Row>

      {0 === list.entries.length ? (
        <p className="font-italic">No publisher matches your search criteria</p>
      ) : (
        <>
          <Table hover responsive size="sm" className="publisher-list-table">
            <thead className="MuiPaper-rounded">
              <tr>
                {/*<th></th>*/}
                {/*<th colSpan={4} style={{border:"none", borderRadius:"6px"}}>NOME EDITORE</th>*/}
                <td
                  style={{
                    border: "none",
                    borderTopLeftRadius: "6px",
                    borderBottomLeftRadius: "6px",
                  }}
                ></td>
                <td style={{ border: "none" }}>NOME EDITORE</td>
                <td style={{ border: "none", color: "#B5B5C3" }}>
                  DESCRIZIONE
                </td>
                <td
                  style={{
                    border: "none",
                    borderTopRightRadius: "6px",
                    borderBottomRightRadius: "6px",
                  }}
                ></td>
              </tr>
            </thead>

            <tbody>{tableEntries}</tbody>
          </Table>
        </>
      )}
      <PaginationComponent {...props} />
    </>
  );
}

function SearchForm(props: Props) {
  const [search, setSearch] = useState(props.list.search || "");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    props.searchPublisher({ search });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col xs={12}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <span className="oi oi-magnifying-glass"></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className="custom-form-box"
              type="text"
              placeholder="Cerca Editore"
              onChange={({ target: { value } }) => setSearch(value)}
              value={search}
            />
          </InputGroup>
        </Col>
      </Form.Row>
    </Form>
  );
}
