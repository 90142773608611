import clone from "lodash/clone";
import findIndex from "lodash/findIndex";

import {
  CONTENT_CONTAINER_OPTION_LIST_LOAD_START,
  CONTENT_CONTAINER_OPTION_LIST_LOAD_DONE,
  CONTENT_LIST_LOAD_START,
  CONTENT_LIST_LOAD_DONE,
  ContentListAction,
  CONTENT_SAVE_DONE,
  ContentSaveAction,
  CONTENT_DELETE_DONE,
  ContentDeleteAction,
  ContentContainerOptionListAction,
  EXCEL_UPLOAD_START,
  EXCEL_UPLOAD_DONE,
  ExcelAction,
} from "../actionTypes";
import { ContentListState } from "../state";
import PaginatedResponse, { empty } from "../../models/PaginatedResponse";
import Content from "../../models/Content";

const initialState: ContentListState = {
  loading: false,
  list: empty,
  listContainerOption: [],
};

export default function reducer(
  state: ContentListState = initialState,
  action:
    | ContentListAction
    | ContentSaveAction
    | ContentDeleteAction
    | ContentContainerOptionListAction
    | ExcelAction
) {
  switch (action.type) {
    case CONTENT_LIST_LOAD_START: {
      return { ...state, loading: true };
    }

    case CONTENT_LIST_LOAD_DONE: {
      console.log("reducer:CONTENT_LIST_LOAD_DONE");
      console.log(action.payload);
      console.log("reducer:CONTENT_LIST_LOAD_DONE - END");
      return { ...state, loading: false, list: action.payload };
    }

    case CONTENT_SAVE_DONE: {
      const entries: Content[] = clone(state.list.entries);
      const updated: Content = action.payload;

      const index = findIndex(entries, (entry) => entry.id === updated.id);
      if (index >= 0) {
        entries[index] = updated;
      } else {
        entries.push(updated);
      }
      const list: PaginatedResponse<Content> = { ...state.list, entries };
      return { ...state, list };
    }

    case CONTENT_DELETE_DONE: {
      const { id } = action.payload;
      const entries: Content[] = clone(state.list.entries);

      const index = findIndex(entries, (entry) => entry.id === id);
      if (index >= 0) {
        entries.splice(index, 1);
      }
      const list: PaginatedResponse<Content> = { ...state.list, entries };
      return { ...state, list };
    }

    case CONTENT_CONTAINER_OPTION_LIST_LOAD_START: {
      return { ...state, loading: true };
    }

    case CONTENT_CONTAINER_OPTION_LIST_LOAD_DONE: {
      console.log("reducer:CONTENT_CONTAINER_OPTION_LIST_LOAD_DONE");
      console.log(action.payload);
      console.log("reducer:CONTENT_CONTAINER_OPTION_LIST_LOAD_DONE - END");
      return { ...state, loading: false, listContainerOption: action.payload };
    }

    case EXCEL_UPLOAD_START: {
      console.log("UPLOAD START");
      return { ...state, loading: true };
    }
    case EXCEL_UPLOAD_DONE: {
      console.log("UPLOAD END");
      return {
        ...state,
        loading: false,
        importResponse: action.importResponse,
      };
    }

    default:
      return state;
  }
}
