export default interface Category extends Record<string, any> {
  id: string;
  code: string;
  description?: string;
  parent_category?: string;
  parent_category_code?: string;
}

export function build() {
  return {
    id: "",
    code: "",
    description: "",
    parent_category: "",
    parent_category_code: "",
  };
}
