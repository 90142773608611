import { connect } from "react-redux";

import PublisherFormComponent, {
  DataProps as PublisherFormProps,
} from "../../components/publisher/PublisherForm";

import { PublisherEditState } from "../../redux/state";
import {
  save,
  cancelEdit,
  deletePublisher,
} from "../../redux/actions/publisherActions";
import { build as buildPublisher } from "../../models/Publisher";

const mapStateToProps = ({
  publisherEdit,
}: {
  publisherEdit: PublisherEditState;
}): PublisherFormProps => {
  const publisher = publisherEdit.publisher || buildPublisher();
  return { ...publisherEdit, publisher };
};

export default connect(mapStateToProps, { save, cancelEdit, deletePublisher })(
  PublisherFormComponent as any
);
