import MultiselectOption from "./MultiselectOption";

export const StatusSalesOption: MultiselectOption[] = [
  { value: "1", label: "Display" },
  { value: "2", label: "No-Display" },
  { value: "3", label: "Display-non-orderable" },
  { value: "4", label: "Cancelled" },
];

export const StatusResourcesOption: MultiselectOption[] = [
  { value: "1", label: "Audioserie" },
  //{value:"2", label:"Cofanetto"},
  { value: "3", label: "Risorsa generica" },
  { value: "4", label: "Podcast" },
  { value: "5", label: "Audionotes" },
  { value: "6", label: "Doubleface" },
  { value: "7", label: "Emonsraga" },
  { value: "8", label: "Audiolibro" },
  { value: "9", label: "Prodotti" },
];

export const TypeContainersOption: MultiselectOption[] = [
  { value: "1", label: "Stagione" },
  { value: "2", label: "Audiolibro digitale" },
  { value: "3", label: "Audiolibro fisico" },
  { value: "4", label: "Audilibro ibrido" },
  { value: "5", label: "Cd" },
  { value: "6", label: "Audiolibro" },
  { value: "7", label: "Podcast" },
  { value: "8", label: "Ebook" },
  { value: "9", label: "Libro" },
  { value: "10", label: "Cd+QrCode" },
  { value: "11", label: "Libro+QrCode" },
  { value: "12", label: "Quaderno+QrCode" },
];
