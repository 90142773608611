import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import ContainerList from "../containers/container/ContainerList";
import ContainerDetails from "../containers/container/ContainerDetails";
import ContainerDetailsLight from "../containers/container/ContainerDetailsLight";

import CurrentUser from "../models/CurrentUser";

interface Props {
  currentUser: CurrentUser;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(3, 2),
    borderRadius: 12,
  },
}));

export default function ContainerBackoffice(props: Props) {
  const classes = useStyles();
  console.log("PROPS");
  const editing = useSelector(
    (state: RootStateOrAny) => state.containerEdit.editing
  );

  return (
    <div>
      {!editing ? (
        <Paper className={classes.root}>
          <Typography component="div">
            <ContainerList closeModal={() => {}} modal={false} />
          </Typography>
        </Paper>
      ) : (
        <div>
          <ContainerDetails />
        </div>
      )}
    </div>
  );
}
