import React from "react";
import { Button, Table } from "react-bootstrap";
import join from "lodash/join";

import PeopleForm from "../../containers/people/PeopleForm";
import People from "../../models/People";

interface Props {
  people?: People;
  editing: boolean;

  edit: () => void;
}

export default function PeopleDetails(props: Props) {
  if (!props.people) {
    return <p className="font-italic">Select a people from the list</p>;
  }
  console.log(props);
  const { people } = props;

  return (
    <>
      <div className="mt-4 mb-4">{<PeopleForm />}</div>
    </>
  );
}

function DetailsTable({ people }: { people: People }) {
  return (
    <Table size="sm">
      <tbody>
        <tr>
          <th>ID</th>
          <td>{people.id}</td>
        </tr>
        <tr>
          <th>Name</th>
          <td>{people.name}</td>
        </tr>
        <tr>
          <th>Description</th>
          <td>{people.description}</td>
        </tr>
      </tbody>
    </Table>
  );
}
