import { connect } from "react-redux";

import ResourcesFormComponent, {
  DataProps as ResourcesFormProps,
} from "../../components/resources/ResourcesForm";

import { CategoryListState, ResourcesEditState } from "../../redux/state";
import {
  save,
  saveContainerRelated,
  cancelEdit,
  deleteResources,
  openResources,
  getResources,
} from "../../redux/actions/resourcesActions";
import { build as buildResources } from "../../models/Resources";
import { loadCategoryOption } from "../../redux/actions/categoryActions";

const mapStateToProps = ({
  resourcesEdit,
  categoryList,
}: {
  resourcesEdit: ResourcesEditState;
  categoryList: CategoryListState;
}): ResourcesFormProps => {
  const resources = resourcesEdit.resources || buildResources();
  const categoryOption = categoryList.listOption || [];
  console.log("DRAW COMPONENT RESOURCES");
  console.log(resources);
  console.log("END - DRAW COMPONENT RESOURCES");
  return { ...resourcesEdit, resources, categoryOption };
};

export default connect(mapStateToProps, {
  save,
  saveContainerRelated,
  cancelEdit,
  deleteResources,
  loadCategoryOption,
  openResources,
  getResources,
})(ResourcesFormComponent as any);
